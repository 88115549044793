// libraries
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { addDays, lightFormat } from 'date-fns';
import { useSelector } from 'react-redux';

// js
import colors from '../../../styles/colors';
import {
    Container,
    Home,
    ContainerInner,
    ContainerReports,
    Header,
    ContainerHeaderScreen,
    ContainerDivider,
    ContainerDatePicker,
    ButtonAction,
    TextAction,
    Option,
    ContainerSelect,
} from './reportStyles';
import authorsApi from '../../../services/authors';
import reportsApi from '../../../services/reports';
import { RootState } from '../../../store';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

// interfaces
import { DataAuthorsInterface } from '../../../interfaces';

const ReportPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(addDays(new Date(), 1));
    const [allAuthors, setAllAuthors] = useState<Array<DataAuthorsInterface>>([]);
    const [author, setAuthor] = useState('');
    const [active, setActive] = useState(false);

    // methods
    const onChangeInitialDate = (date: Date | null) => {
        setInitialDate(date || new Date());
    };

    const onChangeFinalDate = (date: Date | null) => {
        setFinalDate(date || new Date());
    };

    const GetAllAuthors = async () => {
        const GetAuthors = await authorsApi.GetAllAuthors(infoUser?.token);
        setAllAuthors(GetAuthors.result);
    };

    const onChangeFormat = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setAuthor(txt.target.value);
    };

    const UploadXML = async () => {
        const FormatInitialDate = initialDate ? lightFormat(initialDate, 'dd/MM/yyyy') : '';
        const FormatFinalDate = finalDate ? lightFormat(finalDate, 'dd/MM/yyyy') : '';
        const GetFile = await reportsApi.GetReports(
            FormatInitialDate,
            FormatFinalDate,
            'csv',
            author.split(',')[0],
            infoUser.token,
        );
        window.open(GetFile.result.file);
    };

    const UploadPDF = async () => {
        const FormatInitialDate = initialDate ? lightFormat(initialDate, 'dd/MM/yyyy') : '';
        const FormatFinalDate = finalDate ? lightFormat(finalDate, 'dd/MM/yyyy') : '';
        const GetFile = await reportsApi.GetReports(
            FormatInitialDate,
            FormatFinalDate,
            'pdf',
            author.split(',')[0],
            infoUser.token,
        );
        window.open(GetFile.result.file);
    };

    // renders
    const RenderAuthors = () => (
        allAuthors.map((v) => (
            <Option value={`${v.id},${v.name}`}>{v.name}</Option>
        ))
    );
    const RenderColorXML = () => {
        if (active) {
            return colors.theme.greenButtonActionReport;
        }
        return colors.basic.grayLight;
    };
    const RenderColorPDF = () => {
        if (active) {
            return colors.theme.redButtonActionReport;
        }
        return colors.basic.grayLight;
    };

    // useEffect
    useEffect(() => {
        if (JSON.stringify(initialDate) !== 'null' && JSON.stringify(initialDate) !== 'null') {
            const FormatInitialDate = initialDate ? lightFormat(initialDate, 'dd/MM/yyyy') : '';
            const FormatFinalDate = finalDate ? lightFormat(finalDate, 'dd/MM/yyyy') : '';
            if (FormatInitialDate.length === 10 && FormatFinalDate.length === 10) {
                setActive(true);
            } else {
                setActive(false);
            }
        } else {
            setActive(false);
        }
    }, [initialDate, finalDate]);

    useEffect(() => {
        GetAllAuthors();
    }, []);

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <>
                            <ContainerReports>
                                <Header>
                                    {'Administração > '}
                                    <Header fontWeight="bold">Relatórios</Header>
                                </Header>
                                <ContainerHeaderScreen>
                                    <Header fontSize="32px" fontWeight="bold" color={colors.basic.gray}>Olá!</Header>
                                    <Header fontSize="32px" color={colors.basic.gray}>Defina o período e o formato para baixar</Header>
                                    <Header fontSize="32px" color={colors.basic.gray}>o relatório completo.</Header>
                                </ContainerHeaderScreen>
                                <ContainerDivider>
                                    <ContainerDatePicker>
                                        <Header fontWeight="bold" color={colors.basic.gray}>Início</Header>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                value={initialDate}
                                                onChange={onChangeInitialDate}
                                                invalidDateMessage="Data inválida!"
                                                autoOk
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ContainerDatePicker>
                                    <ContainerDatePicker>
                                        <Header fontWeight="bold" color={colors.basic.gray}>Fim</Header>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                value={finalDate}
                                                onChange={onChangeFinalDate}
                                                invalidDateMessage="Data inválida!"
                                                autoOk
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ContainerDatePicker>
                                </ContainerDivider>
                                <ContainerSelect onChange={onChangeFormat} value={author}>
                                    <Option value="">Selecione o autor</Option>
                                    {RenderAuthors()}
                                </ContainerSelect>
                                <ButtonAction
                                    border={active ? `1px solid ${colors.theme.greenButtonActionReport}` : `1px solid ${colors.basic.grayLight}`}
                                    disabled={!active}
                                    cursor={active ? 'pointer' : 'default'}
                                >
                                    <TextAction
                                        color={RenderColorXML()}
                                        fontSize="16px"
                                        fontWeight="bold"
                                        cursor={active ? 'pointer' : 'default'}
                                        onClick={UploadXML}
                                    >
                                        Baixar CSV
                                    </TextAction>
                                </ButtonAction>
                                <ButtonAction
                                    border={active ? `1px solid ${colors.theme.redButtonActionReport}` : `1px solid ${colors.basic.grayLight}`}
                                    disabled={!active}
                                    cursor={active ? 'pointer' : 'default'}
                                    onClick={UploadPDF}
                                >
                                    <TextAction
                                        color={RenderColorPDF()}
                                        fontSize="16px"
                                        fontWeight="bold"
                                        cursor={active ? 'pointer' : 'default'}
                                    >
                                        Baixar PDF
                                    </TextAction>
                                </ButtonAction>
                            </ContainerReports>
                        </>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default ReportPage;
