// js
import authors from '../../../services/authors';
import uploads from '../../../services/uploads';

// interfaces
import { DataAuthorsInterface, DataArchiveInterface } from '../../../interfaces';

const GetAllAuthors = async (
    setName: (v: string) => void,
    setDescription: (v: string) => void,
    setEmail: (v: string) => void,
    setPassword: (v: string) => void,
    setConfirmPassword: (v: string) => void,
    setActive: (v: boolean) => void,
    setIdentifier: (v: string) => void,
    setIdAuthorsActual: (v: string) => void,
    setPhotoPage: (v: DataArchiveInterface) => void,
    setPhotoCard: (v: DataArchiveInterface) => void,
    setAllAuthors: (v: Array<DataAuthorsInterface>) => void,
    token: string,
): Promise<void> => {
    // constants
    const initialArchive: DataArchiveInterface = {
        eventFile: new Blob(),
        eventName: '',
        file: '',
    };

    setName('');
    setDescription('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setActive(false);
    setIdentifier('create');
    setIdAuthorsActual('');
    setPhotoPage(initialArchive);
    setPhotoCard(initialArchive);
    // rota para pegar todas os autores
    const infoGetAuthors = await authors.GetAllAuthors(token);
    if (infoGetAuthors.status === 200) {
        setAllAuthors(infoGetAuthors.result);
    }
};

const DeleteAuthors = async (
    setName: (v: string) => void,
    setDescription: (v: string) => void,
    setEmail: (v: string) => void,
    setPassword: (v: string) => void,
    setConfirmPassword: (v: string) => void,
    setActive: (v: boolean) => void,
    setIdentifier: (v: string) => void,
    setIdAuthorsActual: (v: string) => void,
    setPhotoPage: (v: DataArchiveInterface) => void,
    setPhotoCard: (v: DataArchiveInterface) => void,
    setAllAuthors: (v: Array<DataAuthorsInterface>) => void,
    idAuthorsActual: string,
    token: string,
): Promise<void> => {
    // rota para deletar usuário
    await authors.DeleteOneAuthor(idAuthorsActual, token);
    GetAllAuthors(
        setName,
        setDescription,
        setEmail,
        setPassword,
        setConfirmPassword,
        setActive,
        setIdentifier,
        setIdAuthorsActual,
        setPhotoPage,
        setPhotoCard,
        setAllAuthors,
        token,
    );
};

const SaveAuthors = async (
    name: string,
    description: string,
    email: string,
    password: string,
    active: boolean,
    setName: (v: string) => void,
    setDescription: (v: string) => void,
    setEmail: (v: string) => void,
    setPassword: (v: string) => void,
    setConfirmPassword: (v: string) => void,
    setActive: (v: boolean) => void,
    setIdentifier: (v: string) => void,
    setIdAuthorsActual: (v: string) => void,
    setPhotoPage: (v: DataArchiveInterface) => void,
    setPhotoCard: (v: DataArchiveInterface) => void,
    setAllAuthors: (v: Array<DataAuthorsInterface>) => void,
    identifier: string,
    photoCard: DataArchiveInterface | string,
    photoPage: DataArchiveInterface | string,
    idAuthorsActual: string,
    token: string,
) : Promise<void> => {
    if (identifier === 'create') {
        const uriPhotoCard = typeof photoCard === 'object' ? await uploads.UploadFile(photoCard?.eventFile) : photoCard;
        const uriPhotoPage = typeof photoPage === 'object' ? await uploads.UploadFile(photoPage?.eventFile) : photoPage;
        const GetInfoCreate = await authors.CreateOneAuthor(
            name,
            description,
            email,
            password,
            active,
            uriPhotoCard,
            uriPhotoPage,
            token,
        );
        // eslint-disable-next-line no-alert
        alert(GetInfoCreate.message);
        GetAllAuthors(
            setName,
            setDescription,
            setEmail,
            setPassword,
            setConfirmPassword,
            setActive,
            setIdentifier,
            setIdAuthorsActual,
            setPhotoPage,
            setPhotoCard,
            setAllAuthors,
            token,
        );
    }
    if (identifier === 'edit') {
        const uriPhotoCard = typeof photoCard !== 'string' ? await uploads.UploadFile(photoCard?.eventFile) : photoCard;
        const uriPhotoPage = typeof photoPage !== 'string' ? await uploads.UploadFile(photoPage?.eventFile) : photoPage;
        const EditAuthor = await authors.EditOneAuthor(
            name,
            description,
            email,
            password,
            active,
            uriPhotoCard || uriPhotoCard,
            uriPhotoPage || uriPhotoPage,
            idAuthorsActual,
            token,
        );
        // eslint-disable-next-line no-alert
        alert(EditAuthor.message);
        GetAllAuthors(
            setName,
            setDescription,
            setEmail,
            setPassword,
            setConfirmPassword,
            setActive,
            setIdentifier,
            setIdAuthorsActual,
            setPhotoPage,
            setPhotoCard,
            setAllAuthors,
            token,
        );
    }
};

export default {
    GetAllAuthors,
    DeleteAuthors,
    SaveAuthors,
};
