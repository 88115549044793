// js
import consts from '../utils/consts';
import { DashboardInterface } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;

interface InfoChartsFinancialInterfaces {
    status: number,
    result: {
        revenues: Array<{x: number, y: number}>,
        authors: Array<{x: number, y: number}>,
        formats: Array<{x: number, y: number}>,
    } | null;
    message: string,
}

interface InfoChartsOperationInterfaces {
    status: number,
    result: {
        newUsers: Array<{x: number, y: number}>,
        downloads: Array<{x: number, y: number}>,
        streaming: Array<{x: number, y: number}>,
        performance: Array<{x: number, y: number}>,
    } | null;
    message: string,
}

interface InfoDashboardInterfaces {
    status: number,
    result: DashboardInterface,
    message: string,
}

// api
async function GetInfoDashboard(token: string): Promise<InfoDashboardInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/dashboard?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: {},
            status: 500,
            message: '',
        };
    }
}

async function GetInfoDashboardFinance(
    filterRevenues: 'month' | 'year',
    filterAuthor: 'month' | 'year',
    filterFormat: 'month' | 'year',
    idAuthor: string,
    format: 'video' | 'audio' | 'texto',
    token: string,
): Promise<InfoChartsFinancialInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/admnistration/dashboard/finance?admPass=${admPass}&filterRevenues=${filterRevenues}&filterAuthor=${filterAuthor}&filterFormat=${filterFormat}&format=${format}&idAuthor=${idAuthor}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function GetInfoDashboardOperation(
    filterStreaming: 'month' | 'year',
    nameAuthor: string,
    token: string,
): Promise<InfoChartsOperationInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/admnistration/dashboard/operation?admPass=${admPass}&filterStreaming=${filterStreaming}&nameAuthor=${nameAuthor}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

export default {
    GetInfoDashboard,
    GetInfoDashboardFinance,
    GetInfoDashboardOperation,
};
