// js
import consts from '../utils/consts';

// interface
import { DataCategoriesInterface } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;

interface CategoriesInterfaces {
    status: number,
    result: Array<DataCategoriesInterface>;
    message: string,
}

// api
async function GetAllCategories(token: string): Promise<CategoriesInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/category?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function CreateOneCategories(
    name: string,
    description: string,
    visible: boolean,
    iconUrl: string,
    coverUrl: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const data = {
            name,
            description,
            visible,
            iconUrl,
            coverUrl,
        };
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/category?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function EditOneCategories(
    name: string,
    description: string,
    visible: boolean,
    iconUrl: string,
    coverUrl: string,
    idCategory: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const data = {
            name,
            description,
            visible,
            iconUrl,
            coverUrl,
        };
        const method = 'PUT';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/category?admPass=${admPass}&idCategory=${idCategory}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneCategories(
    idCategory: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const method = 'DELETE';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/category?admPass=${admPass}&idCategory=${idCategory}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllCategories,
    CreateOneCategories,
    EditOneCategories,
    DeleteOneCategories,
};
