/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// js
import { RootState } from '../../../store';
import {
    Container,
    ContainerCategories,
    Home,
    ContainerInner,
    ListCategories,
    Header,
    HeaderInput,
    InputDefault,
    ContainerAllSwitchs,
    ButtonDeleteOrCancel,
    ButtonSave,
    TextButtonDeleteOrCancel,
    TextButtonSave,
    TextCancel,
    ContainerPhoto,
    ContainerAllCategories,
    NameCategories,
} from './usersStyles';
import functionsCategories from './UsersCategoryFunctions';

// interfaces
import { DataCategoriesInterface } from '../../../interfaces';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

const UsersCategorySettings: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);
    const tokenUser = infoUser?.token || '';

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [name, setName] = useState<string>('');
    const [identifier, setIdentifier] = useState('create');
    const [idCategoriesActual, setIdCategoriesActual] = useState('');
    const [allCategories, setAllCategories] = useState<Array<DataCategoriesInterface>>([]);

    // methods
    const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setName(txt.target.value);
    };

    const ValidationDeleteOrCancel = () => {
        if (identifier === 'create') {
            if (name.length > 0) {
                return true;
            }
        }
        if (identifier === 'edit') {
            return true;
        }

        return false;
    };

    const GetCategories = async () => {
        await functionsCategories.GetAllCategories(
            setName,
            setIdentifier,
            setAllCategories,
            tokenUser,
        );
    };

    useEffect(() => {
        GetCategories();
    }, []);

    const DeleteCategories = async () => {
        await functionsCategories.DeleteCategories(
            setName,
            setIdentifier,
            setAllCategories,
            idCategoriesActual,
            tokenUser,
        );
    };

    const SaveCategories = async () => {
        await functionsCategories.SaveCategories(
            name,
            setName,
            setIdentifier,
            setAllCategories,
            identifier,
            idCategoriesActual,
            tokenUser,
        );
    };

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <>
                            <ContainerCategories>
                                <Header>
                                    {'Configurações Gerais > '}
                                    <Header fontWeight="bold">Categoria para usuários</Header>
                                </Header>
                                <HeaderInput>Nome da categoria</HeaderInput>
                                <InputDefault
                                    value={name}
                                    type="email"
                                    onChange={onChangeName}
                                />
                                {identifier === 'edit' ? (
                                    <TextCancel onClick={GetCategories}>Cancelar</TextCancel>
                                ) : null}
                                <ContainerAllSwitchs justifyContent="space-between">
                                    <ButtonDeleteOrCancel
                                        valueLength={ValidationDeleteOrCancel()}
                                        onClick={DeleteCategories}
                                        identifier={identifier}
                                        disabled={!ValidationDeleteOrCancel()}
                                    >
                                        <TextButtonDeleteOrCancel
                                            valueLength={ValidationDeleteOrCancel()}
                                        >
                                            {identifier === 'create' ? 'Cancelar' : 'Excluir'}
                                        </TextButtonDeleteOrCancel>
                                    </ButtonDeleteOrCancel>
                                    <ButtonSave
                                        valueLength={name.length > 0}
                                        onClick={SaveCategories}
                                        identifier={identifier}
                                        disabled={name.length === 0}
                                    >
                                        <TextButtonSave>Salvar</TextButtonSave>
                                    </ButtonSave>
                                </ContainerAllSwitchs>
                            </ContainerCategories>
                            <ListCategories>
                                <HeaderInput>Relação de categorias</HeaderInput>
                                <ContainerAllCategories>
                                    {allCategories.map((v) => {
                                        const ActionEditCategories = () => {
                                            setName(v.name);
                                            setIdCategoriesActual(v.id);
                                            setIdentifier('edit');
                                        };

                                        return (
                                            <ContainerPhoto
                                                // background={v.icon}
                                                onClick={ActionEditCategories}
                                                key={v.id}
                                            >
                                                <NameCategories>{v.name}</NameCategories>
                                                {/* <ContainerEmpty background={v.cover}>
                                                    <NameCategories>{v.name}</NameCategories>
                                                </ContainerEmpty> */}
                                            </ContainerPhoto>
                                        );
                                    })}
                                </ContainerAllCategories>
                            </ListCategories>
                        </>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default UsersCategorySettings;
