// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface NumberQtdInterface {
    color: string,
}

interface ContainerDividerInterface {
    width?: string,
    marginBottom?: string,
}

interface TitleFormatInterface {
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
    flex?: number,
}

interface imgAssetsChartsInterface {
    size?: string,
}

interface ContainerCharsDividerInterface {
    width?: string
}

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const ContainerInfoRadius = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    height: 160px;
    width: 90px;
`;

export const ContainerInfoPercentage = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex-direction: column;
    width: 50px;
`;

export const ContainerDividirChartRadius = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const ImgAssetsChartRadius = styled.img<imgAssetsChartsInterface>`
    object-fit: contain;
    width: ${(props) => (props.size ? props.size : '25px')};
`;

export const TextHeader = styled.h1`
    color: ${colors.theme.pinkUltra};
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

export const ContainerInner = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerBox = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 'auto';
    min-height: 150px;
    width: 100%;
`;

export const Box = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 20px;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1);
    display: flex;
    height: 'auto';
    width: 300px;
    overflow-y: auto;
`;

export const ContainerCharts = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    height: 'auto';
    min-height: 350px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;
`;

export const ContainerChartsDivider = styled.div<ContainerCharsDividerInterface>`
    background-color: ${colors.theme.backgroundScreen};
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    padding-bottom: 30px;
    width: ${(props) => (props.width ? props.width : '650px')};
`;

export const ContainerBody = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
`;

export const TitleFormat = styled.text<TitleFormatInterface>`
    flex: ${(props) => props.flex || 0};
    color: ${colors.theme.grayIntense};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
`;

export const ImgInfoHeader = styled.img`
    height: 20px;
    width: 20px;
    object-fit: contain;
`;

export const ContainerDivider = styled.div<ContainerDividerInterface>`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    height: 'auto';
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
    width: ${(props) => (props.width ? props.width : '100%')};
`;

export const ContainerHeader = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 50px;
    width: 280px;
`;

export const NumberQtd = styled.text<NumberQtdInterface>`
    color: ${(props) => (props.color ? props.color : colors.theme.grayIntense)};
    font-weight: bold;
    font-size: 35px;
`;

export const ContainerTitle = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    width: 50px;
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;
