// libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// js
import {
    Container,
    Home,
    Logo,
    TextActionNavigation,
    TextLinkingNavigation,
} from './forgotPasswordStyles';
import { logoFullSize } from '../../assets/index';

// components
import InputAuth from '../../components/Auth/Input';
import ButtonAuth from '../../components/Auth/Button';
import auth from '../../services/auth';
import ToNavigation from '../../routes/navigation';

// interfaces
interface getParamsForgotPasswordInterfaces {
    email?: string,
}

const ForgotPassword: React.FC = () => {
    // constants
    const getParams: getParamsForgotPasswordInterfaces = useParams();

    // states
    const [emailRecover, setEmailRecover] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeEmailRecover = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmailRecover(valueFormat);
    };

    const onChangeOldPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setOldPassword(valueFormat);
    };

    const onChangeNewPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setNewPassword(valueFormat);
    };

    const onChangeConfirmNewPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setConfirmNewPassword(valueFormat);
    };

    const TradePassword = async () => {
        if (getParams?.email) {
            const getInfoTradePassword = await auth.TradePassword(
                emailRecover,
                newPassword,
                confirmNewPassword,
                oldPassword,
            );
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert(getInfoTradePassword?.message);
            setPathRoute('/');
            setRedirect(true);
        } else {
            const getInfoEmit = await auth.EmitTradePassword(emailRecover);
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert(getInfoEmit?.message);
            setEmailRecover('');
        }
    };

    const ValidationColor = () => {
        if (getParams?.email) {
            return emailRecover.length > 0
            && oldPassword.length > 0
            && newPassword.length > 0
            && confirmNewPassword.length > 0;
        }

        return emailRecover.length > 0;
    };

    const onNavigationMain = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    // useEffect
    useEffect(() => {
        setEmailRecover(getParams?.email || '');
    }, []);

    return (
        <Container>
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <Home>
                <Logo src={logoFullSize} />
                {!getParams?.email ? (
                    <InputAuth placeholder="Email" value={emailRecover} type="email" onChange={onChangeEmailRecover} activeInput={emailRecover.length > 0} />
                ) : null}
                {getParams?.email ? (
                    <>
                        <InputAuth placeholder="Senha antiga" value={oldPassword} type="password" onChange={onChangeOldPassword} activeInput={oldPassword.length > 0} />
                        <InputAuth placeholder="Nova senha" value={newPassword} type="password" onChange={onChangeNewPassword} activeInput={newPassword.length > 0} />
                        <InputAuth placeholder="Confirmar nova senha" value={confirmNewPassword} type="password" onChange={onChangeConfirmNewPassword} activeInput={confirmNewPassword.length > 0} />
                    </>
                ) : null}
                <ButtonAuth onClick={TradePassword} activeButton={ValidationColor()} label={getParams?.email ? 'Alterar senha' : 'Recuperar senha'} />
                <TextActionNavigation>
                    Já tem uma conta?
                    <TextLinkingNavigation
                        onClick={onNavigationMain}
                    >
                        Fazer login!
                    </TextLinkingNavigation>
                </TextActionNavigation>
            </Home>
        </Container>
    );
};

export default ForgotPassword;
