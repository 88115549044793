/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import consts from '../utils/consts';

// constants
const { admPass, baseUrl } = consts;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// api
async function GetAllCategoryUsers(
    token: string,
): Promise<any> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const urlGetAllUsers = `${baseUrl}/usersCategory?admPass=${admPass}`;
        const response = await fetch(urlGetAllUsers, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function CreateOneCategoryUser(
    name: string,
    token: string,
): Promise<any> {
    try {
        const data = {
            name,
        };
        const method = 'POST';
        const headers = {
            ...headerApplicationJson,
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/usersCategory?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { user: {}, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

async function EditOneCategoryUser(
    name: string,
    idUserCategory: string,
    token: string,
): Promise<any> {
    try {
        const data = {
            name,
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            ...headerApplicationJson,
            'x-access-token': token,
        };
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/usersCategory?admPass=${admPass}&idUserCategory=${idUserCategory}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneCategoryUser(
    idUserCategory: string,
    token: string,
): Promise<{ status: number, message: string }> {
    try {
        const method = 'DELETE';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/usersCategory?admPass=${admPass}&idUserCategory=${idUserCategory}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllCategoryUsers,
    CreateOneCategoryUser,
    EditOneCategoryUser,
    DeleteOneCategoryUser,
};
