// libraries
import React, { useEffect, useState } from 'react';
import {
    XYPlot,
    AreaSeries,
    HorizontalGridLines,
    VerticalGridLines,
    XAxis,
    YAxis,
} from 'react-vis';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../../styles/colors';
import {
    Container,
    ContainerInner,
    ContainerFinancial,
    Header,
    ContainerCharts,
    Home,
    ContainerChartsDivider,
} from './dashFinancialStyles';
import constants from './dashFinancialConstants';
import functions from '../../../utils/functions';
import authorsApi from '../../../services/authors';
import components from './DashFinanceComponents';
import dashboard from '../../../services/dashboard';
import { RootState } from '../../../store';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

// interfaces
import { DataAuthorsInterface } from '../../../interfaces';
import { AllChartsInterfaces, InitialFiltersInterfaces } from './dashFinanceInterfaces';

const DashFinancialPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const initialWidth = isMobile ? window.innerWidth / 1.3 : window.innerWidth / 2.7;
    const initialFilters: InitialFiltersInterfaces = {
        filterRevenues: 'month',
        filterAuthor: 'month',
        filterFormat: 'month',
        format: 'texto',
        idAuthor: '',
    };

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [widthActual, setWidthActual] = useState(initialWidth);
    const [author, setAuthor] = useState('');
    const [allAuthors, setAllAuthors] = useState<Array<DataAuthorsInterface>>([]);
    const [allCharts, setAllCharts] = useState<Array<AllChartsInterfaces>>([]);
    const [allFilters, setAllFilters] = useState<InitialFiltersInterfaces>(initialFilters);

    // methods
    const GetAllAuthors = async () => {
        const GetAuthors = await authorsApi.GetAllAuthors(infoUser?.token);
        setAllAuthors(GetAuthors.result);
    };

    const GetDashFinance = async () => {
        const GetCharts = await dashboard.GetInfoDashboardFinance(
            allFilters.filterRevenues,
            allFilters.filterAuthor,
            allFilters.filterFormat,
            allFilters.idAuthor,
            allFilters.format,
            infoUser?.token,
        );
        setAllCharts([
            {
                id: 1,
                title: 'Faturamento',
                dataChart: [{
                    fill: colors.theme.pinkUltraLight,
                    stroke: colors.theme.pinkUltra,
                    result: GetCharts?.result?.revenues || [],
                }],
            },
            {
                id: 2,
                title: 'Por autor',
                dataChart: [{
                    fill: colors.theme.backgroundChartsPerformance,
                    stroke: colors.theme.strokeChartsPerformance,
                    result: GetCharts?.result?.authors || [],
                }],
            },
            {
                id: 3,
                title: 'Por formato',
                dataChart: [{
                    fill: colors.theme.backgroundChartsStreaming,
                    stroke: colors.theme.strokeChartsStreaming,
                    result: GetCharts?.result?.formats || [],
                }],
            },
        ]);
    };

    // useEffect
    useEffect(() => {
        GetAllAuthors();
        const adjustSize = window.addEventListener('resize', () => {
            if (!isMobile) {
                setWidthActual(window.innerWidth / 2.7);
            }
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        GetDashFinance();
    }, [allFilters]);

    // renders
    const RenderAllCharts = () => (
        allCharts.map((v) => {
            const [allResults] = v.dataChart.map((valueResults) => valueResults.result);
            const RenderAreaSeries = () => (
                v.dataChart.map((valueCharts) => {
                    if (allResults.filter((valueCoordinates) => valueCoordinates.y).length) {
                        return (
                            <AreaSeries
                                fill={valueCharts.fill}
                                stroke={valueCharts.stroke}
                                data={valueCharts.result}
                            />
                        );
                    }
                    return (
                        <AreaSeries
                            fill={valueCharts.fill}
                            stroke={valueCharts.stroke}
                            data={valueCharts.result}
                            yDomain={[0, 1]}
                        />
                    );
                })
            );

            const RenderTickFormatY = (value: string) => {
                if (!allResults.filter((valueCoordinates) => valueCoordinates.y).length) {
                    return '0';
                }
                return value;
            };

            return (
                <ContainerChartsDivider key={v.id}>
                    {components.RenderTitle(
                        v.title,
                        allFilters,
                        setAllFilters,
                        author,
                        setAuthor,
                        allAuthors,
                    )}
                    <XYPlot width={widthActual} height={300} margin={constants.marginXYPlot}>
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        <XAxis
                            style={constants.stylesXAxis}
                            tickFormat={functions.RenderTickFormatXMonth}
                            tickSize={3}
                        />
                        <YAxis
                            style={constants.stylesXAxis}
                            tickFormat={RenderTickFormatY}
                            tickSize={3}
                            tickValues={
                                !allResults.filter(
                                    (valueCoordinates) => valueCoordinates.y,
                                ).length ? [0] : undefined
                            }
                            top={
                                !allResults.filter(
                                    (valueCoordinates) => valueCoordinates.y,
                                ).length ? 260 : 5
                            }
                            hideLine={!allResults.filter(
                                (valueCoordinates) => valueCoordinates.y,
                            ).length}
                        />
                        {RenderAreaSeries()}
                    </XYPlot>
                </ContainerChartsDivider>
            );
        })
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <ContainerFinancial>
                            <Header>
                                {'Administração > '}
                                <Header fontWeight="bold">Dash Financeiro</Header>
                            </Header>
                            <ContainerCharts>
                                {RenderAllCharts()}
                            </ContainerCharts>
                        </ContainerFinancial>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default DashFinancialPage;
