/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';

// js
import colors from '../../../styles/colors';
import { RootState } from '../../../store';
import {
    Container,
    ContainerSwitch,
    ContainerAuthors,
    Home,
    ContainerInner,
    ListAuthors,
    Header,
    HeaderInput,
    InputDefault,
    ContainerAllSwitchs,
    ButtonDeleteOrCancel,
    ButtonSave,
    TextButtonDeleteOrCancel,
    TextButtonSave,
    TextCancel,
    ContainerPhoto,
    ContainerAllAuthors,
    NameAuthors,
    ContainerDividerBackground,
    IconCloud,
    TextBox,
    ContainerPointer,
    ImageCardAndPage,
    ContainerButton,
    HeaderSwitch,
    InputArea,
} from './authorsContentsStyles';
import { cloudPink } from '../../../assets/index';
import functionsAuthors from './AuthorsContentsFunctions';

// interfaces
import { DataAuthorsInterface, DataArchiveInterface } from '../../../interfaces';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

const AuthorsContentsPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);
    const tokenUser = infoUser?.token || '';

    // constants
    const initialArchive: DataArchiveInterface = {
        eventFile: new Blob(),
        eventName: '',
        file: '',
    };

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [active, setActive] = useState(false);
    const [identifier, setIdentifier] = useState('create');
    const [idAuthorsActual, setIdAuthorsActual] = useState('');
    const [allAuthors, setAllAuthors] = useState<Array<DataAuthorsInterface>>([]);
    const [photoPage, setPhotoPage] = useState<DataArchiveInterface | string>(initialArchive);
    const [photoCard, setPhotoCard] = useState<DataArchiveInterface | string>(initialArchive);

    // methods
    const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setName(txt.target.value);
    };

    const onChangeDescription = (txt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(txt.target.value);
    };

    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(txt.target.value);
    };

    const onChangePassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(txt.target.value);
    };

    const onChangeConfirmPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(txt.target.value);
    };

    const onChangeActive = () => {
        setActive(!active);
    };

    const ValidationDeleteOrCancel = () => {
        if (identifier === 'create') {
            if (name.length > 0 || description.length > 0) {
                return true;
            }
        }
        if (identifier === 'edit') {
            return true;
        }

        return false;
    };

    const ValidationSave = () => {
        const validationPage = typeof photoPage === 'object' ? photoPage?.file?.length > 0 : photoPage.length > 0;
        const validationCard = typeof photoCard === 'object' ? photoCard?.file?.length > 0 : photoCard.length > 0;
        const validationPassword = identifier === 'create' ? password.length > 0 && confirmPassword.length > 0 : true;
        const validationFormsCreate = identifier === 'create' && description.length > 0 && name.length > 0 && email.length > 0 && password === confirmPassword && validationPage && validationCard && validationPassword;
        const validationFormsEdit = identifier === 'edit' && description.length > 0 && name.length > 0 && email.length > 0 && password === confirmPassword && validationPage && validationCard && validationPassword;
        return validationFormsCreate || validationFormsEdit;
    };

    const GetAuthors = async () => {
        await functionsAuthors.GetAllAuthors(
            setName,
            setDescription,
            setEmail,
            setPassword,
            setConfirmPassword,
            setActive,
            setIdentifier,
            setIdAuthorsActual,
            setPhotoPage,
            setPhotoCard,
            setAllAuthors,
            tokenUser,
        );
    };

    useEffect(() => {
        GetAuthors();
    }, []);

    const DeleteAuthors = async () => {
        await functionsAuthors.DeleteAuthors(
            setName,
            setDescription,
            setEmail,
            setPassword,
            setConfirmPassword,
            setActive,
            setIdentifier,
            setIdAuthorsActual,
            setPhotoPage,
            setPhotoCard,
            setAllAuthors,
            idAuthorsActual,
            tokenUser,
        );
    };

    const SaveAuthors = async () => {
        await functionsAuthors.SaveAuthors(
            name,
            description,
            email,
            password,
            active,
            setName,
            setDescription,
            setEmail,
            setPassword,
            setConfirmPassword,
            setActive,
            setIdentifier,
            setIdAuthorsActual,
            setPhotoPage,
            setPhotoCard,
            setAllAuthors,
            identifier,
            photoCard,
            photoPage,
            idAuthorsActual,
            tokenUser,
        );
    };

    const RenderDragMessage = (type: string) => (
        <>
            <IconCloud src={cloudPink} />
            <TextBox>CLIQUE AQUI</TextBox>
            <TextBox>para inserir uma imagem</TextBox>
            <TextBox>{`de background para ${type === 'card' ? 'card' : 'página'}`}</TextBox>
        </>
    );

    function SelectedPhotoCard(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setPhotoCard(newDataFiles);
    }

    function SelectedPhotoPage(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setPhotoPage(newDataFiles);
    }

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <>
                            <ContainerAuthors>
                                <Header>
                                    {'Conteúdos > '}
                                    <Header fontWeight="bold">Autores</Header>
                                </Header>
                                <HeaderInput>Nome do autor</HeaderInput>
                                <InputDefault
                                    value={name}
                                    type="email"
                                    onChange={onChangeName}
                                />
                                <HeaderInput>Descrição</HeaderInput>
                                <InputArea value={description} onChange={onChangeDescription} />
                                <HeaderInput>E-mail</HeaderInput>
                                <InputDefault
                                    value={email}
                                    type="email"
                                    onChange={onChangeEmail}
                                />
                                <HeaderInput>Senha</HeaderInput>
                                <InputDefault
                                    value={password}
                                    type="password"
                                    onChange={onChangePassword}
                                />
                                <HeaderInput>Confirmar senha</HeaderInput>
                                <InputDefault
                                    value={confirmPassword}
                                    type="password"
                                    onChange={onChangeConfirmPassword}
                                />
                                <ContainerAllSwitchs>
                                    <ContainerDividerBackground>
                                        <HeaderInput>Background Card</HeaderInput>
                                        <ContainerPointer>
                                            <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <ContainerButton {...getRootProps()}>
                                                        {typeof photoCard === 'object' ? (
                                                            <>
                                                                {photoCard?.file ? (
                                                                    <ImageCardAndPage
                                                                        src={photoCard?.file}
                                                                    />
                                                                ) : RenderDragMessage('card')}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {photoCard ? (
                                                                    <ImageCardAndPage
                                                                        src={String(photoCard)}
                                                                    />
                                                                ) : (
                                                                    RenderDragMessage('card')
                                                                )}
                                                            </>
                                                        )}
                                                        <input
                                                            {...getInputProps()}
                                                            onChange={SelectedPhotoCard}
                                                        />
                                                    </ContainerButton>
                                                )}
                                            </Dropzone>
                                        </ContainerPointer>
                                    </ContainerDividerBackground>
                                    <ContainerDividerBackground>
                                        <HeaderInput>Background Page</HeaderInput>
                                        <ContainerPointer>
                                            <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <ContainerButton {...getRootProps()}>
                                                        {typeof photoPage === 'object' ? (
                                                            <>
                                                                {photoPage?.file ? (
                                                                    <ImageCardAndPage
                                                                        src={photoPage?.file}
                                                                    />
                                                                ) : RenderDragMessage('page')}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {photoPage ? (
                                                                    <ImageCardAndPage
                                                                        src={String(photoPage)}
                                                                    />
                                                                ) : (
                                                                    RenderDragMessage('page')
                                                                )}
                                                            </>
                                                        )}
                                                        <input
                                                            {...getInputProps()}
                                                            onChange={SelectedPhotoPage}
                                                        />
                                                    </ContainerButton>
                                                )}
                                            </Dropzone>
                                        </ContainerPointer>
                                    </ContainerDividerBackground>
                                </ContainerAllSwitchs>
                                <ContainerSwitch>
                                    <HeaderSwitch>Ativar ou desativar autor</HeaderSwitch>
                                    <Switch
                                        onChange={onChangeActive}
                                        checked={active}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onColor={colors.theme.green}
                                        offColor={colors.theme.gray}
                                    />
                                </ContainerSwitch>
                                {identifier === 'edit' ? (
                                    <TextCancel onClick={GetAuthors}>Cancelar</TextCancel>
                                ) : null}
                                <ContainerAllSwitchs justifyContent="space-between">
                                    <ButtonDeleteOrCancel
                                        valueLength={ValidationDeleteOrCancel()}
                                        onClick={DeleteAuthors}
                                        identifier={identifier}
                                        disabled={!ValidationDeleteOrCancel()}
                                    >
                                        <TextButtonDeleteOrCancel
                                            valueLength={ValidationDeleteOrCancel()}
                                        >
                                            {identifier === 'create' ? 'Cancelar' : 'Excluir'}
                                        </TextButtonDeleteOrCancel>
                                    </ButtonDeleteOrCancel>
                                    <ButtonSave
                                        valueLength={ValidationSave()}
                                        onClick={SaveAuthors}
                                        identifier={identifier}
                                        disabled={!ValidationSave()}
                                    >
                                        <TextButtonSave>Salvar</TextButtonSave>
                                    </ButtonSave>
                                </ContainerAllSwitchs>
                            </ContainerAuthors>
                            <ListAuthors>
                                <HeaderInput>Relação de autores</HeaderInput>
                                <ContainerAllAuthors>
                                    {allAuthors.map((v) => {
                                        const ActionEditAuthors = () => {
                                            setName(v.name);
                                            setDescription(v.description);
                                            setEmail(v.email);
                                            setPhotoCard(v.backgroundCard);
                                            setPhotoPage(v.backgroundPage);
                                            setActive(v.active);
                                            setIdAuthorsActual(v.id);
                                            setIdentifier('edit');
                                        };

                                        return (
                                            <ContainerPhoto
                                                background={v.backgroundCard}
                                                onClick={ActionEditAuthors}
                                            >
                                                <NameAuthors>{v.name}</NameAuthors>
                                                {/* <ContainerEmpty background={v.cover}>
                                                    <NameAuthors>{v.name}</NameAuthors>
                                                </ContainerEmpty> */}
                                            </ContainerPhoto>
                                        );
                                    })}
                                </ContainerAllAuthors>
                            </ListAuthors>
                        </>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default AuthorsContentsPage;
