// js
import consts from '../utils/consts';

// constants
const { baseUrl } = consts;

// api
async function UploadFile(file: Blob): Promise<string> {
    try {
        const body = new FormData();
        body.append('file', file);
        const method = 'POST';
        const configs = {
            method,
            body,
        };
        const response = await fetch(`${baseUrl}/uploads`, configs);
        const json = await response.json();
        return json?.file || '';
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return '';
    }
}

export default {
    UploadFile,
};
