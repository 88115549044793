// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface HeaderInterfaces {
    fontWeight?: string,
}

interface ButtonActionInterfaces {
    valueLength: boolean,
    identifier?: string,
}

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const ImageCardAndPage = styled.img`
    height: 100px;
    border-radius: 15px;
    object-fit: cover;
    width: 100%;
`;

interface ContainerAllSwitchs {
    justifyContent?: string,
}

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerEmpty = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    /* -webkit-filter: blur(4px); */
    height: 40px;
    width: 80%;
    filter: blur(4px);
`;

export const ContainerButton = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    transition: height 0.2s ease;
    margin-top: 10px;
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 10px; */
    width: 100%;
    height: 100px;
    @media (max-width: 1300px) {
        width: 150px;
    }
`;

export const ContainerPointer = styled.div`
    cursor: pointer;
`;

export const InputNullSelectedArchive = styled.input`
    width: 100px;
    height: 100px;
    position: absolute;
    opacity: 1;
    margin-bottom: 50px;
    background-color: red;
    cursor: pointer;
`;

export const ContainerSwitch = styled.div`
    align-items: center;
    display: flex;
`;

export const ContainerPhoto = styled.div`
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 16px;
    padding: 5px;
    height: 200px;
    width: 200px;
`;

export const ButtonDeleteOrCancel = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${colors.theme.backgroundScreen};
    border-radius: 15px;
    border: 1px solid ${(props) => (props.valueLength ? colors.theme.redLight : colors.theme.gray)};
    margin-bottom: 20px;
    margin-top: ${(props) => (props.identifier === 'edit' ? '20px' : '20px')};
    height: 50px;
    transition-duration: 400ms;
    width: 40%;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 100px;
        border-radius: 5px;
    } */
`;

export const ButtonSave = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.theme.gray)};
    border-radius: 15px;
    border: none;
    margin-bottom: 20px;
    margin-top: ${(props) => (props.identifier === 'edit' ? '20px' : '20px')};
    height: 50px;
    transition-duration: 400ms;
    width: 40%;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 100px;
        border-radius: 5px;
    } */
`;

export const TextButtonSave = styled.h4`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 18px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const TextButtonDeleteOrCancel = styled.h4<ButtonActionInterfaces>`
    color: ${(props) => (props.valueLength ? colors.theme.redLight : colors.theme.gray)};
    font-weight: normal;
    font-size: 18px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const TextCancel = styled.text`
    color: ${colors.theme.pink};
    text-decoration: underline;
    display: flex;
    align-self: center;
    margin-top: 50px;
    cursor: pointer;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const ContainerCategories = styled.div`
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 700px;
    @media (max-width: 1300px) {
        width: auto;
    }
`;

export const Header = styled.text<HeaderInterfaces>`
    color: ${colors.basic.gray};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 14px;
    margin-bottom: 20px;
`;

export const ListCategories = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    width: 100%;
    overflow-y: 'visible';
`;

export const NameCategories = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    text-align: center;
`;

export const ContainerAllCategories = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    overflow-y: 'visible';
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerAllSwitchs = styled.div<ContainerAllSwitchs>`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-bottom: 10px;
    @media (max-width: 1300px) {
        flex-wrap: wrap;
    }
    width: 100%;
`;

export const InputArea = styled.textarea`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    min-height: 150px;
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    resize: none;
`;

export const ContainerDividerBackground = styled.div`
    width: 100%;
    margin-bottom: 10px;
    margin-right: 15px;
    @media (max-width: 1300px) {
        width: 50%;
    }
`;

export const IconCloud = styled.img`
    height: 36px;
    width: 36px;
`;

export const TextBox = styled.text`
    color: ${colors.basic.gray};
    font-size: 10px;
    font-weight: bold;
    text-align: center;
`;

export const DropContainer = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: height 0.2s ease;
    margin-top: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 90%;
    height: 100px;
`;

export const InputDefault = styled.input`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    min-height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 60%;
        border-radius: 15px;
        font-size: 11px;
    } */
`;

export const HeaderSwitch = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    padding-right: 15px;
`;

export const HeaderInput = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    padding-left: 15px;
`;
