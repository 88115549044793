/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React, { useEffect, useState } from 'react';
import {
    Chip,
    MenuItem, Select,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

// js
import {
    addMonths, getDate, getMonth, getYear, lightFormat, parseISO,
} from 'date-fns';
import colors from '../../../styles/colors';
import users from '../../../services/users';
import { RootState } from '../../../store';
import {
    Container,
    ContainerInner,
    ContainerUsers,
    Home,
    Header,
    InputDefault,
    HeaderInput,
    ContainerAllSwitchs,
    ButtonDeleteOrCancel,
    TextButtonDeleteOrCancel,
    ButtonSave,
    TextButtonSave,
    ListUser,
    HeaderListUser,
    ContainerList,
    EditIcon,
    ButtonEdit,
    TextCancel,
    ContainerInnerUsers,
    ContainerChips,
} from './usersStyles';
import { editUser } from '../../../assets/index';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';
import Search from '../../../components/Search/SearchComponent';
import usersCategory from '../../../services/usersCategory';

// interfaces
interface UsersInterfaces {
    active: boolean,
    easyPass: boolean,
    name: string,
    email: string,
    id: string,
}

const UsersPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const pathIsTrue = window.location.pathname.includes('/usersSettings');

    // states
    const [search, setSearch] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [active, setActive] = useState(false);
    const [easyPass, setEasyPass] = useState(false);
    const [allUsers, setAllUsers] = useState<Array<UsersInterfaces>>([]);
    const [allUsersCount, setAllUsersCount] = useState<Array<any>>([]);
    const [identifier, setIdentifier] = useState('create');
    const [idUserActual, setIdUserActual] = useState('');
    const [idUserCategoryActual, setIdUserCategoryActual] = useState('');
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [allCategories, setAllCategories] = useState<Array<any>>([]);

    // methods
    const onChangeSearch = async (txt: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(txt.target.value);
    };

    const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setName(txt.target.value);
    };

    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(txt.target.value);
    };

    const onChangeUserCategoryActual = (event: any) => {
        setIdUserCategoryActual(event.target.value);
    };

    const onChangePassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(txt.target.value);
    };

    const onChangeConfirmPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(txt.target.value);
    };

    const onChangeActive = () => {
        setActive(!active);
    };

    const onChangeEasyPass = () => {
        setEasyPass(!easyPass);
    };

    const ValidationDeleteOrCancel = () => {
        if (identifier === 'create') {
            if (
                email.length > 0
                || name.length > 0
                || password.length > 0
                || confirmPassword.length > 0
            ) {
                return true;
            }
        }
        if (identifier === 'edit') {
            return true;
        }

        return false;
    };

    const ValidationSave = () => {
        const validationFormsCreate = identifier === 'create' && email.length > 0 && name.length > 0 && password.length >= 6 && confirmPassword.length >= 6 && password === confirmPassword;
        const validationFormsEdit = identifier === 'edit' && email.length > 0 && name.length > 0;
        return validationFormsCreate || validationFormsEdit;
    };

    const GetUsers = async () => {
        setName('');
        setActive(false);
        setEasyPass(false);
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setSearch('');
        setIdentifier('create');
        setIdUserActual('');
        setIdUserCategoryActual('');
        const infoGetUsers = await users.GetAllUsers('', pathIsTrue ? 'user' : 'adm', infoUser?.token);
        if (infoGetUsers.status === 200) {
            setAllUsers(infoGetUsers.result);
        }
    };

    const DeleteUserOrCancel = async () => {
        if (identifier === 'edit') {
            const infoCreate = await users.DeleteOneUser(idUserActual, infoUser.token);
            if (infoCreate.status === 200) {
                GetUsers();
            } else {
                // FIXME - Adicionar modal de exibição de avisos.
                // eslint-disable-next-line no-alert
                alert(infoCreate?.message || 'Erro ao deletar usuário');
            }
        }
    };

    const SaveUser = async () => {
        if (identifier === 'create') {
            const infoCreate = await users.CreateOneUser(
                !pathIsTrue,
                name,
                email,
                password,
                easyPass,
                active,
                idUserCategoryActual,
            );
            if (infoCreate.status === 201) {
                GetUsers();
            }
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert(infoCreate?.message);
        }
        if (identifier === 'edit') {
            const infoCreate = await users.EditOneUser(
                name, email, easyPass, active, idUserCategoryActual, idUserActual,
            );
            if (infoCreate.status === 200) {
                GetUsers();
            }
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert(infoCreate?.message);
        }
    };

    const GetCategories = async () => {
        const infoGetCategories = await usersCategory.GetAllCategoryUsers(infoUser?.token);
        if (infoGetCategories.status === 200 && infoGetCategories?.result?.length) {
            setAllCategories(infoGetCategories.result.map((v: any) => ({
                id: v.id,
                name: v.name,
            })));
        }
    };

    // useEffect
    useEffect(() => {
        GetCategories();
    }, []);

    useEffect(() => {
        GetUsers();
    }, [window.location.pathname]);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            const infoGetUsers = await users.GetAllUsers(search, pathIsTrue ? 'user' : 'adm', infoUser?.token);
            if (infoGetUsers.status === 200) {
                setAllUsers(infoGetUsers.result);
                setAllUsersCount(infoGetUsers.allCount);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [search]);

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <ContainerInnerUsers>
                            <ContainerUsers>
                                <Header>
                                    {pathIsTrue ? 'Configurações Gerais > ' : 'Administração > '}
                                    <Header fontWeight="bold">{pathIsTrue ? 'Usuários' : 'Usuários Admin'}</Header>
                                </Header>
                                <HeaderInput>Nome do usuário</HeaderInput>
                                <InputDefault
                                    value={name}
                                    type="email"
                                    onChange={onChangeName}
                                />
                                <HeaderInput>E-mail</HeaderInput>
                                <InputDefault
                                    value={email}
                                    type="email"
                                    onChange={onChangeEmail}
                                />
                                <HeaderInput>Categoria do usuário</HeaderInput>
                                <Select
                                    value={idUserCategoryActual}
                                    label="idUserCategoryActual"
                                    onChange={onChangeUserCategoryActual}
                                    style={{
                                        backgroundColor: 'white',
                                        color: colors.basic.gray,
                                        border: `1px solid ${colors.basic.whiteFrosted}`,
                                        height: 60,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        width: '100%',
                                        marginBottom: 20,
                                        fontWeight: '500',
                                        borderRadius: 40,
                                        fontSize: 14,
                                    }}
                                    disableUnderline
                                >
                                    {allCategories.map((v) => (
                                        <MenuItem value={v.id}>{v.name}</MenuItem>
                                    ))}
                                </Select>
                                {identifier === 'create' ? (
                                    <>
                                        <HeaderInput>Senha</HeaderInput>
                                        <InputDefault
                                            value={password}
                                            type="password"
                                            onChange={onChangePassword}
                                        />
                                        <HeaderInput>Confirmar senha</HeaderInput>
                                        <InputDefault
                                            value={confirmPassword}
                                            type="password"
                                            onChange={onChangeConfirmPassword}
                                        />
                                    </>
                                ) : null}
                                <ContainerAllSwitchs>
                                    <HeaderInput>Ativar ou desativar</HeaderInput>
                                    <Switch
                                        onChange={onChangeActive}
                                        checked={active}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onColor={colors.theme.green}
                                        offColor={colors.theme.gray}
                                    />
                                    <HeaderInput>Easy Pass</HeaderInput>
                                    <Switch
                                        onChange={onChangeEasyPass}
                                        checked={easyPass}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onColor={colors.theme.green}
                                        offColor={colors.theme.gray}
                                    />
                                </ContainerAllSwitchs>
                                {identifier === 'edit' ? (
                                    <TextCancel onClick={GetUsers}>Cancelar</TextCancel>
                                ) : null}
                                <ContainerAllSwitchs>
                                    <ButtonDeleteOrCancel
                                        valueLength={ValidationDeleteOrCancel()}
                                        onClick={DeleteUserOrCancel}
                                        identifier={identifier}
                                        disabled={!ValidationDeleteOrCancel()}
                                    >
                                        <TextButtonDeleteOrCancel
                                            valueLength={ValidationDeleteOrCancel()}
                                        >
                                            {identifier === 'create' ? 'Cancelar' : 'Excluir'}
                                        </TextButtonDeleteOrCancel>
                                    </ButtonDeleteOrCancel>
                                    <ButtonSave
                                        valueLength={ValidationSave()}
                                        onClick={SaveUser}
                                        identifier={identifier}
                                        disabled={!ValidationSave()}
                                    >
                                        <TextButtonSave>Salvar</TextButtonSave>
                                    </ButtonSave>
                                </ContainerAllSwitchs>
                                <Search
                                    value={search}
                                    onChange={onChangeSearch}
                                    onClosed={GetUsers}
                                />
                                <ContainerChips>
                                    {allUsersCount.map((v) => (
                                        <Chip key={v.id} label={`${v?.userCategory.name} - ${v?.count}`} />
                                    ))}
                                </ContainerChips>
                            </ContainerUsers>
                            <ListUser>
                                <ContainerList>
                                    <HeaderListUser fontWeight="bold">Nome Completo</HeaderListUser>
                                    <HeaderListUser fontWeight="bold">E-mail</HeaderListUser>
                                    <HeaderListUser fontWeight="bold">Data de Criação</HeaderListUser>
                                    <HeaderListUser fontWeight="bold">Data da Assinatura / Próxima Cobrança</HeaderListUser>
                                    <HeaderListUser fontWeight="bold">Tipo da Assinatura / Plataforma da Assinatura</HeaderListUser>
                                    <HeaderListUser flex={0.2} fontWeight="bold">Editar</HeaderListUser>
                                </ContainerList>
                                {allUsers.map((v: any) => {
                                    const ActionEditUser = () => {
                                        setName(v.finalUser.name || '');
                                        setEmail(v.finalUser.email || '');
                                        setPassword('');
                                        setConfirmPassword('');
                                        setActive(v.finalUser.active);
                                        setEasyPass(v.finalUser.easyPass);
                                        setIdUserActual(v.finalUser.id);
                                        setIdUserCategoryActual(v.finalUser.idUserCategory);
                                        setIdentifier('edit');
                                    };

                                    const getPeriod = () => {
                                        if (!v.finalUser.easyPass) {
                                            return 'N/A';
                                        }
                                        if (v?.historic?.productId === 'monthly_easyliving' || v?.historic?.productId === 'signature_easyliving') {
                                            return 'Mensal';
                                        }
                                        if (v?.historic?.productId === 'semester_easyliving') {
                                            return 'Semestral';
                                        }
                                        if (v?.historic?.productId === 'yearly_easyliving') {
                                            return 'Anual';
                                        }
                                        return v?.historic?.productId || 'N/A';
                                    };

                                    function getNextBilling() {
                                        if (!v?.historic?.datePaymentPlan) {
                                            return 'N/A';
                                        }

                                        const cDate = getDate(
                                            v?.historic?.datePaymentPlan,
                                        );
                                        const cMonth = getMonth(
                                            v?.historic?.datePaymentPlan,
                                        );
                                        const cYear = getYear(
                                            v?.historic?.datePaymentPlan,
                                        );
                                        const getDateCurrent = new Date(
                                            cYear,
                                            cMonth,
                                            cDate,
                                        );

                                        if (v?.historic?.productId === 'monthly_easyliving' || v?.historic?.productId === 'signature_easyliving') {
                                            const formatDate = lightFormat(addMonths(getDateCurrent, 1), 'dd/MM/yyyy HH:mm');
                                            return formatDate;
                                        }
                                        if (v?.historic?.productId === 'semester_easyliving') {
                                            const formatDate = lightFormat(addMonths(getDateCurrent, 6), 'dd/MM/yyyy HH:mm');
                                            return formatDate;
                                        }
                                        if (v?.historic?.productId === 'yearly_easyliving') {
                                            const formatDate = lightFormat(addMonths(getDateCurrent, 12), 'dd/MM/yyyy HH:mm');
                                            return formatDate;
                                        }

                                        return 'Infinity';
                                    }

                                    return (
                                        <ContainerList key={v?.finalUser?.id}>
                                            <HeaderListUser>{v?.finalUser?.name?.substr(0, 24) || ''}</HeaderListUser>
                                            <HeaderListUser>{v?.finalUser?.email?.substr(0, 36) || ''}</HeaderListUser>
                                            <HeaderListUser>{!v?.finalUser?.createdAt ? 'N/A' : lightFormat(parseISO(v?.finalUser?.createdAt), 'dd/MM/yyyy HH:mm')}</HeaderListUser>
                                            <HeaderListUser>{`${v?.finalUser?.historic?.datePaymentPlan ? lightFormat(v.finalUser?.historic?.datePaymentPlan, 'dd/MM/yyyy HH:mm') : 'N/A'} | ${getNextBilling()}`}</HeaderListUser>
                                            <HeaderListUser>{`${getPeriod()} | ${v?.historic?.platform}`}</HeaderListUser>
                                            <ButtonEdit onClick={ActionEditUser}>
                                                <EditIcon src={editUser} />
                                            </ButtonEdit>
                                        </ContainerList>
                                    );
                                })}
                            </ListUser>
                        </ContainerInnerUsers>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default UsersPage;
