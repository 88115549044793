// js
import { LOGOUT, UPDATE_USER } from './constants';
import { AppDispatch } from '../../index';

// interfaces
import { DataUserInterface } from '../../../interfaces';

// actions
export const logoutAuth: AppDispatch = () => (dispatch: AppDispatch) => {
    dispatch({
        type: LOGOUT,
    });
};

export const updateUser: AppDispatch = (user: DataUserInterface) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_USER,
        payload: user,
    });
};
