/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import usersCategory from '../../../services/usersCategory';

// interfaces
import { DataCategoriesInterface } from '../../../interfaces';

const GetAllCategories = async (
    setName: (v: string) => void,
    setIdentifier: (v: string) => void,
    setAllCategories: (v: Array<DataCategoriesInterface>) => void,
    token: string,
): Promise<void> => {
    setName('');
    setIdentifier('create');
    const infoGetCategories = await usersCategory.GetAllCategoryUsers(token);
    if (infoGetCategories.status === 200) {
        setAllCategories(infoGetCategories.result);
    }
};

const DeleteCategories = async (
    setName: (v: string) => void,
    setIdentifier: (v: string) => void,
    setAllCategories: (v: Array<DataCategoriesInterface>) => void,
    idCategoriesActual: string,
    token: string,
): Promise<void> => {
    // rota para deletar usuário
    const DelCategories = await usersCategory.DeleteOneCategoryUser(idCategoriesActual, token);
    // eslint-disable-next-line no-alert
    alert(DelCategories.message);
    GetAllCategories(
        setName,
        setIdentifier,
        setAllCategories,
        token,
    );
};

const SaveCategories = async (
    name: string,
    setName: (v: string) => void,
    setIdentifier: (v: string) => void,
    setAllCategories: (v: Array<DataCategoriesInterface>) => void,
    identifier: string,
    idCategoriesActual: string,
    token: string,
): Promise<void> => {
    if (identifier === 'create') {
        const GetInfoCreate = await usersCategory.CreateOneCategoryUser(
            name,
            token,
        );
        // eslint-disable-next-line no-alert
        alert(GetInfoCreate.message);
        GetAllCategories(
            setName,
            setIdentifier,
            setAllCategories,
            token,
        );
    }
    if (identifier === 'edit') {
        const EditCategories = await usersCategory.EditOneCategoryUser(
            name,
            idCategoriesActual,
            token,
        );
        // eslint-disable-next-line no-alert
        alert(EditCategories.message);
        GetAllCategories(
            setName,
            setIdentifier,
            setAllCategories,
            token,
        );
    }
};

export default {
    GetAllCategories,
    DeleteCategories,
    SaveCategories,
};
