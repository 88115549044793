// libraries
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    XYPlot,
    AreaSeries,
    HorizontalGridLines,
    VerticalGridLines,
    XAxis,
    YAxis,
    RadialChart,
} from 'react-vis';

// js
import auth from '../../services/auth';
import dashboard from '../../services/dashboard';
import {
    Box,
    Home,
    Container,
    ContainerBox,
    ContainerInner,
    TitleFormat,
    ImgInfoHeader,
    ContainerTitle,
    ContainerHeader,
    NumberQtd,
    ContainerDivider,
    ContainerCharts,
    ContainerBody,
    ContainerChartsDivider,
    ContainerDividirChartRadius,
    ImgAssetsChartRadius,
    ContainerInfoRadius,
    ContainerInfoPercentage,
} from './homeStyles';
import { updateUser } from '../../store/modules/user/actions';
import { RootState } from '../../store';
import { DashboardInterface, InfoDashBoardInterface } from '../../interfaces';
import { desktopPink, tabletPink, mobilePink } from '../../assets/index';
import functionsGeneral from '../../utils/functions';
import colors from '../../styles/colors';
import functions from './homeFunctions';

// components
import MenuComponent from '../../components/Menu/MenuComponent';

const HomePage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const stylesXAxis = {
        text: {
            stroke: 'none',
            fill: colors.theme.grayIntense,
            fontWeight: 400,
            fontSize: 12,
        },
        line: { stroke: colors.theme.grayIntense },
        ticks: { stroke: colors.theme.grayIntense },
    };

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [infoDashboard, setInfoDashboard] = useState<DashboardInterface>({});
    const [infoHeader, setInfoHeader] = useState<Array<InfoDashBoardInterface>>([]);

    // functions for use effect
    const RefreshTokenAction = async () => {
        const refresh = await auth.RefreshToken(infoUser?.tokenRefresh || '');
        const newFormatInfoUser = {
            ...infoUser,
            token: refresh.token,
        };
        dispatch(updateUser(newFormatInfoUser));
    };

    const GetDashboard = async () => {
        const allInfoDashboard = await dashboard.GetInfoDashboard(infoUser?.token);
        if (allInfoDashboard?.status === 200) {
            setInfoDashboard(allInfoDashboard.result);
            setInfoHeader(Object.values(allInfoDashboard.result).filter((v) => v.format));
        }
    };

    // useEffect
    useEffect(() => {
        RefreshTokenAction();
        GetDashboard();
    }, []);

    // renders
    const RenderHeaderDashboard = (item: InfoDashBoardInterface) => (
        <Box>
            <ContainerHeader>
                <ContainerDivider width="50%">
                    <ContainerTitle>
                        <ImgInfoHeader src={functions.VerificationImage(item)} />
                        <TitleFormat marginBottom="5px">{functions.VerificationName(item)}</TitleFormat>
                    </ContainerTitle>
                    <NumberQtd color={functions.VerificationColor(item)}>{item.qtd}</NumberQtd>
                </ContainerDivider>
                <TitleFormat fontSize="20px" marginBottom="5px">{item.percentage}</TitleFormat>
            </ContainerHeader>
            {item.moreAccess.length ? (
                <>
                    <ContainerDivider marginBottom="10px">
                        <TitleFormat>Mais acessados</TitleFormat>
                        <TitleFormat>Views</TitleFormat>
                    </ContainerDivider>
                    {item.moreAccess.map((v) => (
                        <ContainerDivider>
                            <ContainerDivider>
                                <ImgInfoHeader src={v.cover} />
                                <TitleFormat flex={1} fontWeight="normal">{`${v.title.slice(0, 20)}...`}</TitleFormat>
                            </ContainerDivider>
                            <TitleFormat fontWeight="normal">{v.count}</TitleFormat>
                        </ContainerDivider>
                    ))}
                </>
            ) : null}
        </Box>
    );

    // FIXME Liberar este script quando passar de 1k++ de usuários
    // const RenderTickFormatY = (v: number) => `${v}k`;

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <ContainerBody>
                            <ContainerBox>
                                {infoHeader.map((item) => RenderHeaderDashboard(item))}
                            </ContainerBox>
                            <ContainerCharts>
                                <ContainerChartsDivider>
                                    <TitleFormat>Quantidade de usuários</TitleFormat>
                                    <XYPlot width={749} height={300}>
                                        <HorizontalGridLines />
                                        <VerticalGridLines />
                                        <XAxis
                                            marginLeft={32}
                                            style={stylesXAxis}
                                            tickFormat={(v) => `${functionsGeneral.RenderTickFormatXMonth(v)} (${(infoDashboard?.infoUsers || [])[v].y})`}
                                            tickSize={3}
                                        />
                                        <YAxis
                                            style={stylesXAxis}
                                            // tickFormat={RenderTickFormatY}
                                            tickSize={3}
                                        />
                                        <AreaSeries
                                            fill={colors.theme.pinkUltraLight}
                                            stroke={colors.theme.pinkUltra}
                                            data={infoDashboard.infoUsers}
                                        />
                                    </XYPlot>
                                </ContainerChartsDivider>
                                <ContainerChartsDivider width="300px">
                                    <TitleFormat>Acessos por Dispositivo</TitleFormat>
                                    <ContainerDividirChartRadius>
                                        <RadialChart
                                            colorType="literal"
                                            innerRadius={45}
                                            radius={70}
                                            data={functions.InfoRadius(infoDashboard)}
                                            width={150}
                                            height={150}
                                        />
                                        <ContainerInfoRadius>
                                            <ContainerDividirChartRadius>
                                                <ImgAssetsChartRadius src={mobilePink} size="15px" />
                                                <ContainerInfoPercentage>
                                                    <TitleFormat fontSize="11px" fontWeight="400">Celular</TitleFormat>
                                                    <TitleFormat>
                                                        {String(infoDashboard.infoAccess?.mobile).includes('.') ? `${String(infoDashboard.infoAccess?.mobile).replace('.', ',')}%` : `${infoDashboard.infoAccess?.mobile}%`}
                                                    </TitleFormat>
                                                </ContainerInfoPercentage>
                                            </ContainerDividirChartRadius>
                                            <ContainerDividirChartRadius>
                                                <ImgAssetsChartRadius src={desktopPink} />
                                                <ContainerInfoPercentage>
                                                    <TitleFormat fontSize="11px" fontWeight="400">Desktop</TitleFormat>
                                                    <TitleFormat>
                                                        {String(infoDashboard.infoAccess?.pc).includes('.') ? `${String(infoDashboard.infoAccess?.pc).replace('.', ',')}%` : `${infoDashboard.infoAccess?.pc}%`}
                                                    </TitleFormat>
                                                </ContainerInfoPercentage>
                                            </ContainerDividirChartRadius>
                                            <ContainerDividirChartRadius>
                                                <ImgAssetsChartRadius src={tabletPink} />
                                                <ContainerInfoPercentage>
                                                    <TitleFormat fontSize="11px" fontWeight="400">Tablet</TitleFormat>
                                                    <TitleFormat>
                                                        {String(infoDashboard.infoAccess?.tablet).includes('.') ? `${String(infoDashboard.infoAccess?.tablet).replace('.', ',')}%` : `${infoDashboard.infoAccess?.tablet}%`}
                                                    </TitleFormat>
                                                </ContainerInfoPercentage>
                                            </ContainerDividirChartRadius>
                                        </ContainerInfoRadius>
                                    </ContainerDividirChartRadius>
                                </ContainerChartsDivider>
                            </ContainerCharts>
                        </ContainerBody>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default HomePage;
