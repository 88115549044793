// libraries
import React from 'react';

// js
import colors from '../../../styles/colors';
import {
    TitleFormat,
    ContainerDivider,
    ButtonAction,
    TextAction,
    ContainerSelect,
    Option,
    ViewSelects,
} from './dashFinancialStyles';

// interfaces
import { DataAuthorsInterface } from '../../../interfaces';
import { InitialFiltersInterfaces } from './dashFinanceInterfaces';

const RenderTitle = (
    title: string,
    allFilters: InitialFiltersInterfaces,
    setAllFilters: (v: InitialFiltersInterfaces) => void,
    author: string,
    setAuthor: (v: string) => void,
    allAuthors: Array<DataAuthorsInterface>,
): React.ReactElement | null => {
    const onChangeFormat = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        const FormatSplit = txt.target.value.split(',');
        setAuthor(FormatSplit[1]);
        setAllFilters({ ...allFilters, idAuthor: FormatSplit[0] });
    };

    const RemoveAuthor = () => {
        setAuthor('');
        setAllFilters({ ...allFilters, idAuthor: '' });
    };

    const RenderAuthors = () => (
        allAuthors.map((v) => (
            <Option value={`${v.id},${v.name}`}>{v.name}</Option>
        ))
    );

    if (title === 'Faturamento') {
        // const onChangeFilterRevenuesMonth = () => {
        //     setAllFilters({
        //         ...allFilters,
        //         filterRevenues: 'month',
        //     });
        // };
        // const onChangeFilterRevenuesYear = () => {
        //     setAllFilters({
        //         ...allFilters,
        //         filterRevenues: 'year',
        //     });
        // };
        return (
            <ContainerDivider>
                <ContainerDivider>
                    <TitleFormat>{title}</TitleFormat>
                    {/*
                    <ButtonAction
                    backgroundColor={allFilters.filterRevenues === 'month' ?
                    colors.basic.grayLight : colors.basic.white}
                    onClick={onChangeFilterRevenuesMonth}>
                        <TextAction
                        cursor="pointer"
                        color={allFilters.filterRevenues !== 'month' ?
                        colors.basic.grayLight : colors.basic.white}>Mensal</TextAction>
                    </ButtonAction> */}
                    {/*
                        <ButtonAction
                            backgroundColor={allFilters.filterRevenues === 'year' ?
                            colors.basic.grayLight : colors.basic.white}
                            onClick={onChangeFilterRevenuesYear}
                        >
                            <TextAction
                                cursor="pointer"
                                color={allFilters.filterRevenues !== 'year'
                                ? colors.basic.grayLight : colors.basic.white}
                            >
                                Anual
                            </TextAction>
                        </ButtonAction>
                    */}
                </ContainerDivider>
            </ContainerDivider>
        );
    }

    if (title === 'Por autor') {
        // const onChangeFilterAuthorMonth = () => {
        //     setAllFilters({
        //         ...allFilters,
        //         filterAuthor: 'month',
        //     });
        // };
        // const onChangeFilterAuthorYear = () => {
        //     setAllFilters({
        //         ...allFilters,
        //         filterAuthor: 'year',
        //     });
        // };
        return (
            <ContainerDivider alignItems="flex-start">
                <ContainerDivider>
                    <TitleFormat>{title}</TitleFormat>
                    {/*
                        <ButtonAction
                        backgroundColor={allFilters.filterAuthor === 'month' ?
                        colors.basic.grayLight : colors.basic.white}
                        onClick={onChangeFilterAuthorMonth}>
                            <TextAction
                            cursor="pointer"
                            color={allFilters.filterAuthor !== 'month' ?
                            colors.basic.grayLight : colors.basic.white}>Mensal</TextAction>
                        </ButtonAction>
                    */}
                    {/*
                        <ButtonAction
                        backgroundColor={allFilters.filterAuthor === 'year' ?
                            colors.basic.grayLight : colors.basic.white}
                        onClick={onChangeFilterAuthorYear}>
                            <TextAction
                                cursor="pointer"
                                color={allFilters.filterAuthor !== 'year'
                                ? colors.basic.grayLight : colors.basic.white}
                            >
                                Anual
                            </TextAction>
                        </ButtonAction>
                    */}
                </ContainerDivider>
                <ContainerDivider flexDirection="column">
                    <ContainerSelect onChange={onChangeFormat} value="">
                        <Option value="">Selecione o autor</Option>
                        {RenderAuthors()}
                    </ContainerSelect>
                    {author.length ? (
                        <ContainerDivider width="250px">
                            <ViewSelects>
                                <TextAction color={colors.basic.white} marginRight="10px">{author}</TextAction>
                                <TextAction
                                    color={colors.basic.white}
                                    onClick={RemoveAuthor}
                                    fontSize="12px"
                                    cursor="pointer"
                                >
                                    X
                                </TextAction>
                            </ViewSelects>
                        </ContainerDivider>
                    ) : null}
                </ContainerDivider>
            </ContainerDivider>
        );
    }

    if (title === 'Por formato') {
        const onChangeVideo = () => {
            setAllFilters({
                ...allFilters,
                format: 'video',
            });
        };
        const onChangeAudio = () => {
            setAllFilters({
                ...allFilters,
                format: 'audio',
            });
        };
        const onChangeText = () => {
            setAllFilters({
                ...allFilters,
                format: 'texto',
            });
        };
        // const onChangeFilterFormatMonth = () => {
        //     setAllFilters({
        //         ...allFilters,
        //         filterFormat: 'month',
        //     });
        // };
        // const onChangeFilterFormatYear = () => {
        //     setAllFilters({
        //         ...allFilters,
        //         filterFormat: 'year',
        //     });
        // };
        return (
            <ContainerDivider>
                <ContainerDivider>
                    <TitleFormat>{title}</TitleFormat>
                    {/*
                        <ButtonAction
                        backgroundColor={allFilters.filterFormat === 'month' ?
                        colors.basic.grayLight : colors.basic.white}
                        onClick={onChangeFilterFormatMonth}>
                            <TextAction
                            cursor="pointer"
                            color={allFilters.filterFormat !== 'month' ?
                            colors.basic.grayLight : colors.basic.white}>Mensal</TextAction>
                        </ButtonAction>
                    */}
                    {/*
                        <ButtonAction
                            backgroundColor={allFilters.filterFormat === 'year' ?
                            colors.basic.grayLight : colors.basic.white}
                            onClick={onChangeFilterFormatYear}
                        >
                            <TextAction
                                cursor="pointer"
                                color={allFilters.filterFormat !== 'year' ?
                                colors.basic.grayLight : colors.basic.white}
                            >
                                Anual
                            </TextAction>
                        </ButtonAction>
                    */}
                </ContainerDivider>
                <ContainerDivider>
                    <ButtonAction backgroundColor={allFilters.format === 'texto' ? colors.basic.grayLight : colors.basic.white} onClick={onChangeText}>
                        <TextAction cursor="pointer" color={allFilters.format !== 'texto' ? colors.basic.grayLight : colors.basic.white}>Texto</TextAction>
                    </ButtonAction>
                    <ButtonAction backgroundColor={allFilters.format === 'audio' ? colors.basic.grayLight : colors.basic.white} onClick={onChangeAudio}>
                        <TextAction cursor="pointer" color={allFilters.format !== 'audio' ? colors.basic.grayLight : colors.basic.white}>Áudio</TextAction>
                    </ButtonAction>
                    <ButtonAction backgroundColor={allFilters.format === 'video' ? colors.basic.grayLight : colors.basic.white} onClick={onChangeVideo}>
                        <TextAction cursor="pointer" color={allFilters.format !== 'video' ? colors.basic.grayLight : colors.basic.white}>Vídeo</TextAction>
                    </ButtonAction>
                </ContainerDivider>
            </ContainerDivider>
        );
    }

    return null;
};

export default {
    RenderTitle,
};
