// js
import appearance from '../../../services/appearance';
import uploads from '../../../services/uploads';

// interfaces
import { DataAppearanceInterfaces, DataArchiveInterface } from '../../../interfaces';

const GetAllAppearance = async (
    setAllAppearance: (v: Array<DataAppearanceInterfaces>) => void,
    setDataLogin: (v: DataAppearanceInterfaces) => void,
    setDataAuthor: (v: DataAppearanceInterfaces) => void,
    setDataGeneral: (v: DataAppearanceInterfaces) => void,
    setDataSearch: (v: DataAppearanceInterfaces) => void,
    token: string,
): Promise<void> => {
    // rota para pegar todas as categorias
    const infoGetAppearance = await appearance.GetAllAppearance(token);
    if (infoGetAppearance.status === 200) {
        setAllAppearance(infoGetAppearance?.result || []);
        const getLogin = (infoGetAppearance?.result || []).find((v) => v.title === 'Login');
        const getGeneral = (infoGetAppearance?.result || []).find((v) => v.title === 'Geral');
        const getAuthor = (infoGetAppearance?.result || []).find((v) => v.title === 'Autor');
        const getSearch = (infoGetAppearance?.result || []).find((v) => v.title === 'Busca');
        if (getLogin) {
            setDataLogin({
                audio: getLogin.audio,
                color: getLogin.color,
                id: getLogin.id,
                showAudio: getLogin.showAudio,
                title: getLogin.title,
                type: getLogin.type,
                uri: getLogin.uri,
                palette: false,
            });
        }
        if (getGeneral) {
            setDataGeneral({
                audio: getGeneral.audio,
                color: getGeneral.color,
                id: getGeneral.id,
                showAudio: getGeneral.showAudio,
                title: getGeneral.title,
                type: getGeneral.type,
                uri: getGeneral.uri,
                palette: false,
            });
        }
        if (getAuthor) {
            setDataAuthor({
                audio: getAuthor.audio,
                color: getAuthor.color,
                id: getAuthor.id,
                showAudio: getAuthor.showAudio,
                title: getAuthor.title,
                type: getAuthor.type,
                uri: getAuthor.uri,
                palette: false,
            });
        }
        if (getSearch) {
            setDataSearch({
                audio: getSearch.audio,
                color: getSearch.color,
                id: getSearch.id,
                showAudio: getSearch.showAudio,
                title: getSearch.title,
                type: getSearch.type,
                uri: getSearch.uri,
                palette: false,
            });
        }
    }
};

const SaveAppearance = async (
    changedLoading: () => void,
    fontColor: string,
    backgroundUrl: DataArchiveInterface | string,
    audioUrl: DataArchiveInterface | string,
    identifier: string,
    type: string,
    setDataLogin: (v: DataAppearanceInterfaces) => void,
    setDataAuthor: (v: DataAppearanceInterfaces) => void,
    setDataGeneral: (v: DataAppearanceInterfaces) => void,
    setDataSearch: (v: DataAppearanceInterfaces) => void,
    setAllAppearance: (v: Array<DataAppearanceInterfaces>) => void,
    token: string,
) : Promise<void> => {
    const uriPhoto = typeof backgroundUrl === 'string' ? backgroundUrl : await uploads.UploadFile(backgroundUrl?.eventFile);
    const uriAudio = typeof audioUrl === 'string' ? audioUrl : await uploads.UploadFile(audioUrl?.eventFile);
    if (identifier === 'create') {
        await appearance.CreateOneAppearance(
            fontColor,
            uriPhoto,
            uriAudio,
            type,
            token,
        );
        changedLoading();
        GetAllAppearance(
            setAllAppearance,
            setDataLogin,
            setDataAuthor,
            setDataGeneral,
            setDataSearch,
            token,
        );
    }
    if (identifier === 'edit') {
        await appearance.EditOneAppearance(
            fontColor,
            uriPhoto,
            uriAudio,
            type,
            token,
        );
        changedLoading();
        GetAllAppearance(
            setAllAppearance,
            setDataLogin,
            setDataAuthor,
            setDataGeneral,
            setDataSearch,
            token,
        );
    }
};

export default {
    GetAllAppearance,
    SaveAppearance,
};
