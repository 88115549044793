// js
import consts from '../utils/consts';
import { DataUserInterface } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// interfaces
interface AuthInterfaces {
    status: number,
    result: { user: DataUserInterface, token: string, tokenRefresh: string };
    message: string,
}

// api
async function Login(email: string, password: string): Promise<AuthInterfaces> {
    try {
        const data = {
            email,
            password,
            typeDevice: 'web-adm',
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/token?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { user: {}, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

async function RefreshToken(refresh: string): Promise<{ token: string }> {
    try {
        const data = {
            refresh,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/refreshToken?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            token: '',
        };
    }
}

async function EmitTradePassword(email: string): Promise<AuthInterfaces> {
    try {
        const data = {
            email,
            typeDevice: 'web-adm',
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/recoverPassword?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            message: '',
            status: 500,
            result: { user: {}, token: '', tokenRefresh: '' },
        };
    }
}

async function TradePassword(
    email: string,
    newPassword: string,
    confirmedNewPassword: string,
    oldPassword: string,
): Promise<AuthInterfaces> {
    try {
        const data = {
            email,
            newPassword,
            confirmedNewPassword,
            oldPassword,
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/updatePassword?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            message: '',
            status: 500,
            result: { user: {}, token: '', tokenRefresh: '' },
        };
    }
}

export default {
    Login,
    RefreshToken,
    EmitTradePassword,
    TradePassword,
};
