// libraries
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// js
import {
    AdjustSpace,
    Header,
    Logo,
    TxtHeader,
    Home,
    Title,
    Subtitle,
    Selector,
    Separator,
    Logout,
    OpenIcon,
    HamburgerOpen,
    ContainerMenu,
} from './menuStyles';
import { logoFullSize, drawer, goBackPinkLeft } from '../../assets/index';
import { Contents, GeneralSettings, Administration } from './menuConstants';
import { logoutAuth } from '../../store/modules/user/actions';

// components
import ToNavigation from '../../routes/navigation';

// interfaces
interface Props {
    setVisibleDrawer: (visibleDrawer: boolean) => void,
}

const MenuComponent: React.FC<Props> = (props: Props) => {
    // dispatch
    const dispatch = useDispatch();

    // consts
    const location = useLocation();
    const { setVisibleDrawer } = props;

    // states
    const [currentLocation, setCurrentLocation] = useState('/');
    const [visibleItem, setVisibleItem] = useState(false);
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // useEffect
    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location]);

    // methods
    const onLogout = () => {
        dispatch(logoutAuth());
    };

    const tradeVisibleItem = () => {
        const newVisibleItem = !visibleItem;
        setVisibleDrawer(newVisibleItem);
        setVisibleItem(newVisibleItem);
    };

    const onNavigationMain = () => {
        setPathRoute('/dashboard');
        setRedirect(true);
    };

    const VerificationSubItemContents = (type: 'Contents' | 'GeneralSettings' | 'Administration') => {
        if (type === 'Contents') {
            const amountVerify = Contents.filter((v) => v.pathName === currentLocation).length > 0;
            return amountVerify;
        }
        if (type === 'GeneralSettings') {
            const amountVerify = GeneralSettings.filter(
                (v) => v.pathName === currentLocation,
            ).length > 0;
            return amountVerify;
        }
        if (type === 'Administration') {
            const amountVerify = Administration.filter(
                (v) => v.pathName === currentLocation,
            ).length > 0;
            return amountVerify;
        }

        return false;
    };

    // renders
    const RenderSubtitles = (type: 'Contents' | 'GeneralSettings' | 'Administration') => {
        if (type === 'Contents') {
            return Contents.map((v) => {
                const onNavigation = () => {
                    setPathRoute(v.pathName);
                    setRedirect(true);
                };

                return (
                    <Separator>
                        <Selector currentLocation={currentLocation === v.pathName} />
                        <Subtitle
                            onClick={onNavigation}
                            currentLocation={currentLocation === v.pathName}
                        >
                            {v.title}
                        </Subtitle>
                    </Separator>
                );
            });
        }
        if (type === 'GeneralSettings') {
            return GeneralSettings.map((v) => {
                const onNavigation = () => {
                    setPathRoute(v.pathName);
                    setRedirect(true);
                };

                return (
                    <Separator>
                        <Selector currentLocation={currentLocation === v.pathName} />
                        <Subtitle
                            onClick={onNavigation}
                            currentLocation={currentLocation === v.pathName}
                        >
                            {v.title}
                        </Subtitle>
                    </Separator>
                );
            });
        }
        if (type === 'Administration') {
            return Administration.map((v) => {
                const onNavigation = () => {
                    setPathRoute(v.pathName);
                    setRedirect(true);
                };

                return (
                    <Separator>
                        <Selector currentLocation={currentLocation === v.pathName} />
                        <Subtitle
                            onClick={onNavigation}
                            currentLocation={currentLocation === v.pathName}
                        >
                            {v.title}
                        </Subtitle>
                    </Separator>
                );
            });
        }
        return null;
    };

    return (
        <ContainerMenu>
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <Header visibleItem={visibleItem}>
                <HamburgerOpen onClick={tradeVisibleItem}>
                    <OpenIcon src={visibleItem ? goBackPinkLeft : drawer} />
                    {/* <OpenIcon visibleItem={visibleItem} />
                    <OpenIcon visibleItem={visibleItem} />
                    <OpenIcon visibleItem={visibleItem} /> */}
                </HamburgerOpen>
                <Home visibleItem={visibleItem}>
                    <Logo src={logoFullSize} />
                    <TxtHeader onClick={onNavigationMain} currentLocation={currentLocation === '/dashboard'}>Dashboard</TxtHeader>
                    <Title currentLocation={VerificationSubItemContents('Contents')}>Conteúdos</Title>
                    {RenderSubtitles('Contents')}
                    <Title currentLocation={VerificationSubItemContents('GeneralSettings')}>Configurações Gerais</Title>
                    {RenderSubtitles('GeneralSettings')}
                    <Title currentLocation={VerificationSubItemContents('Administration')}>Administração</Title>
                    {RenderSubtitles('Administration')}
                    <Logout onClick={onLogout}>Sair</Logout>
                </Home>
            </Header>
            <AdjustSpace />
        </ContainerMenu>
    );
};

export default MenuComponent;
