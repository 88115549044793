/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React from 'react';

// js
import {
    Option,
} from './editContentsStyles';

// methods
const RenderPickers = (
    type: string,
    allCategories?: any,
    allAuthors?: any,
): React.ReactElement | null => {
    if (type === 'format') {
        return (
            <>
                <Option value="text">Texto</Option>
                <Option value="audio">Áudio</Option>
                <Option value="video">Vídeo</Option>
            </>
        );
    }

    if (type === 'categories' && allCategories?.length) {
        return allCategories.map((v: any) => (
            <Option value={v.id}>{v.name}</Option>
        ));
    }

    if (type === 'authors' && allAuthors?.length) {
        return allAuthors.map((v: any) => (
            <Option value={v.name}>{v.name}</Option>
        ));
    }

    return null;
};

export default {
    RenderPickers,
};
