// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// styles - search
export const ContainerInput = styled.div`
    width: 500px;
    height: 45px;
    border: 1px solid ${colors.theme.blackLight};
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* media query */
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const Input = styled.input`
    color: ${colors.basic.gray};
    background-color: ${colors.theme.backgroundScreen};
    width: 430px;
    height: 40px;
    border: none;
    margin-left: 20px;
    /* media query */
    /* @media (max-width: 1024px) {
        height: 25px;
        width: 130px;
        font-size: 11px;
    } */
`;

export const Icon = styled.img`
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
`;

export const IconClosed = styled.img`
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer;
`;
