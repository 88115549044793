/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import consts from '../utils/consts';

// constants
const { baseUrl } = consts;

// api
async function CreateNotification(
    title: string,
    subtitle: string,
    isEasyPass: number,
    token: string,
): Promise<any> {
    try {
        const data = {
            title,
            subtitle,
            isEasyPass,
        };
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        await fetch(`${baseUrl}/notifications`, configs);
        alert('Notificação enviada com sucesso!');
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    CreateNotification,
};
