// js
import consts from '../utils/consts';

// interface
import { DataContentInterface } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;

interface ContentsInterfaces {
    status: number,
    message: string,
}

interface GetContentsInterfaces {
    status: number,
    result: Array<DataContentInterface>,
    message: string,
}

// api
async function CreateOneContent(
    cover: string,
    // textArea: string,
    title: string,
    author: string,
    narrator: string,
    price: number,
    description: string,
    type: 'text' | 'audio' | 'video',
    idCategory: string,
    free: boolean,
    highLight: boolean,
    visible: boolean,
    token: string,
): Promise<ContentsInterfaces> {
    try {
        const data = {
            cover,
            // media: textArea,
            title,
            author,
            narrator,
            price,
            description,
            type,
            idCategory,
            free,
            easyPass: !free,
            isHighlight: highLight,
            visible,
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/contents?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function EditOneContent(
    cover: string,
    title: string,
    author: string,
    narrator: string,
    price: number,
    description: string,
    idCategory: string,
    free: boolean,
    visible: boolean,
    highLight: boolean,
    idContent: string,
    token: string,
): Promise<ContentsInterfaces> {
    try {
        const data = {
            cover,
            title,
            author,
            narrator,
            price,
            description,
            idCategory,
            free,
            easyPass: !free,
            visible,
            isHighlight: highLight,
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/contents?admPass=${admPass}&idContent=${idContent}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function GetAllContents(
    format: string | 'audio' | 'video' | 'text',
    idCategory: string,
    author: string,
    idContent: string,
    token: string,
): Promise<GetContentsInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/contents?admPass=${admPass}&format=${format}&idCategory=${idCategory}&author=${author}&idContent=${idContent}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneContent(
    idContent: string,
    token: string,
): Promise<ContentsInterfaces> {
    try {
        const method = 'DELETE';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/contents?admPass=${admPass}&idContent=${idContent}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllContents,
    CreateOneContent,
    EditOneContent,
    DeleteOneContent,
};
