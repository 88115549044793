// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface HeaderInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
}

interface ContainerPhoto {
    background: string,
}

interface ButtonActionInterfaces {
    valueLength?: boolean,
    identifier?: string,
}

interface InputAreaInterfaces {
    height: string,
}

interface ContainerAllSwitchs {
    justifyContent?: string,
}

interface ContainerPhotoInterfaces {
    background: string,
}

interface ContainerButtonInterfaces {
    height?: string,
}

interface HeaderInputInterfaces {
    alignSelf?: string,
    paddingLeft?: string,
    paddingRight?: string,
}

// styles
export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const ContainerPointer = styled.div`
    cursor: pointer;
    min-width: 49%;
`;

export const ContainerButton = styled.div<ContainerButtonInterfaces>`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    transition: height 0.2s ease;
    margin-top: 10px;
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: ${(props) => (props.height || '230px')};
`;

export const ImageConfig = styled.div<ContainerPhotoInterfaces>`
    align-items: flex-end;
    display: flex;
    min-height: 230px;
    border-radius: 15px;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
`;

export const ContainerInputs = styled.div`
    width: 49%;
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 98%;
`;

export const ContainerModal = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const InputArea = styled.textarea<InputAreaInterfaces>`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    min-height: ${(props) => (props.height || '150px')};
    padding: 15px;
    width: 98%;
    margin-bottom: 20px;
    font-weight: bold;
    resize: none;
`;

export const InputDefault = styled.input`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
`;

export const HeaderInput = styled.text<HeaderInputInterfaces>`
    align-self: ${(props) => (props.alignSelf || 'flex-start')};
    color: ${colors.basic.gray};
    font-size: 14px;
    padding-left: ${(props) => (props.paddingLeft || '15px')};
    padding-right: ${(props) => (props.paddingRight || '0px')};
`;

export const ContainerAddArchive = styled.div<ContainerButtonInterfaces>`
    align-items: center;
    justify-content: center;
    background-color: ${colors.theme.blackMedium};
    display: flex;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: ${(props) => (props.height || '35px')};
    width: 100%;
`;

export const ButtonDeleteOrCancel = styled.button<ButtonActionInterfaces>`
    cursor: pointer;
    background-color: ${colors.basic.white};
    border-radius: 20px;
    border: 1px solid ${colors.theme.redLight};
    margin-bottom: 20px;
    height: 40px;
    transition-duration: 400ms;
    width: 45%;
`;

export const ContainerBox = styled.div`
    /* align-items: center; */
    background-color: ${colors.basic.white};
    /* display: flex; */
    /* flex-direction: column; */
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 80px;
    max-height: calc(100vh - 210px);
    width: 80%;
    max-width: 1000px;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const ContainerSwitch = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const ContainerAllSwitchs = styled.div<ContainerAllSwitchs>`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-bottom: 10px;
    @media (max-width: 1300px) {
        flex-wrap: wrap;
    }
    width: 100%;
`;

export const TextButtonDeleteOrCancel = styled.h4<ButtonActionInterfaces>`
    color: ${colors.theme.redLight};
    font-weight: normal;
    font-size: 18px;
`;

export const ButtonSave = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.theme.gray)};
    border-radius: 20px;
    border: none;
    margin-bottom: 20px;
    height: 40px;
    transition-duration: 400ms;
    width: 45%;
`;

export const TextButtonSave = styled.h4`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 18px;
`;

export const TextCancel = styled.text`
    color: ${colors.theme.pink};
    text-decoration: underline;
    display: flex;
    align-self: center;
    margin-top: 50px;
    cursor: pointer;
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerSelect = styled.select`
    background-color: ${colors.theme.backgroundScreen};
    border: 1px solid ${colors.basic.gray};
    border-radius: 20px;
    padding-left: 5px;
    margin-right: 10px;
    color: ${colors.basic.gray};
    height: 35px;
    width: 200px;
`;

export const CotainerDividerSelect = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const Option = styled.option``;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding-bottom: 60px;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerAuthors = styled.div`
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 1300px) {
        width: auto;
    }
`;

export const Header = styled.text<HeaderInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '14px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
`;

export const NameCategories = styled.text`
    color: ${colors.basic.white};
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    width: 90%;
`;

export const ContainerPhoto = styled.div<ContainerPhoto>`
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 16px;
    min-height: 202px;
    width: 246px;
`;

export const ContainerWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ContainerListEmpty = styled.div`
    align-items: center;
    flex-direction: column;
    display: flex;
    margin-top: 100px;
    width: 100%;
`;

export const ContainerSelectModal = styled.select`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
`;

export const ButtonCreateContent = styled.div`
    background-color: ${colors.theme.pink};
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    right: 50px;
    font-size: 32px;
    color: ${colors.basic.white};
    cursor: pointer;
    min-height: 70px;
    min-width: 70px;
    @media (max-width: 800px) {
        min-height: 60px;
        min-width: 60px;
    }
    @media (max-width: 600px) {
        min-height: 60px;
        min-width: 60px;
        bottom: 20px;
        right: 40px;
    }
    @media (max-width: 400px) {
        min-height: 50px;
        min-width: 50px;
        bottom: 20px;
        right: 20px;
    }
`;

export const ContainerAuthorsTwo = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 1300px) {
        width: auto;
        flex-wrap: wrap;
    }
`;

export const ContainerDividerScreen = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 30%;
    @media (max-width: 1300px) {
        min-height: 380px;
        width: 300px;
    }
`;

export const CheckBox = styled.input`
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
`;

export const ContainerCheckBox = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 30px;
`;

export const IconCloud = styled.img`
    object-fit: contain;
    margin-bottom: 20px;
    width: 100px;
`;

export const ContainerButtonPhoto = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    transition: height 0.2s ease;
    margin-top: 10px;
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100px;
    @media (max-width: 1300px) {
        width: 150px;
    }
`;

export const ContainerSwitchTwo = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 5px;
`;

export const ImageConfigTwo = styled.div<ContainerPhotoInterfaces>`
    align-items: flex-end;
    display: flex;
    min-height: 100px;
    border-radius: 15px;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
`;

export const ContainerAdjustButtonSave = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
`;
