/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';

// js
import colors from '../../../styles/colors';
import { RootState } from '../../../store';
import {
    Container,
    ContainerSwitch,
    ContainerCategories,
    Home,
    ContainerInner,
    ListCategories,
    Header,
    HeaderInput,
    InputDefault,
    ContainerAllSwitchs,
    ButtonDeleteOrCancel,
    ButtonSave,
    TextButtonDeleteOrCancel,
    TextButtonSave,
    TextCancel,
    ContainerPhoto,
    ContainerAllCategories,
    NameCategories,
    ContainerDividerBackground,
    IconCloud,
    TextBox,
    ContainerPointer,
    ImageCardAndPage,
    ContainerButton,
    HeaderSwitch,
    InputArea,
} from './categoriesContentsStyles';
import { cloudPink } from '../../../assets/index';
import functionsCategories from './CategoriesContentsFunctions';

// interfaces
import { DataCategoriesInterface, DataArchiveInterface } from '../../../interfaces';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

const CategoriesContentsPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);
    const tokenUser = infoUser?.token || '';

    // constants
    const initialArchive: DataArchiveInterface = {
        eventFile: new Blob(),
        eventName: '',
        file: '',
    };

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(false);
    const [identifier, setIdentifier] = useState('create');
    const [idCategoriesActual, setIdCategoriesActual] = useState('');
    const [allCategories, setAllCategories] = useState<Array<DataCategoriesInterface>>([]);
    const [photoPage, setPhotoPage] = useState<DataArchiveInterface | string>(initialArchive);
    const [photoCard, setPhotoCard] = useState<DataArchiveInterface | string>(initialArchive);

    // methods
    const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setName(txt.target.value);
    };

    const onChangeDescription = (txt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(txt.target.value);
    };

    const onChangeActive = () => {
        setActive(!active);
    };

    const ValidationDeleteOrCancel = () => {
        if (identifier === 'create') {
            if (name.length > 0 || description.length > 0) {
                return true;
            }
        }
        if (identifier === 'edit') {
            return true;
        }

        return false;
    };

    const ValidationSave = () => {
        const validationPage = typeof photoPage === 'object' ? photoPage?.file?.length > 0 : photoPage.length > 0;
        const validationCard = typeof photoCard === 'object' ? photoCard?.file?.length > 0 : photoCard.length > 0;
        const validationFormsCreate = identifier === 'create' && description.length > 0 && name.length > 0 && validationPage && validationCard;
        const validationFormsEdit = identifier === 'edit' && description.length > 0 && name.length > 0 && validationPage && validationCard;
        return validationFormsCreate || validationFormsEdit;
    };

    const GetCategories = async () => {
        await functionsCategories.GetAllCategories(
            setName,
            setDescription,
            setActive,
            setIdentifier,
            setIdCategoriesActual,
            setPhotoPage,
            setPhotoCard,
            setAllCategories,
            tokenUser,
        );
    };

    useEffect(() => {
        GetCategories();
    }, []);

    const DeleteCategories = async () => {
        await functionsCategories.DeleteCategories(
            setName,
            setDescription,
            setActive,
            setIdentifier,
            setIdCategoriesActual,
            setPhotoPage,
            setPhotoCard,
            setAllCategories,
            idCategoriesActual,
            tokenUser,
        );
    };

    const SaveCategories = async () => {
        await functionsCategories.SaveCategories(
            name,
            description,
            active,
            setName,
            setDescription,
            setActive,
            setIdentifier,
            setIdCategoriesActual,
            setPhotoPage,
            setPhotoCard,
            setAllCategories,
            identifier,
            photoCard,
            photoPage,
            idCategoriesActual,
            tokenUser,
        );
    };

    const RenderDragMessage = (type: string) => (
        <>
            <IconCloud src={cloudPink} />
            <TextBox>CLIQUE AQUI</TextBox>
            <TextBox>para inserir uma imagem</TextBox>
            <TextBox>{`de background para ${type === 'card' ? 'card' : 'página'}`}</TextBox>
        </>
    );

    function SelectedPhotoCard(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setPhotoCard(newDataFiles);
    }

    function SelectedPhotoPage(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setPhotoPage(newDataFiles);
    }

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <>
                            <ContainerCategories>
                                <Header>
                                    {'Conteúdos > '}
                                    <Header fontWeight="bold">Categorias</Header>
                                </Header>
                                <HeaderInput>Nome da categoria</HeaderInput>
                                <InputDefault
                                    value={name}
                                    type="email"
                                    onChange={onChangeName}
                                />
                                <HeaderInput>Descrição</HeaderInput>
                                <InputArea value={description} onChange={onChangeDescription} />
                                <ContainerAllSwitchs>
                                    <ContainerDividerBackground>
                                        <HeaderInput>Background Card</HeaderInput>
                                        <ContainerPointer>
                                            <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <ContainerButton {...getRootProps()}>
                                                        {typeof photoCard === 'object' ? (
                                                            <>
                                                                {photoCard?.file ? (
                                                                    <ImageCardAndPage
                                                                        src={photoCard?.file}
                                                                    />
                                                                ) : RenderDragMessage('card')}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {photoCard ? (
                                                                    <ImageCardAndPage
                                                                        src={String(photoCard)}
                                                                    />
                                                                ) : (
                                                                    RenderDragMessage('card')
                                                                )}
                                                            </>
                                                        )}
                                                        <input
                                                            {...getInputProps()}
                                                            onChange={SelectedPhotoCard}
                                                        />
                                                    </ContainerButton>
                                                )}
                                            </Dropzone>
                                        </ContainerPointer>
                                    </ContainerDividerBackground>
                                    <ContainerDividerBackground>
                                        <HeaderInput>Background Page</HeaderInput>
                                        <ContainerPointer>
                                            <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <ContainerButton {...getRootProps()}>
                                                        {typeof photoPage === 'object' ? (
                                                            <>
                                                                {photoPage?.file ? (
                                                                    <ImageCardAndPage
                                                                        src={photoPage?.file}
                                                                    />
                                                                ) : RenderDragMessage('page')}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {photoPage ? (
                                                                    <ImageCardAndPage
                                                                        src={String(photoPage)}
                                                                    />
                                                                ) : (
                                                                    RenderDragMessage('page')
                                                                )}
                                                            </>
                                                        )}
                                                        <input
                                                            {...getInputProps()}
                                                            onChange={SelectedPhotoPage}
                                                        />
                                                    </ContainerButton>
                                                )}
                                            </Dropzone>
                                        </ContainerPointer>
                                    </ContainerDividerBackground>
                                </ContainerAllSwitchs>
                                <ContainerSwitch>
                                    <HeaderSwitch>Ativar ou desativar cont.</HeaderSwitch>
                                    <Switch
                                        onChange={onChangeActive}
                                        checked={active}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onColor={colors.theme.green}
                                        offColor={colors.theme.gray}
                                    />
                                </ContainerSwitch>
                                {identifier === 'edit' ? (
                                    <TextCancel onClick={GetCategories}>Cancelar</TextCancel>
                                ) : null}
                                <ContainerAllSwitchs justifyContent="space-between">
                                    <ButtonDeleteOrCancel
                                        valueLength={ValidationDeleteOrCancel()}
                                        onClick={DeleteCategories}
                                        identifier={identifier}
                                        disabled={!ValidationDeleteOrCancel()}
                                    >
                                        <TextButtonDeleteOrCancel
                                            valueLength={ValidationDeleteOrCancel()}
                                        >
                                            {identifier === 'create' ? 'Cancelar' : 'Excluir'}
                                        </TextButtonDeleteOrCancel>
                                    </ButtonDeleteOrCancel>
                                    <ButtonSave
                                        valueLength={ValidationSave()}
                                        onClick={SaveCategories}
                                        identifier={identifier}
                                        disabled={!ValidationSave()}
                                    >
                                        <TextButtonSave>Salvar</TextButtonSave>
                                    </ButtonSave>
                                </ContainerAllSwitchs>
                            </ContainerCategories>
                            <ListCategories>
                                <HeaderInput>Relação de categorias</HeaderInput>
                                <ContainerAllCategories>
                                    {allCategories.map((v) => {
                                        const ActionEditCategories = () => {
                                            setName(v.name);
                                            setDescription(v.description);
                                            setPhotoCard(v.icon);
                                            setPhotoPage(v.cover);
                                            setActive(v.visible);
                                            setIdCategoriesActual(v.id);
                                            setIdentifier('edit');
                                        };

                                        return (
                                            <ContainerPhoto
                                                background={v.icon}
                                                onClick={ActionEditCategories}
                                            >
                                                <NameCategories>{v.name}</NameCategories>
                                                {/* <ContainerEmpty background={v.cover}>
                                                    <NameCategories>{v.name}</NameCategories>
                                                </ContainerEmpty> */}
                                            </ContainerPhoto>
                                        );
                                    })}
                                </ContainerAllCategories>
                            </ListCategories>
                        </>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default CategoriesContentsPage;
