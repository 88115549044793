/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// js
import colors from '../../../styles/colors';
import { RootState } from '../../../store';
import {
    Container,
    Home,
    ContainerAuthors,
    ContainerInner,
    Header,
    ContainerSelect,
    Option,
    CotainerDividerSelect,
    ContainerListEmpty,
    ContainerPhoto,
    NameCategories,
    ContainerWrap,
    ButtonCreateContent,
} from './editContentsStyles';
import authorsApi from '../../../services/authors';
import categoriesApi from '../../../services/categories';
import contents from '../../../services/contents';
import editContentsConstants from './editContentsConstants';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';
import LoadingComponent from '../../../components/Loading/LoadingComponent';

// interfaces
import {
    DataAuthorsInterface,
    DataCategoriesInterface,
    DataContentInterface,
} from '../../../interfaces';
import editContentsFunctions from './editContentsFunctions';
import ModalCreateOrEditContent from './components/ModalCreateOrEditContent';
import functions from '../../../utils/functions';

const EditContentsPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [allContents, setAllContents] = useState<Array<DataContentInterface>>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [idContentActual, setIdContentActual] = useState('');
    const [allAuthors, setAllAuthors] = useState<Array<DataAuthorsInterface>>([]);
    const [allCategories, setAllCategories] = useState<Array<DataCategoriesInterface>>([]);
    const [validationEmpty, setValidationEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState(editContentsConstants.initialInputs);
    const [inputsCreate, setInputsCreate] = useState(editContentsConstants.initialInputsCreate);
    const [typeModal, setTypeModal] = useState<'create' | 'edit'>('create');

    // methods
    const GetFuncContents = async () => {
        const GetContents = await contents.GetAllContents(inputs.format, inputs.categories, inputs.authors, '', infoUser?.token);
        setValidationEmpty(GetContents.result.length > 0);
        setAllContents(GetContents.result);
    };

    const onChangeFormat = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setInputs({
            ...inputs,
            format: txt.target.value,
        });
    };

    const onChangeCategories = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setInputs({
            ...inputs,
            categories: txt.target.value,
        });
    };

    const onChangeAuthors = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setInputs({
            ...inputs,
            authors: txt.target.value,
        });
    };

    const ToggleModal = () => {
        setIsOpen(!isOpen);
    };

    const GetInitialScreen = async () => {
        const GetAuthors = await authorsApi.GetAllAuthors(infoUser?.token);
        setAllAuthors(GetAuthors.result);
        const GetCategories = await categoriesApi.GetAllCategories(infoUser?.token);
        setAllCategories(GetCategories.result);
        GetFuncContents();
    };

    const OpenModalCreate = () => {
        setTypeModal('create');
        setIsOpen(true);
    };

    // useEffect
    useEffect(() => {
        GetInitialScreen();
    }, []);

    useEffect(() => {
        GetFuncContents();
    }, [inputs.format, inputs.authors, inputs.categories]);

    // renders
    const RenderContents = () => {
        if (allContents.length && validationEmpty) {
            return (
                <ContainerWrap>
                    {allContents.map((v) => {
                        const ActionEditCategories = () => {
                            ToggleModal();
                            setInputs({
                                ...inputs,
                                photo: v.cover,
                                title: v.title,
                                author: v.author,
                                interpret: v.narrator,
                                price: `R$ ${functions.toPrice(v.price, 2)}`,
                                resume: v.description,
                                activeContent: v.visible,
                                highLight: v.isHighlight,
                                contentFree: v.free,
                                categories: allCategories.find((c) => c.id === v.idCategory)?.id || '',
                            });
                            setTypeModal('edit');
                            setIdContentActual(v.id);
                        };

                        return (
                            <ContainerPhoto
                                background={v.cover}
                                onClick={ActionEditCategories}
                            >
                                <NameCategories>{v.title}</NameCategories>
                                {/* <ContainerEmpty background={v.cover}>
                                    <NameCategories>{v.name}</NameCategories>
                                </ContainerEmpty> */}
                            </ContainerPhoto>
                        );
                    })}
                </ContainerWrap>
            );
        }
        if (!allContents.length && !validationEmpty) {
            return (
                <ContainerListEmpty>
                    <Header fontSize="32px" fontWeight="bold" color={colors.basic.gray}>Olá!</Header>
                    <Header fontSize="32px" color={colors.basic.gray}>Defina uma opção a partir dos filtros</Header>
                    <Header fontSize="32px" color={colors.basic.gray}>acima para editar conteúdos.</Header>
                </ContainerListEmpty>
            );
        }
        return null;
    };

    return (
        <Container>
            <Home>
                <ContainerInner>
                    {!isOpen ? (
                        <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    ) : null}
                    {visibleDrawer ? null : (
                        <>
                            <ContainerAuthors>
                                <Header marginBottom="20px" color={colors.basic.gray}>
                                    {'Conteúdos > '}
                                    <Header fontWeight="bold" color={colors.basic.gray}>Conteúdos</Header>
                                </Header>
                                <CotainerDividerSelect>
                                    <ContainerSelect onChange={onChangeFormat}>
                                        <Option value="">Formatos</Option>
                                        {editContentsFunctions.RenderPickers('format')}
                                    </ContainerSelect>
                                    <ContainerSelect onChange={onChangeCategories}>
                                        <Option value="">Categorias</Option>
                                        {editContentsFunctions.RenderPickers('categories', allCategories)}
                                    </ContainerSelect>
                                    <ContainerSelect onChange={onChangeAuthors}>
                                        <Option value="">Autores</Option>
                                        {editContentsFunctions.RenderPickers('authors', null, allAuthors)}
                                    </ContainerSelect>
                                </CotainerDividerSelect>
                                <ButtonCreateContent
                                    onClick={OpenModalCreate}
                                >
                                    +
                                </ButtonCreateContent>
                                {RenderContents()}
                                {ModalCreateOrEditContent(
                                    isOpen,
                                    setIsOpen,
                                    inputs,
                                    setInputs,
                                    inputsCreate,
                                    setInputsCreate,
                                    allAuthors,
                                    allCategories,
                                    ToggleModal,
                                    idContentActual,
                                    setIdContentActual,
                                    infoUser,
                                    GetFuncContents,
                                    setLoading,
                                    setVisibleDrawer,
                                    typeModal,
                                )}
                            </ContainerAuthors>
                        </>
                    )}
                </ContainerInner>
            </Home>
            <LoadingComponent label="Carregando...." loading={loading} subtitle="Esta operação pode levar alguns minutos dependendo do tamanho do seu arquivo ou velocidade da sua internet..." />
        </Container>
    );
};

export default EditContentsPage;
