/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import consts from '../utils/consts';
import { DataUserInterface } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// interface UsersInterfaces {
//     status: number,
//     result: Array<{ active: boolean, easyPass: boolean, name: string, email: string, id: string }>;
//     message: string,
// }
interface AuthInterfaces {
    status: number,
    result: { user: DataUserInterface, token: string, tokenRefresh: string };
    message: string,
}

// api
async function GetAllUsers(
    searchName: string,
    typeUser: string,
    token: string,
): Promise<any> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const urlGetAllUsers = `${baseUrl}/users?admPass=${admPass}&searchName=${searchName || ''}&typeUser=${typeUser}`;
        const response = await fetch(urlGetAllUsers, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function CreateOneUser(
    isAdm: boolean,
    name: string,
    email: string,
    password: string,
    easyPass: boolean,
    active: boolean,
    idUserCategory: string,
): Promise<AuthInterfaces> {
    try {
        const data = {
            isAdm,
            name,
            email,
            password,
            easyPass,
            active,
            idUserCategory,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { user: {}, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

async function EditOneUser(
    name: string,
    email: string,
    easyPass: boolean,
    active: boolean,
    idUserCategory: string,
    idUser: string,
): Promise<{ status: number, message: string }> {
    try {
        const data = {
            name,
            email,
            easyPass,
            active,
            idUserCategory,
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users?admPass=${admPass}&idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneUser(
    idUser: string,
    token: string,
): Promise<{ status: number, message: string }> {
    try {
        const method = 'DELETE';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/users?admPass=${admPass}&idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllUsers,
    CreateOneUser,
    EditOneUser,
    DeleteOneUser,
};
