// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface HeaderInterfaces {
    fontWeight?: string,
}

interface ButtonActionInterfaces {
    valueLength: boolean,
    identifier?: string,
}

export const TextButtonSave = styled.h4`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 18px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const InputDefault = styled.input`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 60%;
        border-radius: 15px;
        font-size: 11px;
    } */
`;

export const ButtonSave = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.theme.gray)};
    border-radius: 15px;
    border: none;
    margin-bottom: 50px;
    height: 50px;
    transition-duration: 400ms;
    width: 20%;
    margin-top: 20px;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 100px;
        border-radius: 5px;
    } */
`;

export const Option = styled.option``;

export const ContainerSelect = styled.select`
    align-self: center;
    background-color: ${colors.theme.backgroundScreen};
    border: 1px solid ${colors.basic.gray};
    border-radius: 20px;
    margin-bottom: 20px;
    padding-left: 5px;
    margin-right: 10px;
    color: ${colors.basic.gray};
    height: 35px;
    width: 250px;
`;

export const HeaderSwitch = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    margin-right: 12px;
`;

export const ContainerAllSwitchs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const HeaderInput = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    padding-left: 15px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerFinancial = styled.div`
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 1300px) {
        width: auto;
    }
`;

export const Header = styled.text<HeaderInterfaces>`
    color: ${colors.basic.gray};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 14px;
    margin-bottom: 20px;
`;
