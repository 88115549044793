// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface HeaderInterfaces {
    fontWeight?: string,
}

interface HeaderListUserInterfaces {
    fontWeight?: string,
    flex?: number,
}

interface ButtonActionInterfaces {
    valueLength: boolean,
    identifier?: string,
}

// styles
export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    /* background-color: orange; */
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerInnerUsers = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ContainerUsers = styled.div`
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
`;

export const ButtonSave = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.theme.gray)};
    border-radius: 15px;
    border: none;
    margin-bottom: 50px;
    margin-top: ${(props) => (props.identifier === 'edit' ? '20px' : '50px')};
    height: 50px;
    transition-duration: 400ms;
    width: 40%;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 100px;
        border-radius: 5px;
    } */
`;

export const TextButtonSave = styled.h4`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 18px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const ButtonDeleteOrCancel = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${colors.theme.backgroundScreen};
    border-radius: 15px;
    border: 1px solid ${(props) => (props.valueLength ? colors.theme.redLight : colors.theme.gray)};
    margin-bottom: 50px;
    margin-top: ${(props) => (props.identifier === 'edit' ? '20px' : '50px')};
    height: 50px;
    transition-duration: 400ms;
    width: 40%;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 100px;
        border-radius: 5px;
    } */
`;

export const TextButtonDeleteOrCancel = styled.h4<ButtonActionInterfaces>`
    color: ${(props) => (props.valueLength ? colors.theme.redLight : colors.theme.gray)};
    font-weight: normal;
    font-size: 18px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const ContainerList = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: blue; */
    /* media query */
    /* @media (max-width: 1294px) {
        width: 200px;
    } */
`;

export const ListUser = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    /* width: 70%; */
    overflow-y: 'visible';
`;

export const EditIcon = styled.img`
    width: 21px;
    object-fit: contain;
    /* media query */
    /* @media (max-width: 1294px) {
        width: 16px;
    } */
`;

export const InputDefault = styled.input`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    /* media query */
    /* @media (max-width: 1294px) {
        height: 30px;
        width: 60%;
        border-radius: 15px;
        font-size: 11px;
    } */
`;

export const ContainerAllSwitchs = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
`;

export const Header = styled.text<HeaderInterfaces>`
    color: ${colors.basic.gray};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 14px;
    padding-bottom: 24px;
`;

export const HeaderListUser = styled.text<HeaderListUserInterfaces>`
    color: ${colors.basic.gray};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 12px;
    /* width: 200px; */
    /* background-color: blue; */
    /* text-align: left; */
    display: flex;
    flex: ${(props) => (props.flex || 1)};
    /* width: 100%; */
    /* margin-bottom: 10px;
    padding-right: 5px; */
    /* word-break: normal; */
    /* media query */
    @media (max-width: 1200px) {
        font-size: 12px;
    }

    @media (max-width: 1000px) {
        font-size: 10px;
    }
`;

export const TextCancel = styled.text`
    color: ${colors.theme.pink};
    text-decoration: underline;
    display: flex;
    align-self: center;
    margin-top: 50px;
    cursor: pointer;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const ButtonEdit = styled.button`
    background-color: ${colors.theme.backgroundScreen};
    cursor: pointer;
    flex: 0.2;
    display: flex;
    border: none;
`;

export const HeaderInput = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    padding-left: 15px;
    /* media query */
    /* @media (max-width: 1294px) {
        font-size: 11px;
    } */
`;

export const ContainerChips = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
`;
