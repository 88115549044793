// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces

interface HeaderInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
}

interface ContainerDividerInterfaces {
    alignItems?: string,
    flexDirection?: string,
    width?: string,
}

interface TextActionInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginRight?: string,
    cursor?: string,
}

interface ButtonActionInterfaces {
    border?: string,
    cursor?: string,
}

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const ContainerDivider = styled.div<ContainerDividerInterfaces>`
    align-items: ${(props) => (props.alignItems || 'center')};
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: ${(props) => (props.width || 'auto')};
`;

export const ButtonAction = styled.button<ButtonActionInterfaces>`
    display: flex;
    border-radius: 20px;
    cursor: pointer;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    height: 50px;
    transition-duration: 400ms;
    border: ${(props) => (props.border || `1px solid ${colors.basic.grayLight}`)};
    background-color: ${colors.basic.white};
    margin-right: 5px;
    margin-left: 5px;
    width: 70%;
    cursor: ${(props) => (props.cursor || 'default')};
`;

export const Option = styled.option``;

export const ContainerSelect = styled.select`
    align-self: center;
    background-color: ${colors.theme.backgroundScreen};
    border: 1px solid ${colors.basic.gray};
    border-radius: 20px;
    margin-bottom: 20px;
    padding-left: 5px;
    margin-right: 10px;
    color: ${colors.basic.gray};
    height: 35px;
    width: 250px;
`;

export const TextAction = styled.text<TextActionInterfaces>`
    color: ${(props) => (props.color || colors.basic.gray)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '13px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    cursor: ${(props) => (props.cursor || 'default')};
`;

export const ContainerDatePicker = styled.div`
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 230px;
`;

export const ContainerHeaderScreen = styled.div`
    align-items: center;
    flex-direction: column;
    display: flex;
    margin-top: 100px;
    margin-bottom: 20px;
    width: 100%;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerReports = styled.div`
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 1300px) {
        width: auto;
    }
`;

export const Header = styled.text<HeaderInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '14px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
`;
