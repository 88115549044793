// js
import colors from '../../../styles/colors';

const stylesXAxis = {
    text: {
        stroke: 'none',
        fill: colors.theme.grayIntense,
        fontWeight: 400,
        fontSize: 12,
    },
    line: { stroke: colors.theme.grayIntense },
    ticks: { stroke: colors.theme.grayIntense },
};

const marginXYPlot = { left: 60 };

export default {
    stylesXAxis,
    marginXYPlot,
};
