/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// js
import {
    Container,
    Home,
    ContainerAuthors,
    ContainerInner,
    ContainerDividerScreen,
    Header,
    ContainerSelect,
    Option,
    ButtonCreateContent,
    ContainerWrap,
    ContainerListEmpty,
    ContainerPhoto,
    NameCategories,
} from './mediasContentStyles';
import contents from '../../../services/contents';
import { RootState } from '../../../store';
import medias from '../../../services/medias';
import colors from '../../../styles/colors';
import mediasContentsConstants from './mediasContentsConstants';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';
import LoadingComponent from '../../../components/Loading/LoadingComponent';
import ModalCreateOrEditMedias from './components/ModalCreateOrEditMedias';

const MediasContentsPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    const [allContents, setAllContents] = useState<Array<any>>([]);
    const [allMedias, setAllMedias] = useState<Array<any>>([]);
    const [contentSelected, setContentSelected] = useState<any>(null);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [validationEmpty, setValidationEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [inputs, setInputs] = useState(mediasContentsConstants.initialInputs);
    const [idMediaActual, setIdMediaActual] = useState('');
    const [mode, setMode] = useState('post');

    // methods
    const ActionGetContents = async () => {
        const GetContents = await contents.GetAllContents('', '', '', '', infoUser?.token);
        if (GetContents?.status === 200) {
            setAllContents(GetContents?.result || []);
        }
    };

    const ActionGetMedias = async () => {
        const GetMedias = await medias.GetAllMedias(contentSelected?.id, infoUser?.token);
        if (GetMedias?.status === 200) {
            setValidationEmpty(GetMedias.result.length > 0);
            setAllMedias(GetMedias?.result || []);
        }
    };

    const onChangeFormat = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setContentSelected({ id: txt.target.value });
    };

    const ToggleModal = () => {
        if (allMedias.length === 0) {
            const inputsAddWithoutMedias = {
                title: contentSelected.title,
                description: contentSelected.description,
                cover: contentSelected.cover,
                media: mediasContentsConstants.initialInputs.media,
                idMedia: mediasContentsConstants.initialInputs.idMedia,
                text: mediasContentsConstants.initialInputs.text,
                type: mediasContentsConstants.initialInputs.type,
                orientationHorizontal: mediasContentsConstants.initialInputs.orientationHorizontal,
            };
            setInputs(inputsAddWithoutMedias);
        } else {
            const inputsAddWithMedias = {
                title: allMedias[0].title,
                description: allMedias[0].description,
                cover: allMedias[0].cover,
                media: mediasContentsConstants.initialInputs.media,
                idMedia: mediasContentsConstants.initialInputs.idMedia,
                text: mediasContentsConstants.initialInputs.text,
                type: mediasContentsConstants.initialInputs.type,
                orientationHorizontal: mediasContentsConstants.initialInputs.orientationHorizontal,
            };
            setInputs(inputsAddWithMedias);
        }

        setIsOpen(!isOpen);
    };

    // useEffect
    useEffect(() => {
        ActionGetContents();
    }, []);

    useEffect(() => {
        if (contentSelected) {
            ActionGetMedias();
        }
    }, [contentSelected]);

    // renders
    const RenderMedias = () => {
        if (allMedias.length > 0 && validationEmpty) {
            return (
                <ContainerWrap>
                    {allMedias.map((v) => {
                        const ActionEditCategories = () => {
                            ToggleModal();
                            setMode('put');
                            setInputs({
                                ...inputs,
                                description: v.description,
                                title: v.title,
                                cover: v.cover,
                                media: v.media,
                                idMedia: v.id,
                                text: v.text,
                                orientationHorizontal: v.orientation !== 'vertical',
                            });
                            setIdMediaActual(v.id);
                        };

                        return (
                            <ContainerPhoto
                                background={v.cover}
                                onClick={ActionEditCategories}
                            >
                                <NameCategories>{v.title}</NameCategories>
                            </ContainerPhoto>
                        );
                    })}
                </ContainerWrap>
            );
        }
        if (!allMedias.length && !validationEmpty) {
            return (
                <ContainerListEmpty>
                    <Header fontSize="32px" fontWeight="bold" color={colors.basic.gray}>Olá!</Header>
                    <Header fontSize="32px" color={colors.basic.gray}>Defina uma opção a partir dos conteúdos existentes</Header>
                    <Header fontSize="32px" color={colors.basic.gray}>acima para editar uma mídia.</Header>
                </ContainerListEmpty>
            );
        }
        return null;
    };

    return (
        <Container>
            <Home>
                <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                {visibleDrawer ? null : (
                    <ContainerInner>
                        <ContainerAuthors>
                            <ContainerDividerScreen>
                                <Header marginBottom="30px">
                                    {'Conteúdos > '}
                                    <Header fontWeight="bold">Mídias</Header>
                                </Header>
                                <ContainerSelect onChange={onChangeFormat}>
                                    <Option value="">Selecione um conteúdo</Option>
                                    {allContents?.map((v) => (
                                        <Option value={v?.id}>{v?.title}</Option>
                                    ))}
                                </ContainerSelect>
                            </ContainerDividerScreen>
                            {contentSelected?.id ? (
                                <ButtonCreateContent onClick={() => {
                                    ToggleModal();
                                    setMode('post');
                                }}
                                >
                                    +
                                </ButtonCreateContent>
                            ) : null}
                            {ModalCreateOrEditMedias(
                                isOpen,
                                setIsOpen,
                                inputs,
                                setInputs,
                                ToggleModal,
                                idMediaActual,
                                setIdMediaActual,
                                infoUser,
                                ActionGetMedias,
                                setLoading,
                                setVisibleDrawer,
                                (allContents || []).find((c) => c.id === contentSelected?.id),
                                mode,
                            )}
                        </ContainerAuthors>
                        {RenderMedias()}
                    </ContainerInner>
                )}
            </Home>
            <LoadingComponent label="Carregando...." loading={loading} subtitle="Esta operação pode levar alguns minutos dependendo do tamanho do seu arquivo ou velocidade da sua internet..." />
        </Container>
    );
};

export default MediasContentsPage;
