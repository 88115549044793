/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import Switch from 'react-switch';

// js
import colors from '../../../../styles/colors';
import {
    ContainerSwitchTwo,
    ButtonDeleteOrCancel,
    ButtonSave,
    ContainerAddArchive,
    ContainerAllSwitchs,
    ContainerAuthorsTwo,
    ContainerBox,
    ContainerButton,
    ContainerButtonPhoto,
    ContainerDividerScreen,
    ContainerModal,
    ContainerPointer,
    ContainerSelectModal,
    Header,
    HeaderInput,
    IconCloud,
    ImageConfigTwo,
    InputArea,
    InputDefault,
    Option,
    TextButtonDeleteOrCancel,
    TextButtonSave,
    TextCancel,
    Video,
    CheckButton,
} from '../mediasContentStyles';
import { cloudBlue, uploadFinish } from '../../../../assets';
import mediasContentsConstants, { InterfacesInitialInputs } from '../mediasContentsConstants';
import uploads from '../../../../services/uploads';
import medias from '../../../../services/medias';

const ModalCreateOrEditMedias = (
    isOpen: boolean,
    setIsOpen: (v: boolean) => void,
    inputs: InterfacesInitialInputs,
    setInputs: (v: InterfacesInitialInputs) => void,
    ToggleModal: any,
    idMediaActual: string,
    setIdMediaActual: (v: string) => void,
    infoUser: any,
    ActionGetMedias: any,
    setLoading: (v: boolean) => void,
    setVisibleDrawer: (v: boolean) => void,
    contentSelected: any,
    mode: string,
): React.ReactElement => {
    // constants
    const modalStyles = {
        overlay: {
            backgroundColor: colors.theme.blackMedium,
        },
        content: {
            alignItems: 'center',
            backgroundColor: colors.theme.blackMedium,
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
        },
    };

    // methods
    const ValidationAccept = () => {
        if (contentSelected?.type === 'audio') {
            return ['audio/mp3'];
        }
        return ['video/mp4'];
    };

    const ValidationFormatsArchive = () => {
        if (contentSelected?.type === 'audio') {
            return 'audio/mp3';
        }
        return 'video/mp4';
    };

    function SelectedArchive(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setInputs({
            ...inputs,
            text: '',
            media: newDataFiles,
        });
    }

    function SelectedPhoto(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setInputs({ ...inputs, cover: newDataFiles });
    }

    const ValidationSave = () => {
        const validationCover = typeof inputs?.cover === 'string' ? inputs?.cover?.length > 0 : inputs?.cover?.file?.length > 0;
        const validationMedia = typeof inputs?.media === 'string' ? inputs?.media?.length > 0 : inputs?.media?.file?.length > 0;
        const validationOptions = validationMedia || inputs?.text?.length > 0;
        const validationFormsCreate = inputs?.title?.length > 0
        && inputs?.description?.length > 0
        && validationCover
        && validationOptions;
        return validationFormsCreate;
    };

    const DeleteOneMedias = async () => {
        await medias.DeleteOneMedia(inputs?.idMedia, infoUser.token);
        ActionGetMedias();
        setIsOpen(false);
    };

    const SaveAuthors = async () => {
        if (idMediaActual && mode === 'put') {
            setLoading(true);
            const uploadCover = typeof inputs?.cover !== 'string' && inputs?.cover?.file?.length > 0 ? await uploads.UploadFile(inputs?.cover?.eventFile) : inputs?.cover;
            const uploadMedia = typeof inputs?.media !== 'string' && inputs?.media?.file?.length > 0 ? await uploads.UploadFile(inputs?.media?.eventFile) : inputs?.media;
            const orientation = inputs.orientationHorizontal ? 'horizontal' : 'vertical';
            const ActionEditMedias = await medias.EditOneMedia(
                typeof uploadCover === 'string' ? uploadCover : '',
                contentSelected?.type === 'text' ? inputs?.text : (typeof uploadMedia === 'string' ? uploadMedia : ''),
                inputs?.title,
                inputs?.description,
                orientation,
                contentSelected?.id,
                inputs?.idMedia,
                infoUser.token,
            );
            if (ActionEditMedias.status === 200) {
                ActionGetMedias();
                setIsOpen(false);
                setVisibleDrawer(false);
                setIdMediaActual('');
                setInputs(mediasContentsConstants.initialInputs);
            }
            // eslint-disable-next-line no-alert
            alert(ActionEditMedias?.message);
            setLoading(false);
        }
        if (!idMediaActual && mode === 'post') {
            setLoading(true);
            const uploadCover = typeof inputs?.cover !== 'string' && inputs?.cover?.file?.length > 0 ? await uploads.UploadFile(inputs?.cover?.eventFile) : (typeof inputs?.cover !== 'string' ? '' : inputs?.cover);
            const uploadMedia = typeof inputs?.media !== 'string' && inputs?.media?.file?.length > 0 && contentSelected?.type !== 'text' ? await uploads.UploadFile(inputs?.media?.eventFile) : (typeof inputs?.media !== 'string' ? '' : inputs?.media);
            const orientation = inputs.orientationHorizontal ? 'horizontal' : 'vertical';
            const ActionCreateMedias = await medias.CreateOneMedia(
                uploadCover,
                uploadMedia || inputs?.text,
                inputs?.title,
                inputs?.description,
                orientation,
                contentSelected?.id,
                infoUser.token,
            );
            if (ActionCreateMedias.status === 201) {
                ActionGetMedias();
                setVisibleDrawer(false);
                setIsOpen(false);
                setInputs(mediasContentsConstants.initialInputs);
            }
            // eslint-disable-next-line no-alert
            alert(ActionCreateMedias?.message);
            setLoading(false);
        }
    };

    // renders
    const RenderInputOrSelect = (type: 'input' | 'select' | 'inputArea' | 'switch' | 'checkbox', value: any, key: string, label: string, RenderItems?: any, selectDefault?: any) => {
        if (type === 'input' || type === 'inputArea') {
            const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
                setInputs({ ...inputs, [key]: txt.target.value });
            };
            const onChangeArea = (txt: React.ChangeEvent<HTMLTextAreaElement>) => {
                setInputs({ ...inputs, [key]: txt.target.value });
            };
            if (type === 'inputArea') {
                return (
                    <>
                        <HeaderInput>{label}</HeaderInput>
                        <InputArea
                            value={value}
                            onChange={onChangeArea}
                            height={!label || label === 'Resumo' ? '300px' : '150px'}
                        />
                    </>
                );
            }
            return (
                <>
                    <HeaderInput>{label}</HeaderInput>
                    <InputDefault
                        value={value}
                        type="email"
                        onChange={onChange}
                    />
                </>
            );
        }
        if (type === 'select') {
            const onChangeSelect = (txt: React.ChangeEvent<HTMLSelectElement>) => {
                setInputs({ ...inputs, [key]: txt.target.value });
            };

            return (
                <>
                    <HeaderInput>{label}</HeaderInput>
                    <ContainerSelectModal
                        onChange={onChangeSelect}
                        value={value}
                    >
                        <Option value="">{selectDefault}</Option>
                        {RenderItems}
                    </ContainerSelectModal>
                </>
            );
        }
        if (type === 'switch') {
            const onChangeSwitch = () => {
                setInputs({ ...inputs, [key]: !inputs.orientationHorizontal });
            };
            return (
                <>
                    <HeaderInput alignSelf="center" paddingRight="10px" paddingLeft="0px">{label}</HeaderInput>
                    <Switch
                        onChange={onChangeSwitch}
                        checked={value}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={colors.theme.green}
                        offColor={colors.theme.gray}
                    />
                </>
            );
        }

        return null;
    };

    return (
        <Modal isOpen={isOpen} style={modalStyles}>
            <ContainerModal>
                <ContainerBox>
                    <ContainerAuthorsTwo>
                        <ContainerDividerScreen>
                            <Header>Arquivo</Header>
                            <ContainerPointer>
                                {contentSelected?.type === 'text'
                                    ? (
                                        RenderInputOrSelect('inputArea', inputs?.text, 'text', '')
                                    )
                                    : (
                                        <Dropzone accept={ValidationAccept()}>
                                            {({ getRootProps, getInputProps }) => (
                                                <ContainerButton
                                                    file={typeof inputs?.media === 'string' ? inputs?.media : inputs?.media?.file}
                                                    {...getRootProps()}
                                                >
                                                    {(typeof inputs?.media === 'string' && inputs?.media?.length > 0) || (typeof inputs?.media !== 'string' && inputs?.media?.file?.length > 0) ? (
                                                        <>
                                                            <Video controls>
                                                                <source src={typeof inputs?.media === 'string' ? inputs?.media : inputs?.media?.file} type="video/mp4" />
                                                            </Video>
                                                            <Header fontWeight="bold">
                                                                <CheckButton src={uploadFinish} />
                                                                Upload feito com sucesso!
                                                            </Header>
                                                            <Header fontWeight="bold" marginBottom="30px">
                                                                {typeof inputs?.media === 'string' ? `${inputs?.media?.length > 20 ? `${inputs?.media?.substring(0, 20)}...` : `${inputs?.media?.substring(0, 20)}`}` : inputs?.media?.eventName}
                                                            </Header>
                                                            <Header fontSize="11px" marginBottom="50px" textDecoration="underline">CLIQUE AQUI PARA TROCAR O ARQUIVO</Header>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconCloud src={cloudBlue} />
                                                            <Header fontWeight="bold">Arraste seu arquivo</Header>
                                                            <Header fontWeight="bold" marginBottom="10px">para cá ou clique no link abaixo</Header>
                                                            <Header fontSize="11px" marginBottom="50px" textDecoration="underline">BUSCAR ARQUIVO</Header>
                                                            <Header fontSize="9px">Formatos suportados:</Header>
                                                            <Header fontSize="9px">{ValidationFormatsArchive()}</Header>
                                                        </>
                                                    )}
                                                    <input
                                                        {...getInputProps()}
                                                        onChange={SelectedArchive}
                                                    />
                                                </ContainerButton>
                                            )}
                                        </Dropzone>
                                    )}
                            </ContainerPointer>
                        </ContainerDividerScreen>
                        <ContainerDividerScreen>
                            {RenderInputOrSelect('input', inputs?.title, 'title', 'Título')}
                            {RenderInputOrSelect('input', inputs?.description, 'description', 'Descrição')}
                            <Header>Imagem de capa</Header>
                            <ContainerPointer>
                                <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                    {({ getRootProps, getInputProps }) => (
                                        <ContainerButtonPhoto {...getRootProps()}>
                                            {(typeof inputs?.cover === 'string' && inputs?.cover?.length > 0) || (typeof inputs?.cover !== 'string' && inputs?.cover?.file?.length > 0) ? (
                                                <ImageConfigTwo background={typeof inputs?.cover === 'string' ? inputs?.cover : inputs?.cover?.file}>
                                                    <ContainerAddArchive>
                                                        <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                    </ContainerAddArchive>
                                                </ImageConfigTwo>
                                            ) : null}
                                            <input
                                                {...getInputProps()}
                                                onChange={SelectedPhoto}
                                            />
                                        </ContainerButtonPhoto>
                                    )}
                                </Dropzone>
                            </ContainerPointer>
                            {contentSelected?.type === 'text' ? null : (
                                <ContainerSwitchTwo>
                                    {RenderInputOrSelect('switch', inputs.orientationHorizontal, 'orientationHorizontal', 'Orientação horizontal')}
                                </ContainerSwitchTwo>
                            )}
                        </ContainerDividerScreen>
                    </ContainerAuthorsTwo>
                    <ContainerAllSwitchs justifyContent="center">
                        <TextCancel onClick={ToggleModal}>
                            Cancelar
                        </TextCancel>
                    </ContainerAllSwitchs>
                    <ContainerAllSwitchs justifyContent="space-between">
                        <ButtonDeleteOrCancel onClick={DeleteOneMedias}>
                            <TextButtonDeleteOrCancel>
                                Excluir
                            </TextButtonDeleteOrCancel>
                        </ButtonDeleteOrCancel>
                        <ButtonSave
                            valueLength={ValidationSave()}
                            onClick={SaveAuthors}
                            disabled={!ValidationSave()}
                        >
                            <TextButtonSave>Salvar</TextButtonSave>
                        </ButtonSave>
                    </ContainerAllSwitchs>
                </ContainerBox>
            </ContainerModal>
        </Modal>
    );
};

export default ModalCreateOrEditMedias;
