// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface HeaderInterfaces {
    fontWeight?: string,
}

interface ContainerCharsDividerInterface {
    width?: string
}

interface TitleFormatInterface {
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
}

interface TextActionInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginRight?: string,
    cursor?: string,
}

interface ButtonActionInterfaces {
    backgroundColor?: string,
    border?: string,
    padding?: string,
}

interface ContainerDividerInterfaces {
    alignItems?: string,
    flexDirection?: string,
    width?: string,
}

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const ViewSelects = styled.div`
    align-items: center;
    display: flex;
    background-color: ${colors.basic.whiteFrosted};
    border-radius: 10px;
    justify-content: space-between;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0px 8px 0px 8px;
`;

export const ContainerSelect = styled.select`
    background-color: ${colors.theme.backgroundScreen};
    border: 1px solid ${colors.basic.gray};
    border-radius: 20px;
    padding-left: 5px;
    margin-right: 10px;
    color: ${colors.basic.gray};
    height: 35px;
    width: 250px;
`;

export const Option = styled.option``;

export const TitleFormat = styled.text<TitleFormatInterface>`
    color: ${colors.theme.grayIntense};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
`;

export const ContainerDivider = styled.div<ContainerDividerInterfaces>`
    align-items: ${(props) => (props.alignItems || 'center')};
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
    justify-content: space-between;
    flex-wrap: wrap;
    width: ${(props) => (props.width || 'auto')};
`;

export const ButtonAction = styled.div<ButtonActionInterfaces>`
    display: flex;
    border-radius: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: ${(props) => (props.border || `1px solid ${colors.basic.grayLight}`)};
    background-color: ${(props) => (props.backgroundColor || colors.basic.white)};;
    margin-right: 5px;
    margin-left: 5px;
    padding: ${(props) => (props.padding || '5px 10px 5px 10px')};
`;

export const TextAction = styled.text<TextActionInterfaces>`
    color: ${(props) => (props.color || colors.basic.gray)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '13px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    cursor: ${(props) => (props.cursor || 'default')};
`;

export const ContainerChartsDivider = styled.div<ContainerCharsDividerInterface>`
    background-color: ${colors.theme.backgroundScreen};
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    padding-bottom: 30px;
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerFinancial = styled.div`
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 1300px) {
        width: auto;
    }
`;

export const Header = styled.text<HeaderInterfaces>`
    color: ${colors.basic.gray};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 14px;
    margin-bottom: 20px;
`;

export const ContainerCharts = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`;
