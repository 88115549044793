// libraries
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// js
import {
    Home,
    Logo,
    TextActionNavigation,
    TextLinkingNavigation,
} from './loginStyles';
import { logoFullSize } from '../../assets/index';
import auth from '../../services/auth';
import { updateUser } from '../../store/modules/user/actions';

// components
import InputAuth from '../../components/Auth/Input';
import ButtonAuth from '../../components/Auth/Button';
import ToNavigation from '../../routes/navigation';

const LoginPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmail(valueFormat);
    };

    const onChangePassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setPassword(valueFormat);
    };

    const onNavigationForgotPassword = () => {
        setPathRoute('/forgotPassword');
        setRedirect(true);
    };

    const Signin = async () => {
        const getDataUser = await auth.Login(email, password);
        if (getDataUser.status === 200) {
            const formatInfoUser = {
                ...getDataUser.result.user,
                token: getDataUser.result.token,
                tokenRefresh: getDataUser.result.tokenRefresh,
            };
            dispatch(updateUser(formatInfoUser));
            setPathRoute('/dashboard');
            setRedirect(true);
        } else {
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert(getDataUser?.message || 'Erro ao logar');
        }
    };

    return (
        <Home>
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <Logo src={logoFullSize} />
            <InputAuth placeholder="Email" value={email} type="email" onChange={onChangeEmail} activeInput={email.length > 0} />
            <InputAuth placeholder="Senha" value={password} type="password" onChange={onChangePassword} activeInput={password.length > 0} />
            <ButtonAuth onClick={Signin} activeButton={email.length > 0 && password.length > 0} label="Entrar" />
            <TextActionNavigation>
                Esqueceu sua senha?
                <TextLinkingNavigation
                    onClick={onNavigationForgotPassword}
                >
                    Toque aqui!
                </TextLinkingNavigation>
            </TextActionNavigation>
        </Home>
    );
};

export default LoginPage;
