// js
import colors from '../../../styles/colors';

const chartNewUsers = {
    fill: colors.theme.pinkUltraLight,
    stroke: colors.theme.pinkUltra,
    result: [
        {
            x: 0,
            y: 0,
        },
        {
            x: 1,
            y: 0,
        },
        {
            x: 2,
            y: 0,
        },
        {
            x: 3,
            y: 0,
        },
        {
            x: 4,
            y: 0,
        },
        {
            x: 5,
            y: 0,
        },
        {
            x: 6,
            y: 9,
        },
        {
            x: 7,
            y: 17,
        },
        {
            x: 8,
            y: 1,
        },
        {
            x: 9,
            y: 0,
        },
        {
            x: 10,
            y: 0,
        },
        {
            x: 11,
            y: 0,
        },
    ],
};

const chartStreaming = {
    fill: colors.theme.backgroundChartsStreaming,
    stroke: colors.theme.strokeChartsStreaming,
    result: [
        {
            x: 0,
            y: 0,
        },
        {
            x: 1,
            y: 0,
        },
        {
            x: 2,
            y: 0,
        },
        {
            x: 3,
            y: 0,
        },
        {
            x: 4,
            y: 0,
        },
        {
            x: 5,
            y: 0,
        },
        {
            x: 6,
            y: 9,
        },
        {
            x: 7,
            y: 17,
        },
        {
            x: 8,
            y: 1,
        },
        {
            x: 9,
            y: 0,
        },
        {
            x: 10,
            y: 0,
        },
        {
            x: 11,
            y: 0,
        },
    ],
};

const chartPerformance = {
    fill: colors.theme.backgroundChartsPerformance,
    stroke: colors.theme.strokeChartsPerformance,
    result: [
        {
            x: 0,
            y: 0,
        },
        {
            x: 1,
            y: 0,
        },
        {
            x: 2,
            y: 0,
        },
        {
            x: 3,
            y: 0,
        },
        {
            x: 4,
            y: 0,
        },
        {
            x: 5,
            y: 0,
        },
        {
            x: 6,
            y: 9,
        },
        {
            x: 7,
            y: 17,
        },
        {
            x: 8,
            y: 1,
        },
        {
            x: 9,
            y: 0,
        },
        {
            x: 10,
            y: 0,
        },
        {
            x: 11,
            y: 0,
        },
    ],
};

const chartOne = {
    fill: colors.theme.backgroundChartsExclusion,
    stroke: colors.theme.strokeChartsExclusion,
    result: [
        {
            x: 0,
            y: 10,
        },
        {
            x: 1,
            y: 12,
        },
        {
            x: 2,
            y: 4,
        },
        {
            x: 3,
            y: 5,
        },
        {
            x: 4,
            y: 9,
        },
        {
            x: 5,
            y: 15,
        },
        {
            x: 6,
            y: 17,
        },
        {
            x: 7,
            y: 18,
        },
        {
            x: 8,
            y: 9,
        },
        {
            x: 9,
            y: 3,
        },
        {
            x: 10,
            y: 2,
        },
        {
            x: 11,
            y: 6,
        },
    ],
};

const chartTwo = {
    fill: colors.theme.backgroundChartsDownloads,
    stroke: colors.theme.strokeChartsDownloads,
    result: [
        {
            x: 0,
            y: 1,
        },
        {
            x: 1,
            y: 2,
        },
        {
            x: 2,
            y: 3,
        },
        {
            x: 3,
            y: 4,
        },
        {
            x: 4,
            y: 5,
        },
        {
            x: 5,
            y: 6,
        },
        {
            x: 6,
            y: 7,
        },
        {
            x: 7,
            y: 8,
        },
        {
            x: 8,
            y: 9,
        },
        {
            x: 9,
            y: 10,
        },
        {
            x: 10,
            y: 11,
        },
        {
            x: 11,
            y: 12,
        },
    ],
};

const allCharts = [
    {
        id: 1,
        title: 'Novos usuários',
        dataChart: [chartNewUsers],
    },
    {
        id: 2,
        title: 'Streaming reproduzidos',
        dataChart: [chartStreaming],
    },
    {
        id: 3,
        title: 'Download x Exclusão do aplicativo',
        dataChart: [chartOne, chartTwo],
    },
    {
        id: 4,
        title: 'Desempenho dos autores',
        dataChart: [chartPerformance],
    },
];

const stylesXAxis = {
    text: {
        stroke: 'none',
        fill: colors.theme.grayIntense,
        fontWeight: 400,
        fontSize: 12,
    },
    line: { stroke: colors.theme.grayIntense },
    ticks: { stroke: colors.theme.grayIntense },
};

export default {
    chartNewUsers,
    allCharts,
    stylesXAxis,
};
