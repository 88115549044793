export const Contents = [
    {
        title: 'Conteúdos',
        pathName: '/editContents',
    },
    {
        title: 'Mídias',
        pathName: '/mediasContents',
    },
    {
        title: 'Categorias',
        pathName: '/categoriesContents',
    },
    {
        title: 'Autores',
        pathName: '/authorsContents',
    },
];

export const GeneralSettings = [
    {
        title: 'Usuários',
        pathName: '/usersSettings',
    },
    {
        title: 'Categoria para usuários',
        pathName: '/usersCategorySettings',
    },
    {
        title: 'Aparência',
        pathName: '/appearanceSettings',
    },
];

export const Administration = [
    {
        title: 'Enviar Notificação',
        pathName: '/notification',
    },
    {
        title: 'Dash Operacional',
        pathName: '/operationAdministration',
    },
    // {
    //     title: 'Dash Financeiro',
    //     pathName: '/financialAdministration',
    // },
    {
        title: 'Usuários Admin',
        pathName: '/usersMasterAdministration',
    },
    {
        title: 'Relatórios',
        pathName: '/reportsAdministration',
    },
    {
        title: 'Avaliação',
        pathName: '/evaluationAdministration',
    },
];
