/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Switch, Route, useHistory, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

// pages
import AppearancePage from '../pages/GeneralSettings/Appearance/AppearancePage';
import UsersCategory from '../pages/GeneralSettings/UsersCategory/UsersCategory';
import LoginPage from '../pages/Login/LoginPage';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import HomePage from '../pages/Home/HomePage';
import EditContentsPage from '../pages/Contents/EditContents/EditContentsPage';
import MediasContentsPage from '../pages/Contents/MediasContents/MediasContentsPage';
import UsersPage from '../pages/GeneralSettings/UsersSettings/UsersPage';
import CategoriesContentsPage from '../pages/Contents/CategoriesContents/CategoriesContentsPage';
import AuthorsContentsPage from '../pages/Contents/AuthorsContents/AuthorsContentsPage';
import DashFinancialPage from '../pages/Administration/DashFinancial/DashFinancialPage';
import DashOperationalPage from '../pages/Administration/DashOperation/DashOperationPage';
import ReportPage from '../pages/Administration/Report/ReportPage';
import EvaluationPage from '../pages/Administration/Evaluation/EvaluationPage';
import NotificationPage from '../pages/Administration/Notification/NotificationPage';

// js
import { RootState } from '../store';

const Routes: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const history = useHistory();

    // conditions
    if (!window.location.pathname.includes('/forgotPassword') && !infoUser) {
        history.push('/');
    }

    // rota para redirecionar o usuário quando digitar uma rota não encontrada.
    const pathNotFound = infoUser ? '/dashboard' : '/';
    const RouteNotFound = infoUser ? HomePage : LoginPage;

    return (
        <Switch>
            <Route path="/" exact component={LoginPage} />
            <Route path={['/forgotPassword/:email', '/forgotPassword']} component={ForgotPassword} />
            <Route path="/dashboard" component={HomePage} />
            <Route path="/editContents" component={EditContentsPage} />
            <Route path="/mediasContents" component={MediasContentsPage} />
            <Route path={['/usersSettings', '/usersMasterAdministration']} component={UsersPage} />
            <Route path={pathNotFound} component={RouteNotFound} />
            <Route path="/categoriesContents" component={CategoriesContentsPage} />
            <Route path="/authorsContents" component={AuthorsContentsPage} />
            <Route path="/appearanceSettings" component={AppearancePage} />
            <Route path="/usersCategorySettings" component={UsersCategory} />
            <Route path="/financialAdministration" component={DashFinancialPage} />
            <Route path="/operationAdministration" component={DashOperationalPage} />
            <Route path="/notification" component={NotificationPage} />
            <Route path="/reportsAdministration" component={ReportPage} />
            <Route path="/evaluationAdministration" component={EvaluationPage} />
            <Redirect to={pathNotFound} />
        </Switch>
    );
};

export default Routes;
