// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface MenuInterface {
    currentLocation?: boolean,
    visibleItem?: boolean,
}

// styles - menu
export const Header = styled.div<MenuInterface>`
    align-items: center;
    background-color: ${colors.theme.backgroundScreen};
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1);
    justify-items: center;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    width: 250px;
    margin-top: 30px;
    /* media query */
    @media (max-width: 1300px) {
        box-shadow: ${(props) => (props.visibleItem ? '0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1)' : '0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0)')};
        // FIXME descomentar para deixar o drawer do lado direito
        /* border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px; */
    }
`;

export const ContainerMenu = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
    // FIXME descomentar para deixar o drawer do lado direito
    /* media query */
    /* @media (max-width: 1300px) {
        align-items: flex-end;
        justify-content: flex-end;
    } */
`;

export const AdjustSpace = styled.div`
    height: 30px;
`;

export const Home = styled.div<MenuInterface>`
    display: inline;
    padding: 20px;
    width: 200px;
    /* media query */
    @media (max-width: 1300px) {
        display: ${(props) => (props.visibleItem ? 'inline' : 'none')};
    }
`;

export const Title = styled.h4<MenuInterface>`
    color: ${(props) => (props.currentLocation ? colors.theme.pinkUltra : colors.theme.grayIntense)};
    font-weight: bold;
    margin-top: 20px;
`;

export const Logout = styled.h4`
    color: ${colors.theme.grayIntense};
    cursor: pointer;
    font-weight: bold;
    margin-top: 50px;
    border-radius: 20px;
    :hover {
        color: ${colors.theme.pinkUltra};
    }
`;

export const Selector = styled.div<MenuInterface>`
    background-color: ${(props) => (props.currentLocation ? colors.theme.pinkLight : colors.basic.white)};
    border-radius: 4px;
    border: 1px solid ${(props) => (props.currentLocation ? colors.theme.pinkLight : colors.theme.grayIntense)};
    height: 8px;
    width: 8px;
`;

export const Separator = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

export const HamburgerOpen = styled.div<MenuInterface>`
    display: none;
    z-index: 4;
    cursor: pointer;

    /* media query */
    @media (max-width: 1300px) {
        align-self: flex-start;
        display: flex;
        margin-top: 15px;
        margin-left: 15px;
        // FIXME descomentar para deixar o drawer do lado direito
        /* margin-left: 200px; */
        flex-flow: column nowrap;
    }
`;

export const OpenIcon = styled.img`
    width: 25px;
    height: 25px;
    object-fit: contain;
`;

export const Subtitle = styled.a<MenuInterface>`
    font-weight: 400;
    margin-left: 5px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => (props.currentLocation ? colors.theme.pinkLight : colors.theme.grayIntense)};
    font-weight: ${(props) => (props.currentLocation ? 'bold' : '400')};
    :hover {
        color: ${colors.theme.pinkLight};
    }
`;

export const Logo = styled.img`
    height: 150px;
    width: 150px;
`;

export const TxtHeader = styled.div<MenuInterface>`
    color: ${(props) => (props.currentLocation ? colors.theme.pinkUltra : colors.theme.grayIntense)};
    text-decoration: none;
    font-weight: bold;
`;
