const isLocal = window.location.hostname.includes('localhost');
const isDevelopment = window.location.hostname.includes('development');
const isStaging = window.location.hostname.includes('staging');

function getBaseUrl(): string {
    if (isDevelopment || isLocal) {
        return 'https://api.easyliving.com.br';
    }
    if (isStaging) {
        return 'https://staging.easylivingapi.dotcoding.com.br';
    }
    return 'https://api.easyliving.com.br';
}

const baseUrl = getBaseUrl();

const admPass = '245BVECRP64352RUOMAKsVcCUZu0bmXkrxmLoqGZfmBui4fIYTxb88JLEcxUtp0';

const fontFamily = '"Nunito"';

const fontSize = {
    default: 22,
};

const getFontWeight = (family: 'Nunito-Thin' | 'Nunito-Light' | 'Nunito-Regular' | 'Nunito-Bold' | 'Nunito-Black'): number => {
    if (family === 'Nunito-Thin') {
        return 100;
    }
    if (family === 'Nunito-Light') {
        return 300;
    }
    if (family === 'Nunito-Regular') {
        return 400;
    }
    if (family === 'Nunito-Bold') {
        return 700;
    }
    if (family === 'Nunito-Black') {
        return 900;
    }
    return 0;
};

const modalStyles = {
    overlay: {
        backgroundColor: 'transparent',
    },
    content: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
    },
};

export default {
    admPass,
    baseUrl,
    fontFamily,
    fontSize,
    getFontWeight,
    modalStyles,
};
