const moneyMask = (value: string): string => {
    const valueFormat = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(valueFormat) / 100,
    );

    return `R$ ${result}`;
};

const toPrice = (price: number, scale?: number | 2): string => (
    String(((price * 100) / 100).toFixed(scale)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
);

const RenderTickFormatXMonth = (v: number): string => {
    if (v === 0) {
        return 'Jan';
    }
    if (v === 1) {
        return 'Fev';
    }
    if (v === 2) {
        return 'Mar';
    }
    if (v === 3) {
        return 'Abr';
    }
    if (v === 4) {
        return 'Mai';
    }
    if (v === 5) {
        return 'Jun';
    }
    if (v === 6) {
        return 'Jul';
    }
    if (v === 7) {
        return 'Ago';
    }
    if (v === 8) {
        return 'Set';
    }
    if (v === 9) {
        return 'Out';
    }
    if (v === 10) {
        return 'Nov';
    }
    if (v === 11) {
        return 'Dez';
    }
    return '';
};

export default {
    toPrice,
    moneyMask,
    RenderTickFormatXMonth,
};
