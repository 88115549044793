/* eslint-disable react/require-default-props */
// libraries
import React from 'react';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';

// js
import {
    ContainerModal,
    ContainerBox,
    TxtLoading,
    SubtitleTxtLoading,
} from './loadingStyles';
import consts from '../../utils/consts';
import colors from '../../styles/colors';

// interfaces
interface Props {
    label: string,
    loading: boolean,
    subtitle?: string,
}

const Loading: React.FC<Props> = ({
    label,
    loading,
    subtitle,
}: Props) => (
    <Modal
        isOpen={loading}
        style={consts.modalStyles}
    >
        <ContainerModal>
            <ContainerBox>
                <ReactLoading type="bubbles" color={colors.basic.black} height="10%" width="10%" />
                <TxtLoading>{label}</TxtLoading>
                <SubtitleTxtLoading>{subtitle}</SubtitleTxtLoading>
            </ContainerBox>
        </ContainerModal>
    </Modal>
);

export default Loading;
