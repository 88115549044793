/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React from 'react';
import Modal from 'react-modal';
import evaluation from '../../../../services/evaluation';

// js
import colors from '../../../../styles/colors';
import {
    ContainerModal,
    ContainerBox,
    ContainerAuthorsTwo,
    ContainerAllSwitchs,
    TextCancel,
    ButtonDeleteOrCancel,
    TextButtonDeleteOrCancel,
    Header,
} from '../evaluationStyles';

const ModalViewerEvaluation = (
    isOpen: boolean,
    setIsOpen: (v: boolean) => void,
    ToggleModal: any,
    allEvaluation: any,
    setAllEvaluation: (v: any) => void,
    infoUser: any,
): React.ReactElement => {
    // constants
    const modalStyles = {
        overlay: {
            backgroundColor: colors.theme.blackMedium,
        },
        content: {
            alignItems: 'center',
            backgroundColor: colors.theme.blackMedium,
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
        },
    };

    // renders
    const RenderEvaluation = () => {
        if (allEvaluation?.length > 0) {
            return (
                allEvaluation?.map((v: any) => {
                    const DeleteOneEvaluation = async () => {
                        await evaluation.DeleteOneEvaluation(
                            v.id,
                            infoUser.token,
                        );
                        ToggleModal();
                    };
                    return (
                        <ContainerAllSwitchs justifyContent="space-between">
                            <text style={{ color: 'black' }}>{v?.description}</text>
                            <ButtonDeleteOrCancel onClick={DeleteOneEvaluation}>
                                <TextButtonDeleteOrCancel>
                                    Excluir
                                </TextButtonDeleteOrCancel>
                            </ButtonDeleteOrCancel>
                        </ContainerAllSwitchs>
                    );
                })
            );
        }
        return (
            <Header textAlign="center">Sem avaliações para serem exibidas</Header>
        );
    };
    return (
        <Modal isOpen={isOpen} style={modalStyles}>
            <ContainerModal>
                <ContainerBox>
                    <ContainerAuthorsTwo>
                        {RenderEvaluation()}
                    </ContainerAuthorsTwo>
                    <ContainerAllSwitchs justifyContent="center">
                        <TextCancel onClick={ToggleModal}>
                            Cancelar
                        </TextCancel>
                    </ContainerAllSwitchs>
                </ContainerBox>
            </ContainerModal>
        </Modal>
    );
};

export default ModalViewerEvaluation;
