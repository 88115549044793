// libraries
import React, { useEffect, useState } from 'react';
import {
    XYPlot,
    AreaSeries,
    HorizontalGridLines,
    VerticalGridLines,
    XAxis,
    YAxis,
} from 'react-vis';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../../styles/colors';
import {
    Home,
    Container,
    ContainerInner,
    ContainerOperational,
    Header,
    ContainerCharts,
    ContainerChartsDivider,
} from './dashOperationStyles';
import functions from '../../../utils/functions';
import constants from './dashOperationConstants';
import components from './DashOperationComponents';
import authorsApi from '../../../services/authors';
import dashboard from '../../../services/dashboard';
import { RootState } from '../../../store';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

// interfaces
import { DataAuthorsInterface } from '../../../interfaces';
import { AllChartsInterfaces, InitialFiltersInterfaces } from './dashOperationInterfaces';

const DashOperationPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const initialWidth = isMobile ? window.innerWidth / 1.3 : window.innerWidth / 2.7;
    const initialFilters: InitialFiltersInterfaces = {
        filterStreaming: 'month',
        nameAuthor: '',
    };

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [widthActual, setWidthActual] = useState(initialWidth);
    const [author, setAuthor] = useState('');
    const [allAuthors, setAllAuthors] = useState<Array<DataAuthorsInterface>>([]);
    const [allCharts, setAllCharts] = useState<Array<AllChartsInterfaces>>([]);
    const [allFilters, setAllFilters] = useState<InitialFiltersInterfaces>(initialFilters);

    // methods
    const GetAllAuthors = async () => {
        const GetAuthors = await authorsApi.GetAllAuthors(infoUser?.token);
        setAllAuthors(GetAuthors.result);
    };

    const GetDashOperation = async () => {
        const GetCharts = await dashboard.GetInfoDashboardOperation(
            allFilters.filterStreaming,
            allFilters.nameAuthor,
            infoUser?.token,
        );
        setAllCharts([
            {
                id: 1,
                title: 'Novos usuários',
                dataChart: [{
                    fill: colors.theme.pinkUltraLight,
                    stroke: colors.theme.pinkUltra,
                    result: GetCharts?.result?.newUsers || [],
                }],
            },
            {
                id: 2,
                title: 'Streaming reproduzidos',
                dataChart: [{
                    fill: colors.theme.backgroundChartsPerformance,
                    stroke: colors.theme.strokeChartsPerformance,
                    result: GetCharts?.result?.streaming || [],
                }],
            },
            // {
            //     id: 3,
            //     title: 'Download do aplicativo',
            //     dataChart: [{
            //         fill: colors.theme.backgroundChartsStreaming,
            //         stroke: colors.theme.strokeChartsStreaming,
            //         result: GetCharts?.result?.downloads || [],
            //     }],
            // },
            {
                id: 4,
                title: 'Desempenho dos autores',
                dataChart: [{
                    fill: colors.theme.backgroundChartsStreaming,
                    stroke: colors.theme.strokeChartsStreaming,
                    result: GetCharts?.result?.performance || [],
                }],
            },
        ]);
    };

    // useEffect
    useEffect(() => {
        GetAllAuthors();
        const adjustSize = window.addEventListener('resize', () => {
            if (!isMobile) {
                setWidthActual(window.innerWidth / 2.7);
            }
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        GetDashOperation();
    }, [allFilters]);

    // renders
    const RenderAllCharts = () => (
        allCharts.map((v) => {
            const [allResults] = v.dataChart.map((valueResults) => valueResults.result);
            const RenderTickFormatY = (value: string) => {
                if (!allResults.filter((valueCoordinates) => valueCoordinates.y).length) {
                    return '0';
                }
                return value;
            };
            const RenderAreaSeries = () => (
                v.dataChart.map((valueCharts) => {
                    if (allResults.filter((valueCoordinates) => valueCoordinates.y).length) {
                        return (
                            <AreaSeries
                                fill={valueCharts.fill}
                                stroke={valueCharts.stroke}
                                data={valueCharts.result}
                            />
                        );
                    }
                    return (
                        <AreaSeries
                            fill={valueCharts.fill}
                            stroke={valueCharts.stroke}
                            data={valueCharts.result}
                            yDomain={[0, 1]}
                        />
                    );
                })
            );
            return (
                <ContainerChartsDivider key={v.id}>
                    {components.RenderTitle(
                        v.title,
                        allAuthors,
                        author,
                        setAuthor,
                        allFilters,
                        setAllFilters,
                    )}
                    <XYPlot width={widthActual} height={300}>
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        <XAxis
                            style={constants.stylesXAxis}
                            tickFormat={functions.RenderTickFormatXMonth}
                            tickSize={3}
                        />
                        <YAxis
                            style={constants.stylesXAxis}
                            tickFormat={RenderTickFormatY}
                            tickSize={3}
                            tickValues={
                                !allResults.filter(
                                    (valueCoordinates) => valueCoordinates.y,
                                ).length ? [0] : undefined
                            }
                            top={
                                !allResults.filter(
                                    (valueCoordinates) => valueCoordinates.y,
                                ).length ? 260 : 5
                            }
                            hideLine={!allResults.filter(
                                (valueCoordinates) => valueCoordinates.y,
                            ).length}
                        />
                        {RenderAreaSeries()}
                    </XYPlot>
                </ContainerChartsDivider>
            );
        })
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <ContainerOperational>
                            <Header>
                                {'Administração > '}
                                <Header fontWeight="bold">Dash Operacional</Header>
                            </Header>
                            <ContainerCharts>
                                {RenderAllCharts()}
                            </ContainerCharts>
                        </ContainerOperational>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default DashOperationPage;
