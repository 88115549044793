/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import consts from '../utils/consts';

// constants
const { admPass, baseUrl } = consts;

interface CategoriesInterfaces {
    status: number,
    result: Array<any>;
    message: string,
}

// api
async function GetAllMedias(idContent: string, token: string): Promise<CategoriesInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/medias?admPass=${admPass}&idContent=${idContent}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function CreateOneMedia(
    cover: any,
    media: any,
    title: string,
    description: string,
    orientation: 'vertical' | 'horizontal',
    idContent: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const data = {
            cover,
            media,
            title,
            description,
            orientation,
            idContent,
        };
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/medias?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function EditOneMedia(
    cover: string,
    media: string,
    title: string,
    description: string,
    orientation: 'vertical' | 'horizontal',
    idContent: string,
    idMedia: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const data = {
            cover,
            media,
            title,
            description,
            orientation,
            idContent,
        };
        const method = 'PUT';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/medias?admPass=${admPass}&idMedia=${idMedia}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneMedia(
    idMedia: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const method = 'DELETE';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/medias?admPass=${admPass}&idMedia=${idMedia}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllMedias,
    CreateOneMedia,
    EditOneMedia,
    DeleteOneMedia,
};
