// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ButtonActionInterfaces {
    valueLength: boolean,
}

export const Logo = styled.img`
    height: 286px;
    width: 286px;

    /* media query */
    @media (max-width: 1024px) {
        height: 200px;
        width: 200px;
    }
`;

export const Home = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
`;

export const ButtonAuthStyles = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.basic.white)};
    border-radius: 30px;
    border: none;
    margin-top: 20px;
    height: 50px;
    transition-duration: 400ms;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    width: 30%;
    /* media query */
        @media (max-width: 512px) {
        height: 40px;
        width: 80%;
    }
`;

export const TextButtonAuthStyles = styled.h4<ButtonActionInterfaces>`
    color: ${(props) => (props.valueLength ? colors.basic.white : colors.basic.gray)};
    font-weight: bold;
    font-size: 18px;
`;

export const TextActionNavigation = styled.h4`
    color: ${colors.theme.gray};
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 20px;
`;

export const TextLinkingNavigation = styled.div`
    color: ${colors.theme.gray};
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
`;

export const InputAuthStyles = styled.input<ButtonActionInterfaces>`
    color: ${colors.theme.pink};
    border: ${(props) => (props.valueLength ? colors.theme.pink : colors.theme.gray)} 1px solid;
    border-radius: 30px;
    height: 50px;
    padding-left: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 40%;
    margin-top: 10px;
    /* media query */
    @media (max-width: 512px) {
        height: 40px;
        width: 90%;
    }
`;
