// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// styles
export const ContainerModal = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;

export const ContainerBox = styled.div`
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const TxtLoading = styled.text`
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Nunito-Bold')};
    font-size: 20px;
`;

export const SubtitleTxtLoading = styled.text`
    color: ${colors.basic.gray};
    margin-top: 10px;
    font-weight: ${consts.getFontWeight('Nunito-Bold')};
    font-size: 12px;
`;
