// js
import consts from '../utils/consts';

// interface
import { DataAppearanceInterfaces } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;

interface AppearanceInterfaces {
    status: number,
    result?: Array<DataAppearanceInterfaces>;
    message?: string,
}

// api
async function GetAllAppearance(token: string): Promise<AppearanceInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/configs`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function CreateOneAppearance(
    fontColor: string,
    backgroundUrl: string,
    audioUrl: string,
    type: string,
    token: string,
): Promise<AppearanceInterfaces> {
    try {
        const data = {
            fontColor,
            backgroundUrl,
            audioUrl,
        };
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/configs?admPass=${admPass}&type=${type}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function EditOneAppearance(
    fontColor: string,
    backgroundUrl: string,
    audioUrl: string,
    type: string,
    token: string,
): Promise<AppearanceInterfaces> {
    try {
        const data = {
            fontColor,
            backgroundUrl,
            audioUrl,
        };
        const method = 'PUT';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/configs?admPass=${admPass}&type=${type}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllAppearance,
    CreateOneAppearance,
    EditOneAppearance,
};
