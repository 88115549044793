// libraries
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// js
import {
    Container,
    ContainerInner,
    ContainerFinancial,
    Header,
    Home,
    HeaderInput,
    ButtonSave,
    InputDefault,
    TextButtonSave,
    ContainerAllSwitchs,
    ContainerSelect,
    Option,
} from './notificationStyles';
import { RootState } from '../../../store';
import notification from '../../../services/notification';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';

const NotificationPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [isEasyPass, setIsEasyPass] = useState('0');

    // methods
    const onChangeTitle = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(txt.target.value);
    };

    const onChangeSubtitle = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setSubtitle(txt.target.value);
    };

    const SendNotification = async () => {
        await notification.CreateNotification(title, subtitle, Number(isEasyPass), infoUser.token);
        setTitle('');
        setSubtitle('');
    };

    const onChangeIsEasyPass = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setIsEasyPass(txt.target.value);
    };

    // main
    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <ContainerFinancial>
                            <Header>
                                {'Administração > '}
                                <Header fontWeight="bold">Enviar Notificação</Header>
                            </Header>
                            <HeaderInput>Título</HeaderInput>
                            <InputDefault
                                value={title}
                                onChange={onChangeTitle}
                            />
                            <HeaderInput>Descrição</HeaderInput>
                            <InputDefault
                                value={subtitle}
                                onChange={onChangeSubtitle}
                            />
                            <ContainerAllSwitchs>
                                <ContainerSelect onChange={onChangeIsEasyPass} value={isEasyPass}>
                                    <Option value="0">Selecione um grupo</Option>
                                    <Option value="1">Todos</Option>
                                    <Option value="2">Assinantes</Option>
                                    <Option value="3">Não assinantes</Option>
                                </ContainerSelect>
                            </ContainerAllSwitchs>
                            <ButtonSave
                                valueLength={!!(title && subtitle && Number(isEasyPass))}
                                onClick={SendNotification}
                                disabled={!title || !subtitle || !Number(isEasyPass)}
                            >
                                <TextButtonSave>Enviar</TextButtonSave>
                            </ButtonSave>
                        </ContainerFinancial>
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default NotificationPage;
