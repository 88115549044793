// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface HeaderInterfaces {
    color?: string,
    fontWeight?: string,
    fontSize?: string,
    textAlign?: string,
    marginBottom?: string,
    marginTop?: string
}

interface ContainerPhotoInterfaces {
    background: string,
}

interface ContainerButtonInterfaces {
    height?: string,
}

interface ButtonActionInterfaces {
    valueLength: boolean,
    marginTop?: string,
}

// styles
export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreen};
`;

export const TextButtonSave = styled.h4`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 18px;
`;

export const ContainerAddArchive = styled.div<ContainerButtonInterfaces>`
    align-items: center;
    justify-content: center;
    background-color: ${colors.theme.blackMedium};
    display: flex;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: ${(props) => (props.height || '35px')};
    width: 100%;
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerInner = styled.div`
background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`;

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 15px;
    flex: 1;
`;

export const ContainerAppearance = styled.div`
    background-color: ${colors.theme.backgroundScreen};
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-right: 20px;
`;

export const ContainerDividerAppearance = styled.div`
    flex-direction: column;
    display: flex;
    width: 265px;
`;

export const Header = styled.text<HeaderInterfaces>`
    text-align: ${(props) => (props.textAlign || 'flex-start')};
    color: ${(props) => (props.color || `${colors.basic.black}`)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '14px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    margin-top: ${(props) => (props.marginTop || '0px')};
`;

// export const HeaderAudio = styled.text`
//     color: ${colors.theme.blackMedium};
//     font-weight: bold;
//     font-size: 12px;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     width: 90%;
//     text-align: center;
// `;

export const ContainerPointer = styled.div`
    align-self: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 20px;
`;

export const ContainerButton = styled.div<ContainerButtonInterfaces>`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    transition: height 0.2s ease;
    margin-top: 10px;
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    min-height: ${(props) => (props.height || '150px')};
`;

export const InputDefault = styled.input`
    color: ${colors.basic.gray};
    border: 1px solid ${colors.theme.gray};
    border-radius: 30px;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
`;

export const ImageConfig = styled.div<ContainerPhotoInterfaces>`
    align-items: flex-end;
    display: flex;
    min-height: 150px;
    border-radius: 15px;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
`;

export const ContainerEmpty = styled.div`
    align-items: flex-end;
    background-color: ${colors.theme.blackMediumLight};
    display: flex;
    min-height: 150px;
    border-radius: 15px;
    width: 100%;
`;

export const ContainerEmptyAudio = styled.div`
    align-items: flex-end;
    background-color: ${colors.theme.blackMediumLight};
    display: flex;
    min-height: 50px;
    border-radius: 15px;
    width: 100%;
`;

export const ButtonSave = styled.button<ButtonActionInterfaces>`
    align-self: center;
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.theme.gray)};
    border-radius: 15px;
    border: none;
    margin-bottom: 20px;
    height: 50px;
    transition-duration: 400ms;
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: 80%;
`;

export const ContainerInput = styled.div`
    align-self: center;
    width: 250px;
    height: 45px;
    border: 1px solid ${colors.theme.blackLight};
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const InputBlind = styled.input`
    color: ${colors.basic.gray};
    border-radius: 30px;
    min-height: 40px;
    border: none;
    padding-left: 5px;
    width: 90%;
    font-weight: bold;
`;

export const ContainerColorFont = styled.div<ContainerPhotoInterfaces>`
    background-color: ${(props) => (props.background || colors.basic.white)};
    height: 10px;
    width: 10px;
`;

export const ContainerHeaderAudio = styled.div`
    align-items: flex-end;
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
`;

export const ContainerClosed = styled.div`
    align-items: flex-end;
    justify-content: center;
    /* background-color: orange; */
    cursor: pointer;
    display: flex;
    height: 30px;
    width: 30px;
`;
