const colors = {
    basic: {
        black: '#000000',
        gray: '#707070',
        grayLight: '#BCBEBD',
        green: '#6BB99E',
        redLight: '#EA6767',
        white: '#ffffff',
        whiteFrosted: '#D5D5D5',
    },
    theme: {
        blackLight: '#434343',
        blackMediumLight: '#3E3E3E',
        blackMedium: '#333333',
        backgroundScreen: '#F4F4F4',
        blue: '#6090DE',
        blueLight: '#2C96E3',
        backgroundChartsStreaming: '#8ED9EB',
        strokeChartsStreaming: '#3A9AB1',
        backgroundChartsPerformance: '#EDD1A4',
        strokeChartsPerformance: '#BA9F73',
        backgroundChartsDownloads: 'rgba(164, 237, 212, 0.8)',
        strokeChartsDownloads: 'rgba(107, 185, 158, 0.8)',
        backgroundChartsExclusion: 'rgba(255,173,173, 1)',
        strokeChartsExclusion: 'rgba(234,103, 103, 1)',
        green: '#00E584',
        greenButtonActionReport: '#33A877',
        redButtonActionReport: '#F05050',
        greenTree: '#52C79A',
        gray: '#DBDBDB',
        grayUltra: '#AAAAAA',
        grayIntense: '#9B9B9B',
        redLight: '#ED6464',
        pink: '#E27898',
        pinkLight: '#EA7B92',
        pinkUltraLight: '#EDA4B3',
        purple: '#A460DE',
        pinkUltra: '#FD648A',
        pinkMoreUltra: '#DE607A',
    },
};

export default colors;
