// js
import consts from '../utils/consts';

// interface
import { DataAuthorsInterface } from '../interfaces';

// constants
const { admPass, baseUrl } = consts;

interface AuthorsInterfaces {
    status: number,
    result: Array<DataAuthorsInterface>;
    message: string,
}

// api
async function GetAllAuthors(token: string): Promise<AuthorsInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/authors?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function CreateOneAuthor(
    name: string,
    description: string,
    email: string,
    password: string,
    active: boolean,
    backgroundCardUrl: string,
    backgroundPageUrl: string,
    token: string,
): Promise<AuthorsInterfaces> {
    try {
        const data = {
            name,
            description,
            email,
            password,
            active,
            backgroundCardUrl,
            backgroundPageUrl,
        };
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/authors?admPass=${admPass}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function EditOneAuthor(
    name: string,
    description: string,
    email: string,
    password: string,
    active: boolean,
    backgroundCardUrl: string,
    backgroundPageUrl: string,
    idAuthor: string,
    token: string,
): Promise<AuthorsInterfaces> {
    try {
        const data = {
            name,
            description,
            email,
            password,
            active,
            backgroundCardUrl,
            backgroundPageUrl,
        };
        const method = 'PUT';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const body = JSON.stringify(data);
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseUrl}/authors?admPass=${admPass}&idAuthor=${idAuthor}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneAuthor(
    idAuthor: string,
    token: string,
): Promise<AuthorsInterfaces> {
    try {
        const method = 'DELETE';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/authors?admPass=${admPass}&idAuthor=${idAuthor}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllAuthors,
    CreateOneAuthor,
    EditOneAuthor,
    DeleteOneAuthor,
};
