/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import consts from '../utils/consts';

// constants
const { admPass, baseUrl } = consts;

interface CategoriesInterfaces {
    status: number,
    result: Array<any>;
    message: string,
}

// api
async function GetAllEvaluations(
    idContent: string,
    idMedia: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/evaluations?admPass=${admPass}&idContent=${idContent}&idMedia=${idMedia}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneEvaluation(
    idEvaluation: string,
    token: string,
): Promise<CategoriesInterfaces> {
    try {
        const method = 'DELETE';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/evaluations?admPass=${admPass}&idEvaluation=${idEvaluation}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllEvaluations,
    DeleteOneEvaluation,
};
