/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// js
import {
    Container,
    Home,
    ContainerAuthors,
    ContainerInner,
    ContainerDividerScreen,
    Header,
    ContainerSelect,
    Option,
    ContainerWrap,
    ContainerListEmpty,
    ContainerPhoto,
    NameCategories,
    CotainerDividerSelect,
} from './evaluationStyles';
import contents from '../../../services/contents';
import { RootState } from '../../../store';
import medias from '../../../services/medias';
import colors from '../../../styles/colors';
import evaluation from '../../../services/evaluation';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';
import ModalViewerEvaluation from './components/ModalViewerEvaluation';

const EvaluationPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    const [allContents, setAllContents] = useState<Array<any>>([]);
    const [allMedias, setAllMedias] = useState<Array<any>>([]);
    const [allEvaluation, setAllEvaluation] = useState<Array<any>>([]);
    const [contentSelected, setContentSelected] = useState<any>(null);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [validationEmpty, setValidationEmpty] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    // methods
    const ActionGetContents = async () => {
        const GetContents = await contents.GetAllContents('', '', '', '', infoUser?.token);
        if (GetContents?.status === 200) {
            setAllContents(GetContents?.result || []);
        }
    };

    const ActionGetMedias = async () => {
        const GetMedias = await medias.GetAllMedias(contentSelected?.id, infoUser?.token);
        if (GetMedias?.status === 200) {
            setValidationEmpty(GetMedias.result.length > 0);
            setAllMedias(GetMedias?.result || []);
        }
    };

    const onChangeFormat = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        const GetOnlyContent = allContents.find((v) => v.id === txt.target.value);
        setContentSelected(GetOnlyContent);
    };

    const ToggleModal = () => {
        setIsOpen(!isOpen);
        if (!isOpen === false) {
            setAllEvaluation([]);
        }
    };

    // useEffect
    useEffect(() => {
        ActionGetContents();
    }, []);

    useEffect(() => {
        if (contentSelected) {
            ActionGetMedias();
        }
    }, [contentSelected]);

    // renders
    const RenderEvaluation = () => {
        if (allMedias.length > 0 && validationEmpty) {
            return (
                <ContainerWrap>
                    {allMedias.map((v) => {
                        const ActionEditCategories = async () => {
                            const GetEvaluation = await evaluation.GetAllEvaluations(
                                contentSelected?.id,
                                v?.id,
                                infoUser?.token,
                            );
                            if (GetEvaluation?.status === 200) {
                                setAllEvaluation(GetEvaluation?.result);
                                ToggleModal();
                            }
                        };

                        return (
                            <ContainerPhoto
                                background={v.cover}
                                onClick={ActionEditCategories}
                            >
                                <NameCategories>{v.title}</NameCategories>
                            </ContainerPhoto>
                        );
                    })}
                </ContainerWrap>
            );
        }
        if (!allMedias.length && !validationEmpty) {
            return (
                <ContainerListEmpty>
                    <Header fontSize="32px" fontWeight="bold" color={colors.basic.gray}>Olá!</Header>
                    <Header fontSize="32px" color={colors.basic.gray}>Defina uma opção a partir dos conteúdos existentes</Header>
                    <Header fontSize="32px" color={colors.basic.gray}>acima para visualizar as medias e suas avaliações.</Header>
                </ContainerListEmpty>
            );
        }
        return null;
    };

    return (
        <Container>
            <Home>
                <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                {visibleDrawer ? null : (
                    <ContainerInner>
                        <ContainerAuthors>
                            <ContainerDividerScreen>
                                <Header marginBottom="30px">
                                    {'Administração > '}
                                    <Header fontWeight="bold">Avaliação</Header>
                                </Header>
                                <CotainerDividerSelect>
                                    <ContainerSelect onChange={onChangeFormat}>
                                        <Option value="">Selecione um conteúdo</Option>
                                        {allContents?.map((v) => (
                                            <Option value={v?.id}>{v?.title}</Option>
                                        ))}
                                    </ContainerSelect>
                                    {/* <ContainerSelect onChange={onChangeMedia}>
                                        <Option value="">Selecione uma mídia</Option>
                                        {allMedias?.map((v) => (
                                            <Option value={v?.id}>{v?.title}</Option>
                                        ))}
                                    </ContainerSelect> */}
                                </CotainerDividerSelect>
                            </ContainerDividerScreen>
                            {ModalViewerEvaluation(
                                isOpen,
                                setIsOpen,
                                ToggleModal,
                                allEvaluation,
                                setAllEvaluation,
                                infoUser,
                            )}
                        </ContainerAuthors>
                        {RenderEvaluation()}
                    </ContainerInner>
                )}
            </Home>
        </Container>
    );
};

export default EvaluationPage;
