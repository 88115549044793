// js
import { audio, play, text } from '../../assets/index';
import colors from '../../styles/colors';

// interfaces
import { DashboardInterface, InfoDashBoardInterface } from '../../interfaces';

function VerificationName(item: InfoDashBoardInterface): string {
    if (item.format === 'audio') {
        return 'Áudio';
    }
    if (item.format === 'video') {
        return 'Vídeo';
    }
    return 'Texto';
}

function VerificationColor(item: InfoDashBoardInterface): string {
    if (item.format === 'audio') {
        return colors.theme.purple;
    }
    if (item.format === 'video') {
        return colors.theme.blue;
    }
    return colors.theme.greenTree;
}

function VerificationImage(item: InfoDashBoardInterface): string {
    if (item.format === 'audio') {
        return audio;
    }
    if (item.format === 'video') {
        return play;
    }
    return text;
}

function InfoRadius(infoDashboard: DashboardInterface): Array<{angle: number, color: string}> {
    return [
        {
            angle: infoDashboard.infoAccess?.mobile || 70,
            color: colors.theme.pinkMoreUltra,
        },
        {
            angle: 2,
            color: colors.theme.backgroundScreen,
        },
        {
            angle: infoDashboard.infoAccess?.pc || 20,
            color: colors.theme.pinkLight,
        },
        {
            angle: 2,
            color: colors.theme.backgroundScreen,
        },
        {
            angle: infoDashboard.infoAccess?.tablet || 10,
            color: colors.theme.pinkUltraLight,
        },
        {
            angle: 2,
            color: colors.theme.backgroundScreen,
        },
    ];
}

export default {
    VerificationColor,
    VerificationImage,
    VerificationName,
    InfoRadius,
};
