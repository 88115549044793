/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React from 'react';
import Modal from 'react-modal';
import Switch from 'react-switch';
import Dropzone from 'react-dropzone';

// js
import {
    Header,
    Option,
    ContainerModal,
    TextCancel,
    ButtonDeleteOrCancel,
    TextButtonDeleteOrCancel,
    ButtonSave,
    TextButtonSave,
    ContainerAllSwitchs,
    ImageConfig,
    ContainerAddArchive,
    ContainerBody,
    ContainerInputs,
    HeaderInput,
    InputDefault,
    InputArea,
    ContainerSelectModal,
    ContainerBox,
    ContainerSwitch,
    ContainerPointer,
    ContainerButton,
    ContainerAuthorsTwo,
    ContainerDividerScreen,
    ContainerCheckBox,
    CheckBox,
    // IconCloud,
    ContainerButtonPhoto,
    ContainerSwitchTwo,
    ImageConfigTwo,
    ContainerAdjustButtonSave,
} from '../editContentsStyles';
import colors from '../../../../styles/colors';
import editContentsConstants, { InterfacesInitialInputs, InterfacesInitialInputsCreate } from '../editContentsConstants';
import editContentsFunctions from '../editContentsFunctions';
import contents from '../../../../services/contents';
import uploads from '../../../../services/uploads';
// import { cloudBlue, uploadFinish } from '../../../../assets';
// import functions from '../../../../utils/functions';

const ModalCreateOrEditContent = (
    isOpen: boolean,
    setIsOpen: (v: boolean) => void,
    inputs: InterfacesInitialInputs,
    setInputs: (v: InterfacesInitialInputs) => void,
    inputsCreate: InterfacesInitialInputsCreate,
    setInputsCreate: (v: InterfacesInitialInputsCreate) => void,
    allAuthors: any,
    allCategories: any,
    ToggleModal: any,
    idContentActual: string,
    setIdContentActual: (v: string) => void,
    infoUser: any,
    GetFuncContents: any,
    setLoading: (v: boolean) => void,
    setVisibleDrawer: (v: boolean) => void,
    typeModal: 'create' | 'edit',
): React.ReactElement => {
    // constants
    const modalStyles = {
        overlay: {
            backgroundColor: colors.theme.blackMedium,
        },
        content: {
            alignItems: 'center',
            backgroundColor: colors.theme.blackMedium,
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
        },
    };

    // methods
    function SelectedPhoto(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        if (typeModal === 'edit') {
            setInputs({ ...inputs, photo: newDataFiles });
        } else {
            setInputsCreate({ ...inputsCreate, photo: newDataFiles });
        }
    }

    const DeleteOneContent = async () => {
        await contents.DeleteOneContent(idContentActual, infoUser.token);
        GetFuncContents();
        setIsOpen(false);
    };

    const ValidationSave = () => {
        if (typeModal === 'edit') {
            const validationPage = typeof inputs.photo === 'string' ? inputs.photo.length > 0 : inputs.photo?.file?.length > 0;
            const validationPrice = inputs.contentFree ? true : inputs.price.length > 0;
            const validationFormsCreate = inputs.resume.length > 0
            && inputs.title.length > 0
            && inputs.author.length > 0
            && inputs.interpret.length > 0
            && inputs.categories.length > 0
            && validationPrice
            && validationPage;
            return validationFormsCreate;
        }
        if (typeModal === 'create') {
            const validationCheckbox = inputsCreate.isTypeMovie || inputsCreate.isTypeAudio || inputsCreate.isTypeText;
            const validationFormsCreate = inputsCreate.resume.length > 0
            // && (inputsCreate.textArea.length > 0 || inputsCreate.archive?.file?.length > 0)
            && inputsCreate.title.length > 0
            && inputsCreate.author.length > 0
            && inputsCreate.interpret.length > 0
            && inputsCreate.categories.length > 0
            && allAuthors.length > 0
            && inputsCreate.photo?.file?.length > 0
            && validationCheckbox;
            return validationFormsCreate;
        }
        return true;
    };

    // function SelectedArchive(event: React.ChangeEvent<HTMLInputElement>) {
    //     const fileSelected = event.target.files || [];
    //     const newDataFiles = {
    //         eventFile: fileSelected[0],
    //         eventName: fileSelected[0].name,
    //         file: URL.createObjectURL(fileSelected[0]),
    //     };
    //     setInputsCreate({
    //         ...inputsCreate,
    //         textArea: '',
    //         archive: newDataFiles,
    //     });
    // }

    const SaveAuthors = async () => {
        if (typeModal === 'edit') {
            setLoading(true);
            const uploadPhoto = typeof inputs.photo === 'string' ? inputs.photo : await uploads.UploadFile(inputs.photo?.eventFile);
            const removeStrings = inputs.price.replace(/[^0-9]+/g, '');
            const transformNumber = Number(`${removeStrings.substring(0, removeStrings.length - 2)}.${removeStrings.slice(-2)}`);
            const ActionCreateContent = await contents.EditOneContent(
                uploadPhoto,
                inputs.title,
                inputs.author,
                inputs.interpret,
                inputs.contentFree ? 0 : transformNumber,
                inputs.resume,
                inputs.categories,
                inputs.contentFree,
                inputs.activeContent,
                inputs.highLight,
                idContentActual,
                infoUser.token,
            );
            if (ActionCreateContent.status === 200) {
                GetFuncContents();
                setIsOpen(false);
                setVisibleDrawer(false);
                setIdContentActual('');
                setInputs(editContentsConstants.initialInputs);
            }
            // eslint-disable-next-line no-alert
            alert(ActionCreateContent?.message);
            setLoading(false);
        }
        if (typeModal === 'create') {
            setLoading(true);
            const uploadPhoto = await uploads.UploadFile(inputsCreate.photo?.eventFile);
            // const mediaContent = inputsCreate.textArea || await uploads.UploadFile(inputsCreate.archive?.eventFile);
            const removeStrings = inputsCreate.price.replace(/[^0-9]+/g, '');
            const transformNumber = Number(`${removeStrings.substring(0, removeStrings.length - 2)}.${removeStrings.slice(-2)}`);
            const ValidationType = () => {
                if (inputsCreate.isTypeMovie) {
                    return 'video';
                }
                if (inputsCreate.isTypeAudio) {
                    return 'audio';
                }
                return 'text';
            };
            const typeCorrect = ValidationType();
            const ActionCreateContent = await contents.CreateOneContent(
                uploadPhoto,
                // mediaContent,
                inputsCreate.title,
                inputsCreate.author,
                inputsCreate.interpret,
                inputsCreate.contentFree ? 0 : transformNumber,
                inputsCreate.resume,
                typeCorrect,
                inputsCreate.categories,
                inputsCreate.contentFree,
                inputsCreate.highLight,
                inputsCreate.activeContent,
                infoUser.token,
            );
            if (ActionCreateContent.status === 201) {
                GetFuncContents();
                setVisibleDrawer(false);
                setIsOpen(false);
                setInputsCreate(editContentsConstants.initialInputsCreate);
            }
            // eslint-disable-next-line no-alert
            alert(ActionCreateContent?.message);
            setLoading(false);
        }
    };

    // const ValidationAccept = () => {
    //     if (inputsCreate.isTypeAudio) {
    //         return ['audio/mp3'];
    //     }
    //     return ['video/mp4'];
    // };

    // const ValidationFormatsArchive = () => {
    //     if (inputsCreate.isTypeAudio) {
    //         return 'audio/mp3';
    //     }
    //     return 'video/mp4';
    // };

    // renders
    const RenderInputOrSelect = (type: 'input' | 'select' | 'inputArea' | 'switch' | 'checkbox', value: any, key: string, label: string, RenderItems?: any, selectDefault?: any) => {
        if (type === 'input' || type === 'inputArea') {
            const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
                if (typeModal === 'edit') {
                    setInputs({ ...inputs, [key]: txt.target.value });
                } else if (typeModal === 'create') {
                    setInputsCreate({ ...inputsCreate, [key]: txt.target.value });
                }
            };
            const onChangeArea = (txt: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (typeModal === 'edit') {
                    setInputs({ ...inputs, [key]: txt.target.value });
                } else {
                    setInputsCreate({ ...inputsCreate, [key]: txt.target.value });
                }
            };
            if (type === 'inputArea') {
                return (
                    <>
                        <HeaderInput>{label}</HeaderInput>
                        <InputArea
                            value={value}
                            onChange={onChangeArea}
                            height={!label || label === 'Resumo' ? '300px' : '150px'}
                        />
                    </>
                );
            }
            return (
                <>
                    <HeaderInput>{label}</HeaderInput>
                    <InputDefault
                        value={value}
                        type="email"
                        onChange={onChange}
                    />
                </>
            );
        }
        if (type === 'select') {
            const onChangeSelect = (txt: React.ChangeEvent<HTMLSelectElement>) => {
                if (typeModal === 'edit') {
                    setInputs({ ...inputs, [key]: txt.target.value });
                } else {
                    setInputsCreate({ ...inputsCreate, [key]: txt.target.value });
                }
            };

            return (
                <>
                    <HeaderInput>{label}</HeaderInput>
                    <ContainerSelectModal
                        onChange={onChangeSelect}
                        value={value}
                    >
                        <Option value="">{selectDefault}</Option>
                        {RenderItems}
                    </ContainerSelectModal>
                </>
            );
        }
        if (type === 'switch') {
            const onChangeSwitch = () => {
                if (key === 'activeContent') {
                    if (typeModal === 'edit') {
                        setInputs({ ...inputs, [key]: !inputs.activeContent });
                    } else {
                        setInputsCreate({ ...inputsCreate, [key]: !inputsCreate.activeContent });
                    }
                }
                if (key === 'contentFree') {
                    if (typeModal === 'edit') {
                        setInputs({ ...inputs, [key]: !inputs.contentFree });
                    } else {
                        setInputsCreate({ ...inputsCreate, [key]: !inputsCreate.contentFree });
                    }
                }
                if (key === 'highLight') {
                    if (typeModal === 'edit') {
                        setInputs({ ...inputs, [key]: !inputs.highLight });
                    } else {
                        setInputsCreate({ ...inputsCreate, [key]: !inputsCreate.highLight });
                    }
                }
            };
            return (
                <>
                    <HeaderInput alignSelf="center" paddingRight="10px" paddingLeft="0px">{label}</HeaderInput>
                    <Switch
                        onChange={onChangeSwitch}
                        checked={value}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={colors.theme.green}
                        offColor={colors.theme.gray}
                    />
                </>
            );
        }
        if (type === 'checkbox') {
            const onChangeCheckBox = (txt: React.ChangeEvent<HTMLInputElement>) => {
                const validationBoolean = txt.target.value.toLowerCase() === 'true';
                if (typeModal === 'edit') {
                    setInputs({ ...inputs, [key]: txt.target.value });
                } else if (typeModal === 'create') {
                    if (key === 'isTypeText') {
                        setInputsCreate({
                            ...inputsCreate,
                            isTypeText: validationBoolean,
                            isTypeAudio: false,
                            isTypeMovie: false,
                        });
                    }
                    if (key === 'isTypeAudio') {
                        setInputsCreate({
                            ...inputsCreate,
                            isTypeText: false,
                            isTypeAudio: validationBoolean,
                            isTypeMovie: false,
                        });
                    }
                    if (key === 'isTypeMovie') {
                        setInputsCreate({
                            ...inputsCreate,
                            isTypeText: false,
                            isTypeAudio: false,
                            isTypeMovie: validationBoolean,
                        });
                    }
                }
            };
            return (
                <>
                    <CheckBox type="checkbox" checked={value} onChange={onChangeCheckBox} value={JSON.stringify(!value)} />
                    <Header fontWeight="bold">{label}</Header>
                </>
            );
        }

        return null;
    };

    const RenderModal = () => {
        if (typeModal === 'edit') {
            return (
                <ContainerModal>
                    <ContainerBox>
                        <ContainerBody>
                            <ContainerPointer>
                                <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                    {({ getRootProps, getInputProps }) => (
                                        <ContainerButton {...getRootProps()}>
                                            <ImageConfig background={typeof inputs.photo === 'string' ? inputs.photo : inputs.photo?.file}>
                                                <ContainerAddArchive>
                                                    <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                </ContainerAddArchive>
                                            </ImageConfig>
                                            <input
                                                {...getInputProps()}
                                                onChange={SelectedPhoto}
                                            />
                                        </ContainerButton>
                                    )}
                                </Dropzone>
                            </ContainerPointer>
                            <ContainerInputs>
                                {RenderInputOrSelect('input', inputs.title, 'title', 'Título')}
                                {RenderInputOrSelect('select', inputs.author, 'author', 'Autor', editContentsFunctions.RenderPickers('authors', null, allAuthors), 'Selecione um autor')}
                                {RenderInputOrSelect('input', inputs.interpret, 'interpret', 'Intérprete')}
                                {RenderInputOrSelect('select', inputs.categories, 'categories', 'Categorias', editContentsFunctions.RenderPickers('categories', allCategories, null), 'Selecione uma categoria')}
                            </ContainerInputs>
                        </ContainerBody>
                        {RenderInputOrSelect('inputArea', inputs.resume, 'resume', 'Resumo')}
                        <ContainerAllSwitchs justifyContent="space-between">
                            <ContainerSwitch>
                                {RenderInputOrSelect('switch', inputs.activeContent, 'activeContent', 'Ativar')}
                            </ContainerSwitch>
                            <ContainerSwitch>
                                {RenderInputOrSelect('switch', inputs.contentFree, 'contentFree', 'Gratuito')}
                            </ContainerSwitch>
                            <ContainerSwitch>
                                {RenderInputOrSelect('switch', inputs.highLight, 'highLight', 'HighLight')}
                            </ContainerSwitch>
                        </ContainerAllSwitchs>
                        <ContainerAllSwitchs justifyContent="center">
                            <TextCancel onClick={ToggleModal}>
                                Cancelar
                            </TextCancel>
                        </ContainerAllSwitchs>
                        <ContainerAllSwitchs justifyContent="space-between">
                            <ButtonDeleteOrCancel onClick={DeleteOneContent}>
                                <TextButtonDeleteOrCancel>
                                    Excluir
                                </TextButtonDeleteOrCancel>
                            </ButtonDeleteOrCancel>
                            <ButtonSave
                                valueLength={ValidationSave()}
                                onClick={SaveAuthors}
                                disabled={!ValidationSave()}
                            >
                                <TextButtonSave>Salvar</TextButtonSave>
                            </ButtonSave>
                        </ContainerAllSwitchs>
                    </ContainerBox>
                </ContainerModal>
            );
        }

        return (
            <ContainerModal>
                <ContainerBox>
                    <ContainerAuthorsTwo>
                        <ContainerDividerScreen>
                            <ContainerCheckBox>
                                {RenderInputOrSelect('checkbox', inputsCreate.isTypeAudio, 'isTypeAudio', 'Áudio')}
                                {RenderInputOrSelect('checkbox', inputsCreate.isTypeMovie, 'isTypeMovie', 'Vídeo')}
                                {RenderInputOrSelect('checkbox', inputsCreate.isTypeText, 'isTypeText', 'Texto')}
                            </ContainerCheckBox>
                            {/* <Header>Arquivo</Header>
                            <ContainerPointer>
                                {inputsCreate.isTypeText
                                    ? (
                                        RenderInputOrSelect('inputArea', inputsCreate.textArea, 'textArea', '')
                                    )
                                    : (
                                        <Dropzone accept={ValidationAccept()}>
                                            {({ getRootProps, getInputProps }) => (
                                                <ContainerButton
                                                    file={inputsCreate.archive?.file}
                                                    {...getRootProps()}
                                                >
                                                    {inputsCreate.archive?.file ? (
                                                        <>
                                                            <IconCloud src={uploadFinish} />
                                                            <Header fontWeight="bold">Upload feito com sucesso!</Header>
                                                            <Header fontWeight="bold" marginBottom="30px">{inputsCreate.archive?.eventName}</Header>
                                                            <Header fontSize="11px" marginBottom="50px" textDecoration="underline">CLIQUE AQUI PARA TROCAR O ARQUIVO</Header>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconCloud src={cloudBlue} />
                                                            <Header fontWeight="bold">Arraste seu arquivo</Header>
                                                            <Header fontWeight="bold" marginBottom="10px">para cá ou clique no link abaixo</Header>
                                                            <Header fontSize="11px" marginBottom="50px" textDecoration="underline">BUSCAR ARQUIVO</Header>
                                                            <Header fontSize="9px">Formatos suportados:</Header>
                                                            <Header fontSize="9px">{ValidationFormatsArchive()}</Header>
                                                        </>
                                                    )}
                                                    <input
                                                        {...getInputProps()}
                                                        onChange={SelectedArchive}
                                                    />
                                                </ContainerButton>
                                            )}
                                        </Dropzone>
                                    )}
                            </ContainerPointer> */}
                        </ContainerDividerScreen>
                        <ContainerDividerScreen>
                            {RenderInputOrSelect('input', inputsCreate.title, 'title', 'Título')}
                            {RenderInputOrSelect('select', inputsCreate.author, 'author', 'Autor', editContentsFunctions.RenderPickers('authors', null, allAuthors), 'Selecione um autor')}
                            {RenderInputOrSelect('input', inputsCreate.interpret, 'interpret', 'Intérprete')}
                            {RenderInputOrSelect('select', inputsCreate.categories, 'categories', 'Categorias', editContentsFunctions.RenderPickers('categories', allCategories, null), 'Selecione uma categoria')}
                            <Header>Imagem de capa</Header>
                            <ContainerPointer>
                                <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                                    {({ getRootProps, getInputProps }) => (
                                        <ContainerButtonPhoto {...getRootProps()}>
                                            {inputsCreate.photo?.file ? (
                                                <ImageConfigTwo background={inputsCreate.photo?.file}>
                                                    <ContainerAddArchive>
                                                        <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                    </ContainerAddArchive>
                                                </ImageConfigTwo>
                                            ) : null}
                                            <input
                                                {...getInputProps()}
                                                onChange={SelectedPhoto}
                                            />
                                        </ContainerButtonPhoto>
                                    )}
                                </Dropzone>
                            </ContainerPointer>
                        </ContainerDividerScreen>
                        <ContainerDividerScreen>
                            {RenderInputOrSelect('inputArea', inputsCreate.resume, 'resume', 'Resumo')}
                            <ContainerSwitchTwo>
                                {RenderInputOrSelect('switch', inputsCreate.activeContent, 'activeContent', 'Ativar')}
                            </ContainerSwitchTwo>
                            <ContainerSwitchTwo>
                                {RenderInputOrSelect('switch', inputsCreate.contentFree, 'contentFree', 'Gratuito')}
                            </ContainerSwitchTwo>
                            <ContainerSwitchTwo>
                                {RenderInputOrSelect('switch', inputsCreate.highLight, 'highLight', 'HighLight')}
                            </ContainerSwitchTwo>
                        </ContainerDividerScreen>
                    </ContainerAuthorsTwo>
                    <ContainerAllSwitchs justifyContent="center">
                        <TextCancel onClick={ToggleModal}>
                            Cancelar
                        </TextCancel>
                    </ContainerAllSwitchs>
                    <ContainerAdjustButtonSave>
                        <ButtonSave
                            valueLength={ValidationSave()}
                            onClick={SaveAuthors}
                            disabled={!ValidationSave()}
                        >
                            <TextButtonSave>Salvar</TextButtonSave>
                        </ButtonSave>
                    </ContainerAdjustButtonSave>
                </ContainerBox>
            </ContainerModal>
        );
    };

    return (
        <Modal isOpen={isOpen} style={modalStyles}>
            {RenderModal()}
        </Modal>
    );
};

export default ModalCreateOrEditContent;
