// js
import categories from '../../../services/categories';
import uploads from '../../../services/uploads';

// interfaces
import { DataCategoriesInterface, DataArchiveInterface } from '../../../interfaces';

const GetAllCategories = async (
    setName: (v: string) => void,
    setDescription: (v: string) => void,
    setActive: (v: boolean) => void,
    setIdentifier: (v: string) => void,
    setIdCategoriesActual: (v: string) => void,
    setPhotoPage: (v: DataArchiveInterface) => void,
    setPhotoCard: (v: DataArchiveInterface) => void,
    setAllCategories: (v: Array<DataCategoriesInterface>) => void,
    token: string,
): Promise<void> => {
    // constants
    const initialArchive: DataArchiveInterface = {
        eventFile: new Blob(),
        eventName: '',
        file: '',
    };

    setName('');
    setDescription('');
    setActive(false);
    setIdentifier('create');
    setIdCategoriesActual('');
    setPhotoPage(initialArchive);
    setPhotoCard(initialArchive);
    // rota para pegar todas as categorias
    const infoGetCategories = await categories.GetAllCategories(token);
    if (infoGetCategories.status === 200) {
        setAllCategories(infoGetCategories.result);
    }
};

const DeleteCategories = async (
    setName: (v: string) => void,
    setDescription: (v: string) => void,
    setActive: (v: boolean) => void,
    setIdentifier: (v: string) => void,
    setIdCategoriesActual: (v: string) => void,
    setPhotoPage: (v: DataArchiveInterface) => void,
    setPhotoCard: (v: DataArchiveInterface) => void,
    setAllCategories: (v: Array<DataCategoriesInterface>) => void,
    idCategoriesActual: string,
    token: string,
): Promise<void> => {
    // rota para deletar usuário
    await categories.DeleteOneCategories(idCategoriesActual, token);
    GetAllCategories(
        setName,
        setDescription,
        setActive,
        setIdentifier,
        setIdCategoriesActual,
        setPhotoPage,
        setPhotoCard,
        setAllCategories,
        token,
    );
};

const SaveCategories = async (
    name: string,
    description: string,
    active: boolean,
    setName: (v: string) => void,
    setDescription: (v: string) => void,
    setActive: (v: boolean) => void,
    setIdentifier: (v: string) => void,
    setIdCategoriesActual: (v: string) => void,
    setPhotoPage: (v: DataArchiveInterface) => void,
    setPhotoCard: (v: DataArchiveInterface) => void,
    setAllCategories: (v: Array<DataCategoriesInterface>) => void,
    identifier: string,
    photoCard: DataArchiveInterface | string,
    photoPage: DataArchiveInterface | string,
    idCategoriesActual: string,
    token: string,
): Promise<void> => {
    if (identifier === 'create') {
        const uriPhotoCard = typeof photoCard === 'object' ? await uploads.UploadFile(photoCard?.eventFile) : photoCard;
        const uriPhotoPage = typeof photoPage === 'object' ? await uploads.UploadFile(photoPage?.eventFile) : photoPage;
        const GetInfoCreate = await categories.CreateOneCategories(
            name,
            description,
            active,
            uriPhotoCard,
            uriPhotoPage,
            token,
        );
        // eslint-disable-next-line no-alert
        alert(GetInfoCreate.message);
        GetAllCategories(
            setName,
            setDescription,
            setActive,
            setIdentifier,
            setIdCategoriesActual,
            setPhotoPage,
            setPhotoCard,
            setAllCategories,
            token,
        );
    }
    if (identifier === 'edit') {
        const uriPhotoCard = typeof photoCard !== 'string' ? await uploads.UploadFile(photoCard?.eventFile) : photoCard;
        const uriPhotoPage = typeof photoPage !== 'string' ? await uploads.UploadFile(photoPage?.eventFile) : photoPage;
        const EditCategories = await categories.EditOneCategories(
            name,
            description,
            active,
            uriPhotoCard || uriPhotoCard,
            uriPhotoPage || uriPhotoPage,
            idCategoriesActual,
            token,
        );
        // eslint-disable-next-line no-alert
        alert(EditCategories.message);
        GetAllCategories(
            setName,
            setDescription,
            setActive,
            setIdentifier,
            setIdCategoriesActual,
            setPhotoPage,
            setPhotoCard,
            setAllCategories,
            token,
        );
    }
};

export default {
    GetAllCategories,
    DeleteCategories,
    SaveCategories,
};
