// js
import { DataArchiveInterface } from '../../../interfaces';

// interfaces
export interface InterfacesInitialInputs {
    title: string,
    photo: DataArchiveInterface | string,
    author: string,
    interpret: string,
    resume: string,
    price: string,
    format: string,
    categories: string,
    authors: string,
    highLight: boolean,
    activeContent: boolean,
    contentFree: boolean,
}

export interface InterfacesInitialInputsCreate {
    isTypeAudio: boolean,
    isTypeMovie: boolean,
    isTypeText: boolean,
    textArea: string,
    archive: DataArchiveInterface,
    title: string,
    photo: DataArchiveInterface,
    author: string,
    interpret: string,
    resume: string,
    price: string,
    categories: string,
    highLight: boolean,
    activeContent: boolean,
    contentFree: boolean,
}

// consts
const initialArchive: DataArchiveInterface = {
    eventFile: new Blob(),
    eventName: '',
    file: '',
};

const initialInputs: InterfacesInitialInputs = {
    title: '',
    photo: initialArchive,
    author: '',
    interpret: '',
    price: '',
    resume: '',
    format: '',
    categories: '',
    authors: '',
    highLight: false,
    activeContent: true,
    contentFree: false,
};

const initialInputsCreate: InterfacesInitialInputsCreate = {
    isTypeAudio: false,
    isTypeMovie: false,
    isTypeText: false,
    archive: initialArchive,
    textArea: '',
    title: '',
    photo: initialArchive,
    author: '',
    interpret: '',
    resume: '',
    price: '',
    categories: '',
    highLight: false,
    activeContent: true,
    contentFree: false,
};

export default {
    initialInputs,
    initialInputsCreate,
};
