// js
import consts from '../utils/consts';

// constants
const { admPass, baseUrl } = consts;

// interfaces
interface InfoDashboardInterfaces {
    status: number,
    result: { file: string },
    message: string,
}

// api
async function GetReports(
    rangeInitial: string,
    rangeFinal: string,
    type: 'pdf' | 'csv',
    idAuthor: string,
    token: string,
): Promise<InfoDashboardInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const validationPath = idAuthor ? `${baseUrl}/admnistration/report?admPass=${admPass}&rangeInitial=${rangeInitial}&rangeFinal=${rangeFinal}&type=${type}&idAuthor=${idAuthor}` : `${baseUrl}/admnistration/report?admPass=${admPass}&rangeInitial=${rangeInitial}&rangeFinal=${rangeFinal}&type=${type}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { file: '' },
            status: 500,
            message: '',
        };
    }
}

export default {
    GetReports,
};
