/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { CompactPicker, ColorResult } from 'react-color';
import ReactAudioPlayer from 'react-audio-player';

// js
import colors from '../../../styles/colors';
import {
    Container,
    Home,
    ContainerInner,
    ContainerAppearance,
    ContainerDividerAppearance,
    Header,
    ContainerBody,
    ContainerPointer,
    ContainerButton,
    ImageConfig,
    ContainerAddArchive,
    ContainerEmpty,
    ButtonSave,
    InputBlind,
    TextButtonSave,
    ContainerInput,
    ContainerColorFont,
    // HeaderAudio,
    ContainerHeaderAudio,
    ContainerClosed,
} from './appearanceStyles';
import functionsAppearance from './AppearanceFunctions';
import { RootState } from '../../../store';

// interfaces
import { DataAppearanceInterfaces } from '../../../interfaces';

// components
import MenuComponent from '../../../components/Menu/MenuComponent';
import LoadingComponent from '../../../components/Loading/LoadingComponent';

const AppearancePage: React.FC = () => {
    // constants
    const initialConfigs: DataAppearanceInterfaces = {
        title: '',
        color: '',
        type: '',
        showAudio: false,
        audio: '',
        id: -1,
        uri: '',
        palette: false,
    };
    const stylesPlayerAudio = { width: '90%', borderRadius: 5 };

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [allAppearance, setAllAppearance] = useState<Array<DataAppearanceInterfaces>>([]);
    const [dataLogin, setDataLogin] = useState(initialConfigs);
    const [dataAuthor, setDataAuthor] = useState(initialConfigs);
    const [dataGeneral, setDataGeneral] = useState(initialConfigs);
    const [dataSearch, setDataSearch] = useState(initialConfigs);
    const [loading, setLoading] = useState(false);

    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);
    const tokenUser = infoUser?.token || '';

    // methods
    async function GetConfigs() {
        await functionsAppearance.GetAllAppearance(
            setAllAppearance,
            setDataLogin,
            setDataAuthor,
            setDataGeneral,
            setDataSearch,
            tokenUser,
        );
    }

    function disabledLoading() {
        setLoading(false);
    }

    function RenderAppearance(
        value: DataAppearanceInterfaces,
        setValue: (v: DataAppearanceInterfaces) => void,
    ) {
        function SelectedPhoto(event: React.ChangeEvent<HTMLInputElement>) {
            const fileSelected = event.target.files || [];
            const newDataFiles = {
                eventFile: fileSelected[0],
                eventName: fileSelected[0].name,
                file: URL.createObjectURL(fileSelected[0]),
            };
            const newDataValue = { ...value, uri: newDataFiles };
            setValue(newDataValue);
        }

        function onChangeColor(txt: React.ChangeEvent<HTMLInputElement>) {
            const valueFormat = txt.target.value;
            const newDataValue = { ...value, color: valueFormat };
            setValue(newDataValue);
        }

        function SelectedAudio(event: React.ChangeEvent<HTMLInputElement>) {
            const fileSelected = event.target.files || [];
            const newDataFiles = {
                eventFile: fileSelected[0],
                eventName: fileSelected[0].name,
                file: URL.createObjectURL(fileSelected[0]),
            };
            const newDataValue = { ...value, audio: newDataFiles };
            setValue(newDataValue);
        }

        const OpenPalette = () => {
            const newDataValue = { ...value, palette: !value.palette };
            setValue(newDataValue);
        };

        const handleChangeComplete = (color: ColorResult) => {
            const newDataValue = { ...value, color: color.hex };
            setValue(newDataValue);
        };

        const ValidationSave = () => {
            const validationPhoto = typeof value.uri === 'string' ? value.uri?.length > 0 && value.color?.length === 7 : value?.uri?.file?.length > 0;
            return validationPhoto;
        };

        const onChangeAudio = () => {
            const newDataValue = { ...value, audio: '' };
            setValue(newDataValue);
        };

        const SaveOneConfig = () => {
            setLoading(true);
            const GetAllPosts = allAppearance.filter((v) => v.color);
            const ValidationPut = GetAllPosts.filter((v) => v.id === value.id);

            if (ValidationPut.length) {
                functionsAppearance.SaveAppearance(
                    disabledLoading,
                    value.color,
                    value.uri,
                    value.audio,
                    'edit',
                    value.type,
                    setDataLogin,
                    setDataAuthor,
                    setDataGeneral,
                    setDataSearch,
                    setAllAppearance,
                    tokenUser,
                );
            } else {
                functionsAppearance.SaveAppearance(
                    disabledLoading,
                    value.color,
                    value.uri,
                    value.audio,
                    'create',
                    value.type,
                    setDataLogin,
                    setDataAuthor,
                    setDataGeneral,
                    setDataSearch,
                    setAllAppearance,
                    tokenUser,
                );
            }
        };

        return (
            <ContainerDividerAppearance>
                <Header fontSize="20px" color={colors.basic.gray} fontWeight="bold" textAlign="center" marginBottom="20px">{value.title}</Header>
                <Header color={colors.basic.gray} textAlign="center">Background</Header>
                <ContainerPointer>
                    <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                        {({ getRootProps, getInputProps }) => (
                            <ContainerButton {...getRootProps()}>
                                {typeof value?.uri === 'string' ? (
                                    <>
                                        {value?.uri ? (
                                            <ImageConfig background={value?.uri}>
                                                <ContainerAddArchive>
                                                    <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                </ContainerAddArchive>
                                            </ImageConfig>
                                        ) : (
                                            <ContainerEmpty>
                                                <ContainerAddArchive>
                                                    <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                </ContainerAddArchive>
                                            </ContainerEmpty>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {value?.uri?.file ? (
                                            <ImageConfig background={value?.uri?.file}>
                                                <ContainerAddArchive>
                                                    <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                </ContainerAddArchive>
                                            </ImageConfig>
                                        ) : (
                                            <ContainerEmpty>
                                                <ContainerAddArchive>
                                                    <Header fontSize="16px" color={colors.basic.white} fontWeight="bold">Trocar imagem</Header>
                                                </ContainerAddArchive>
                                            </ContainerEmpty>
                                        )}
                                    </>
                                )}
                                <input {...getInputProps()} onChange={SelectedPhoto} />
                            </ContainerButton>
                        )}
                    </Dropzone>
                </ContainerPointer>
                <Header color={colors.basic.gray} textAlign="center">Cor da fonte</Header>
                <ContainerInput>
                    {value.color.length === 7 ? (
                        <ContainerColorFont onClick={OpenPalette} background={value.color} />
                    ) : null}
                    <InputBlind
                        value={value.color}
                        type="email"
                        onChange={onChangeColor}
                        maxLength={7}
                    />
                </ContainerInput>
                {value.color.length === 7 && value.palette ? (
                    <CompactPicker
                        color={value.color}
                        onChangeComplete={handleChangeComplete}
                        styles={{ default: { compact: { width: 260 } } }}
                    />
                ) : null}
                {value?.title === 'Geral' ? (
                    <>
                        <ContainerHeaderAudio>
                            <Header color={colors.basic.gray}>Áudio</Header>
                            <ContainerClosed onClick={onChangeAudio}>
                                <Header color={colors.theme.pink} fontSize="16px" fontWeight="bold">X</Header>
                            </ContainerClosed>
                        </ContainerHeaderAudio>
                        <ContainerPointer>
                            <Dropzone accept={['.mp3,audio/*']}>
                                {({ getRootProps, getInputProps }) => (
                                    <ContainerButton height="50px" {...getRootProps()}>
                                        {typeof value?.audio === 'string' ? (
                                            <>
                                                {value?.audio ? (
                                                    <ReactAudioPlayer
                                                        src={value?.audio}
                                                        controls
                                                        style={stylesPlayerAudio}
                                                    />
                                                ) : (
                                                    <Header fontSize="12px" color={colors.theme.blackMedium} fontWeight="bold">Adicionar áudio</Header>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {value?.audio?.file ? (
                                                    <ReactAudioPlayer
                                                        src={value?.audio?.file}
                                                        controls
                                                        style={stylesPlayerAudio}
                                                    />
                                                ) : (
                                                    <Header fontSize="12px" color={colors.theme.blackMedium} fontWeight="bold">Adicionar áudio</Header>
                                                )}
                                            </>
                                        )}
                                        <input {...getInputProps()} onChange={SelectedAudio} />
                                    </ContainerButton>
                                )}
                            </Dropzone>
                        </ContainerPointer>
                    </>
                ) : null}
                <ButtonSave
                    valueLength={ValidationSave()}
                    onClick={SaveOneConfig}
                    disabled={!ValidationSave()}
                    marginTop={value.title !== 'Geral' ? '100px' : '0px'}
                >
                    <TextButtonSave>Salvar</TextButtonSave>
                </ButtonSave>
            </ContainerDividerAppearance>
        );
    }

    // useEffect
    useEffect(() => {
        GetConfigs();
    }, []);

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {visibleDrawer ? null : (
                        <ContainerBody>
                            <Header color={colors.basic.gray}>
                                {'Configurações Gerais > '}
                                <Header fontWeight="bold" color={colors.basic.gray}>Aparência</Header>
                            </Header>
                            <ContainerAppearance>
                                {RenderAppearance(dataLogin, setDataLogin)}
                                {RenderAppearance(dataAuthor, setDataAuthor)}
                                {RenderAppearance(dataGeneral, setDataGeneral)}
                                {RenderAppearance(dataSearch, setDataSearch)}
                            </ContainerAppearance>
                        </ContainerBody>
                    )}
                    <LoadingComponent label="Carregando...." loading={loading} subtitle="Esta operação pode levar alguns minutos dependendo do tamanho do seu arquivo ou velocidade da sua internet..." />
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default AppearancePage;
