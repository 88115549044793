// libraries
import React from 'react';

// js
import {
    ContainerInput,
    Icon,
    Input,
    IconClosed,
} from './searchStyles';
import { closed, magnifier } from '../../assets/index';

// interfaces
interface Props {
    value: string,
    onChange: (txt: React.ChangeEvent<HTMLInputElement>) => void,
    onClosed: () => Promise<void>,
}

const SearchComponent: React.FC<Props> = ({
    value,
    onChange,
    onClosed,
}: Props) => (
    <ContainerInput>
        <Input value={value} onChange={onChange} placeholder="Buscar" />
        {value.length > 0 ? (
            <IconClosed src={closed} onClick={onClosed} />
        ) : (
            <Icon src={magnifier} />
        )}
    </ContainerInput>
);

export default SearchComponent;
