// libraries
import React from 'react';

// js
import { InputAuthStyles } from '../../pages/Login/loginStyles';

// interfaces
interface Props {
    placeholder: string,
    value: string,
    type: string,
    onChange: (txt: React.ChangeEvent<HTMLInputElement>) => void,
    activeInput: boolean,
}

const InputAuth: React.FC<Props> = ({
    placeholder,
    value,
    type,
    onChange,
    activeInput,
}: Props) => (
    <InputAuthStyles
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        valueLength={activeInput}
    />
);

export default InputAuth;
