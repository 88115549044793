// js
import { DataArchiveInterface } from '../../../interfaces';

// interfaces
export interface InterfacesInitialInputs {
    title: string,
    media: DataArchiveInterface | string,
    description: string,
    cover: DataArchiveInterface | string,
    idMedia: string,
    text: string,
    type: string,
    orientationHorizontal: boolean,
}

// consts
const initialArchive: DataArchiveInterface = {
    eventFile: new Blob(),
    eventName: '',
    file: '',
};

const initialInputs: InterfacesInitialInputs = {
    title: '',
    description: '',
    media: initialArchive,
    cover: initialArchive,
    idMedia: '',
    text: '',
    type: '',
    orientationHorizontal: true,
};

export default {
    initialInputs,
};
